import React, { useMemo, useEffect, useState, useContext } from "react";
import {
  Grid,
  Box,
  Typography,
  IconButton,
  TextField,
  Button,
  Menu,
  MenuItem,
  Divider,
  Tooltip,
} from "@mui/material";
import { TreeView, TreeItem } from "@material-ui/lab";
import {
  styled,
  alpha,
  createTheme,
  ThemeProvider,
} from "@mui/material/styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  AddSharp,
  MinimizeSharp,
  DeleteSharp,
  EditSharp,
  CheckSharp,
  ArrowCircleRight,
  DocumentScannerOutlined,
  SaveSharp,
  WebAssetSharp,
  LegendToggleSharp,
  MedicationLiquid,
  SendSharp,
  AppBlockingOutlined,
  RefreshSharp,
  CloseSharp,
  TimeToLeave,
} from "@mui/icons-material";
import MaterialReactTable from "material-react-table";
import { ShoppingCartContext } from "../Context/ShoppingCartContext";
import { useTheme } from "@mui/material/styles";

import axios from "../../axiosInstances";
import { useNavigate } from "react-router-dom";
import NewVisit from "./NewVisit";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import DischargePatient from "./DischargePatient";
import HosPreview from "./hosPreview";
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: "rgb(55, 65, 81)",
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export default function DiagnosisComp() {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [treeOpen, setTreeOpen] = useState(false);
  //Result
  const [anchorResultEl, setAnchorResultEl] = useState(null);
  const resultOpen = Boolean(anchorResultEl);
  const handleResultClick = (event) => {
    setAnchorResultEl(event.currentTarget);
  };
  const handleResultClose = () => {
    setAnchorResultEl(null);
    setOpenDrawer(false);
  };
  //History
  const [anchorHistoryEl, setAnchorHistoryEl] = useState(null);
  const historyOpen = Boolean(anchorHistoryEl);
  const handleHistoryClick = (event) => {
    setAnchorHistoryEl(event.currentTarget);
  };
  const handleHistoryClose = () => {
    setAnchorHistoryEl(null);
    setOpenDrawer(false);
  };
  //Certificates
  const [anchorCertiEl, setAnchorCertiEl] = useState(null);
  const certiOpen = Boolean(anchorCertiEl);
  const handleCertiClick = (event) => {
    setAnchorCertiEl(event.currentTarget);
  };
  const handleCertiClose = () => {
    setAnchorCertiEl(null);
    setOpenDrawer(false);
  };

  const navigate = useNavigate();
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const [update, setUpdate] = useState(false);

  const mainColumn = useMemo(
    () => [
      {
        header: "Id",
        accessorKey: "Id",
      },
      {
        header: "Name",
        accessorKey: "Name",
      },
      {
        header: "Mobile",
        accessorKey: "Mobile",
      },
    ],
    []
  );
  const labDiagnosisColumn = useMemo(
    () => [
      {
        header: "Id",
        accessorKey: "Id",
      },
      {
        header: "Customer",
        accessorKey: "Customer",
      },
      {
        header: "Name",
        accessorKey: "Name",
        size: 120,
      },
      {
        header: "Value",
        accessorKey: "Value",
      },
    ],
    []
  );
  const labOrderColumn = useMemo(
    () => [
      {
        header: "Id",
        accessorKey: "Id",
      },
      {
        header: "Item",
        accessorKey: "Item",
      },
      {
        header: "Type",
        accessorKey: "Type",
      },
      {
        header: "Name",
        accessorKey: "Name",
      },
      {
        header: "Value",
        accessorKey: "Value",
        size: 120,
      },
    ],
    []
  );
  const [activePatientsList, setActivePatientsList] = useState([]);
  const [maincategories, setMainCategories] = useState([]);
  const [categories, setCategories] = useState([]);
  const [tests, setTests] = useState([]);
  const [selectedLabOrders, setSelectedLabOrders] = useState([]);
  const [selectedMedicationOrders, setSelectedMedicationOrders] = useState([]);
  const [mainExaminationTypes, setMainExaminationTypes] = useState([]);
  const [examinationTypes, setExaminationTypes] = useState([]);
  const [examinationList, setExaminationList] = useState([]);
  const [medicinesList, setMedicinesList] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [selectedInvestigation, setSelectedInvestigation] =
    useState("Laboratory Types");
  const [selectedExamination, setSelectedExamination] =
    useState("PatientHistory");
  const [selectedOrderAndMedication, setSelectedOrderAndMedication] =
    useState(true);
  const [rowSelection, setRowSelection] = useState({});
  const [physicians, setPhysicians] = useState([]);
  const [subSpecialities, setSubSpecialities] = useState([]);
  const { setPageHeader, globalUser, globalToken, globalRoleSecurity } =
    useContext(ShoppingCartContext);
  const [visitDate, setVisitDate] = useState(format(new Date(), "yyyy-MM-dd"));
  const handleSubmitExamination = () => {
    window.confirm("Are you sure you want to save?");
    {
      try {
      } catch (err) {}
    }
  };
  const handleSubmitOrders = () => {
    if (window.confirm("Are you sure you want to save?")) {
      try {
        selectedLabOrders &&
          selectedLabOrders
            .filter(
              (so) => parseInt(so.Customer) === parseInt(selectedPatient.Id)
            )
            .map((item) => {
              var fd = new FormData();
              fd.append("Id", item.Id);
              fd.append("Customer", selectedPatient.Id);
              fd.append("VisitId", selectedPatient.VisitId);
              fd.append("OrderedBy", globalUser.Id);
              fd.append("OrderDate", format(new Date(), "yyyy-MM-dd"));
              fd.append("Type", "Service");
              fd.append("Item", item.Item);
              fd.append("Name", item.Name);
              fd.append("Dosage", 1);
              fd.append("Freq", 1);
              fd.append("OrderQty", 1);
              fd.append("Description", "");
              fd.append("RemainingQty", 0);
              fd.append("Outsource", 0);
              fd.append("Status", "");
              fd.append("Paid", 0);
              fd.append("Route", "");
              fd.append("Remark", "");

              axios
                .post("/hosorders", fd, {
                  headers: {
                    "Content-Type": "application/json",
                    token: globalToken,
                  },
                })
                .then((response) => {
                  console.log("out put");
                  console.log(response.data.data);
                });
            });
      } catch (err) {}
    }
  };
  const handleDeleteOrders = (item) => {
    if (window.confirm("Are you sure you want to delete?")) {
      if (item.Id && item.Id !== 0) {
        try {
          axios.delete("/hosorders?Id=" + item.Id, {
            headers: {
              "Content-Type": "application/json",
              token: globalToken,
            },
          });
        } catch (err) {}
      }
      setSelectedLabOrders((prev) =>
        prev.filter((so) => {
          return (
            parseInt(so.Id) !== parseInt(item.Id) &&
            parseInt(so.Customer) === parseInt(item.Customer)
          );
        })
      );
    }
  };
  const loadMedicalExaminationTypes = (patients, type) => {
    axios
      .get("/itemCategories/getCategoriesChain?Type=" + type, {
        headers: {
          "Content-Type": "application/json",
          token: globalToken,
        },
      })
      .then((response) => {
        if (response.data.data) {
          if (type === "Medical Examination Types") {
            setMainCategories(response.data.data.MainCategories);
            setCategories(response.data.data.Categories);
            setTests(response.data.data.Tests);
            if (
              response.data.data.MainCategories &&
              response.data.data.MainCategories.length > 0
            ) {
              setSelectedInvestigation({
                Id: response.data.data.MainCategories[0].Id,
                Name: response.data.data.MainCategories[0].Name,
              });
            }
          } else {
            setMainExaminationTypes(response.data.data.MainCategories);
            setExaminationTypes(response.data.data.Categories);
           
              (
                patients &&
                patients.map((pt) => {
                  if(examinationList && examinationList.filter(ex=>((parseInt(ex.Customer)===parseInt(pt.Id)))).length<1){
                    setExaminationList((exl) => [
                      ...exl.flat(),
                       response.data.data.Tests.map((exl) => {
                        return { ...exl, Result: "", Customer: pt.Id };
                      })
                    ]
                )}
                   
                  })
              )
          
          }
        } else {
          alert("log out and log in again");
        }
      });
  };

  const getSelectedPatient = () => {
    Object.getOwnPropertyNames(rowSelection).map((item) => {
      if (item === "length") {
        setSelectedPatient(null);
      } else {
        setSelectedPatient(
          activePatientsList.filter(
            (ap) => parseInt(ap.Id) === parseInt(item)
          )[0]
        );
      }
    });
  };

  const loadActivePatientsList = () => {
    axios
      .get(
        "/hosvisits/getPatientByVisitDateAndAssignedTo?Parent=" +
          globalUser.Parent +
          "&VisitDate=" +
          visitDate +
          "&AssignedTo=" +
          globalUser.Id,
        {
          headers: {
            "Content-Type": "application/json",
            token: globalToken,
          },
        }
      )
      .then((response) => {
        if (response.data.data) {
          setActivePatientsList(response.data.data.ActivePatients);
         
        } else {
          alert("log out and log in again");
        }
      });
  };
  const loadPhysicians = () => {
    axios
      .get(
        "/employees/getByParentAndType?Parent=" +
          globalUser.Parent +
          "&Type=Physician",
        {
          headers: {
            "Content-Type": "application/json",
            token: globalToken,
          },
        }
      )
      .then((response) => {
        if (response.data.data) {
          setPhysicians(response.data.data);
        } else {
          alert("log out and log in again");
        }
      });
  };
  const loadSubSpecialities = () => {
    axios
      .get(
        "/settings/getByParentAndType?Parent=" +
          globalUser.Parent +
          "&Type=" +
          "Sub Speciality",
        {
          headers: {
            "Content-Type": "application/json",
            token: globalToken,
          },
        }
      )
      .then((response) => {
        if (response.data.data) {
          setSubSpecialities(response.data.data);
        } else {
          alert("log out and log in again");
        }
      });
  };
  const loadActiveOrders = () => {
    axios
      .get(
        "/hosorders/getActiveOrders?Parent=" +
          globalUser.Parent +
          "&orderedBy=" +
          globalUser.Id +
          "&OrderDate=" +
          visitDate,
        {
          headers: {
            "Content-Type": "application/json",
            token: globalToken,
          },
        }
      )
      .then((response) => {
        if (response.data.data) {
          setSelectedLabOrders(response.data.data);
        } else {
          alert("log out and log in again");
        }
      });
  };

  useEffect(() => {
    if (!globalUser) {
      navigate("/");
      return;
    }

    loadActivePatientsList();
    loadSubSpecialities();
    loadPhysicians();
    loadActiveOrders();

    setPageHeader("Diagnosis");

    const intervalId = setInterval(() => {
      loadActivePatientsList();
    }, 10000);
    return () => {
      clearInterval(intervalId);
    };
  }, [update, visitDate]);
  useEffect(() => {
    loadMedicalExaminationTypes(
      activePatientsList,
      "Medical Examination Types"
    );
    loadMedicalExaminationTypes(
      activePatientsList,
      "Patient Examination Types"
    );
    activePatientsList.map((pt) => {
      if (
        selectedMedicationOrders.filter(
          (mOrder) => ((parseInt(mOrder.Customer) === parseInt(pt.Id)) && (mOrder.Name.length<1))
        ).length < 1
      ) {
        setSelectedMedicationOrders((medOrder) => [
          ...medOrder,
          {
            Customer: pt.Id,
            Id: 0,
            Name: "",
            Dosage: "",
            Freq: "",
            Quantity: "",
            OutSource: "",
            Route: "",
          },
        ]);
      }
    });
  }, [activePatientsList]);
  useEffect(() => {
    loadMedicalExaminationTypes(
      activePatientsList,
      "Medical Examination Types"
    );
  }, []);
  useEffect(() => {
    if (Object.getOwnPropertyNames(rowSelection).length > 0) {
      getSelectedPatient();
    }
  }, [rowSelection]);
  return (
    <Grid container spacing={2} mt={1}>
      <Grid item xs={12} md={6}>
        <MaterialReactTable
          columns={mainColumn}
          data={activePatientsList}
          initialState={{
            density: "compact",
            columnVisibility: { Id: false },
            pagination: {
              pageIndex: 0,
              pageSize: 5,
            },
          }}
          enableGlobalFilter
          enableColumnResizing
          enableRowNumbers
          enableRowSelection
          enableMultiRowSelection={false}
          getRowId={(originalRow) => originalRow.Id}
          onRowSelectionChange={setRowSelection}
          state={{ rowSelection }}
          muiTopToolbarProps={{
            sx: {
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
            },
          }}
          muiBottomToolbarProps={{
            sx: {
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
            },
          }}
          renderTopToolbarCustomActions={() => (
            <Box style={{ display: "flex", flexDirection: "row" }}>
              <TextField
                size="small"
                label="Visit Date"
                type="date"
                value={visitDate}
                onChange={(e) => {
                  setVisitDate(e.target.value);
                }}
                sx={{
                  marginRight: 1,
                  width:120,
                  color: theme.palette.primary.contrastText,
                }}
                InputLabelProps={{
                  sx: {
                    color: theme.palette.primary.contrastText,
                 
                  },
                  shrink: true,
                }}
              />
              |
              {selectedPatient !== null && (
                <>
                
                  <Button
                    id="demo-customized-button"
                    aria-controls={
                      historyOpen ? "demo-customized-menu" : undefined
                    }
                    aria-haspopup="true"
                    aria-expanded={historyOpen ? "true" : undefined}
                    variant="contained"
                    disableElevation
                    onClick={(e) => handleHistoryClick(e)}
                    endIcon={<KeyboardArrowDownIcon />}
                  >
                    History
                  </Button>
                  <StyledMenu
                    backgroundColor="black"
                    id="demo-customized-menu"
                    MenuListProps={{
                      "aria-labelledby": "demo-customized-button",
                    }}
                    anchorEl={anchorHistoryEl}
                    open={historyOpen}
                    onClose={handleHistoryClose}
                  >
                   
                    <MenuItem
                      color="inherit"
                      disableRipple
                      onClick={() => {
                        alert("Prev. Medical Hx");
                        handleHistoryClose();
                      }}
                    >
                      <WebAssetSharp />
                      {t("Prev. Medical Hx")}
                    </MenuItem>

                    <MenuItem
                      color="inherit"
                      disableRipple
                      onClick={() => {
                        alert("Investigation Stats");
                        handleHistoryClose();
                      }}
                    >
                      <WebAssetSharp />
                      {t("Investigation Stats")}
                    </MenuItem>
                    <Divider sx={{ my: 0.5 }} />
                  
                    <MenuItem
                      color="inherit"
                      disableRipple
                      onClick={() => {
                        alert("Appointed");
                        handleHistoryClose();
                      }}
                    >
                      <WebAssetSharp />
                      {t("Appointed")}
                    </MenuItem>
                    <MenuItem
                      color="inherit"
                      disableRipple
                      onClick={() => {
                        alert("Internal Transfer");
                        handleHistoryClose();
                      }}
                    >
                      <WebAssetSharp />
                      {t("Internal Transfer")}
                    </MenuItem>
                    <Divider sx={{ my: 0.5 }} />
                    <MenuItem
                      color="inherit"
                      disableRipple
                      onClick={() => {
                        alert("Discharged Patients");
                        handleHistoryClose();
                      }}
                    >
                      <WebAssetSharp />
                      {t("Discharged Patients")}
                    </MenuItem>
                  </StyledMenu>
                  |
             
              
              <Button
                size="small"
                variant="standard"
                sx={{ borderRadius: "20px", width: "20" }}
                endIcon={<TimeToLeave />}
                onClick={() => {
                  alert('Appointment')
                }}
              >
                Appointment
              </Button>|
              </>
              )}
              <Button
                size="small"
                variant="standard"
                sx={{ borderRadius: "20px", width: "20" }}
                endIcon={<RefreshSharp />}
                onClick={() => {
                  setUpdate(!update);
                }}
              >
                Refresh
              </Button>
            </Box>
          )}
          enableRowActions
          renderRowActions={({ row }) => (
            <Box>
              <Tooltip title="Edit">
                <IconButton>
                  <NewVisit
                    icon={
                      <EditSharp style={{ width: "15px", height: "15px" }} />
                    }
                    data={row.original}
                    SubSpecialities={subSpecialities}
                    Type="Customer"
                    Physicians={physicians}
                    setParentUpdate={setUpdate}
                    ParentUpdate={update}
                  />
                </IconButton>
              </Tooltip>
              |
              <Tooltip title="Discharge">
                <IconButton>
                  <DischargePatient
                    icon={
                      <AppBlockingOutlined
                        style={{ width: "15px", height: "15px" }}
                      />
                    }
                    data={row.original}
                    setParentUpdate={setUpdate}
                    ParentUpdate={update}
                  />
                </IconButton>
              </Tooltip>
            </Box>
          )}
        ></MaterialReactTable>
      </Grid>

      <Grid item xs={12} md={6}>
        {selectedPatient !== null && "Patient Name: " + selectedPatient.Name}
        <Box
          sx={{
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
          }}
        >
          {maincategories &&
            maincategories.map((lc, index) => {
              return (
                <>
                <Button
                  variant="standard"
                  onClick={() =>
                    setSelectedInvestigation({ Id: lc.Id, Name: lc.Name })
                  }
                >
                  {lc.Name}
                </Button>|
                </>
              );
            })}
        </Box>

        <Box>
          <TreeView
            defaultCollapseIcon={<MinimizeSharp />}
            defaultExpandIcon={<AddSharp />}
          >
            {selectedInvestigation &&
              categories &&
              categories
                .filter(
                  (cat) =>
                    parseInt(cat.ParentCategory) ===
                    parseInt(selectedInvestigation.Id)
                )
                .map((cat) => {
                  return (
                    <TreeItem nodeId={cat.Id} label={cat.Name}>
                      {tests &&
                        tests
                          .filter(
                            (ts) => parseInt(ts.Category) === parseInt(cat.Id)
                          )
                          .map((ts) => {
                            return (
                              <TreeItem
                                nodeId={ts.Id}
                                label={ts.Name}
                                icon={
                                  selectedLabOrders.filter(
                                    (slo) =>
                                      slo.Id === ts.Id &&
                                      slo.Customer === selectedPatient?.Id
                                  ).length > 0 ? (
                                    <CheckSharp />
                                  ) : (
                                    <ArrowCircleRight />
                                  )
                                }
                                onLabelClick={() => {
                                  if (selectedPatient) {
                                    selectedLabOrders.filter(
                                      (slo) =>
                                        slo.Id === ts.Id &&
                                        slo.Customer === selectedPatient?.Id
                                    ).length > 0
                                      ? alert("Already inserted")
                                      : setSelectedLabOrders((slo) => [
                                          ...slo,
                                          {
                                            ...ts,
                                            Item: ts.Id,
                                            Id: 0,
                                            Customer: selectedPatient?.Id,
                                          },
                                        ]);
                                  } else {
                                    alert("First select the patient");
                                  }
                                }}
                              />
                            );
                          })}
                    </TreeItem>
                  );
                })}
          </TreeView>
        </Box>
      </Grid>

      <Grid item xs={12} md={6}>
      <Box sx={{backgroundColor:theme.palette.primary.main,
        color:theme.palette.primary.contrastText,
        marginBottom:1}}>
               <Button
                  variant="standard"
                  endIcon={<SaveSharp />}
                  onClick={() => {
                   
                      alert("save");
                    
                  }}
                >
                  Save
                </Button>|
                <Button
                    size="small"
                    variant="standard"
                    sx={{ borderRadius: "20px", width: "20" }}
                    endIcon={
                      <MedicationLiquid
                        style={{ width: "20px", height: "20px" }}
                      />
                    }
                    onClick={() => {
                      alert("discharge");
                    }}
                  >
                    Discharge
                  </Button>
                  |
                  <Button
                    size="small"
                    variant="standard"
                    sx={{ borderRadius: "20px", width: "20" }}
                    endIcon={
                      <MedicationLiquid
                        style={{ width: "20px", height: "20px" }}
                      />
                    }
                    onClick={() => {
                      alert("IPD");
                    }}
                  >
                    IPD
                  </Button>
                  |
                <Button
                  variant="standard"
                  onClick={() => {
                   
                      alert("Diag. Details");
                    
                  }}
                >
                  Diag. Details
                </Button>|
                <Button
                  variant="standard"
                  onClick={() => {
                   
                      alert("Diag. Details");
                    
                  }}
                >
                  Inv. Results
                </Button>|
              
          </Box>
        <Box
          sx={{
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
          }}
        >
          {mainExaminationTypes &&
            mainExaminationTypes.map((lc) => {
              return (
                <>
                <Button
                  variant="standard"
                  onClick={() => {
                    if (selectedPatient) {
                      setSelectedExamination({ Id: lc.Id, Name: lc.Name });
                    } else {
                      alert("Select the patient first");
                    }
                  }}
                >
                  {lc.Name}
                </Button>|
                </>
              );
            })}
         
        </Box>

        {selectedPatient &&
          examinationTypes &&
          examinationTypes
            .filter(
              (eli) =>
                parseInt(eli.ParentCategory) ===
                parseInt(selectedExamination.Id)
            )
            .map((eli) => {
              return (
                <Grid container mt={1}>
                  {selectedPatient &&
                    examinationList
                      .filter(
                        (el) =>
                          parseInt(el.Category) === parseInt(eli.Id) &&
                          parseInt(el.Customer) === parseInt(selectedPatient.Id)
                      )
                      .map((exl) => {
                        return (
                          <Grid item xs={6}>
                            <TextField
                              disabled={
                                selectedPatient && selectedPatient.DischargeDate
                              }
                              size="small"
                              label={exl.Name}
                              value={exl.Result}
                              multiline
                              sx={{
                                width: "95%",
                                marginBottom: 1,
                                marginLeft: 1,
                              }}
                              onChange={(e) => {
                                exl.Result = e.target.value;
                                setExaminationList(
                                  examinationList.map((exl) => exl)
                                );
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </Grid>
                        );
                      })}
                </Grid>
                /*  <MaterialReactTable
                          key={index}
                          columns={labDiagnosisColumn}
                          data={examinationList.filter(
                            (el) => parseInt(el.Category) === parseInt(eli.Id)
                          )}
                          initialState={{
                            density: "compact",
                            columnVisibility: { Id: false, Customer: false },
                            pagination: {
                              pageIndex: 0,
                              pageSize: 12,
                            },
                          }}
                          enableColumnResizing
                          muiTopToolbarProps={{
                            sx: {
                              backgroundColor: theme.palette.primary.main,
                              color: theme.palette.primary.contrastText,
                            },
                          }}
                          muiBottomToolbarProps={{
                            sx: {
                              backgroundColor: theme.palette.primary.main,
                              color: theme.palette.primary.contrastText,
                            },
                          }}
                          renderTopToolbarCustomActions={() => (
                            <Box>
                                {selectedLabOrders && selectedLabOrders.length>0 && 
          <>
          <Button color='inherit' endIcon={<SaveSharp />} onClick={()=>{
            
          }}>Save</Button>|
          </>} 
                              {eli.Name}
                            </Box>
                          )}
                          enableRowActions
                        ></MaterialReactTable> */
              );
            })}
      </Grid>

      <Grid item xs={12} md={6}>
        <Box sx={{backgroundColor:theme.palette.primary.main,
        color:theme.palette.primary.contrastText,
        marginBottom:1}}>
          <Button
            variant="standard"
            onClick={() => {
              setSelectedOrderAndMedication(true);
            }}
          >
            Orders
          </Button>
          |
          <Button
            variant="standard"
            onClick={() => {
              setSelectedOrderAndMedication(false);
            }}
          >
            Medication
          </Button>|
          <Button
                    id="demo-customized-button"
                    variant="standard"
                    aria-controls={
                      historyOpen ? "demo-customized-menu" : undefined
                    }
                    aria-haspopup="true"
                    aria-expanded={historyOpen ? "true" : undefined}
                    disableElevation
                    onClick={(e) => handleCertiClick(e)}
                    endIcon={<KeyboardArrowDownIcon />}
                  >
                    Certificates
                  </Button>
                  <StyledMenu
                    backgroundColor="black"
                    id="demo-customized-menu"
                    MenuListProps={{
                      "aria-labelledby": "demo-customized-button",
                    }}
                    anchorEl={anchorCertiEl}
                    open={certiOpen}
                    onClose={handleCertiClose}
                  >
                    <Divider sx={{ my: 0.5 }} />
                    <MenuItem
                      color="inherit"
                      disableRipple
                      onClick={() => {
                        
                  
                        handleCertiClose();
                      }}
                    >
                   
                      <HosPreview
                        icon={<div>{t("Birth")}</div>}
                       
                        data=""
                        DocumentType=
                          "Purchase "                          
                        Type="Invoice"
                      />
                      
                    </MenuItem>
                    <MenuItem
                      color="inherit"
                      disableRipple
                      onClick={() => {
                        handleCertiClose();
                      }}
                    >
                      <WebAssetSharp />
                      {t("Death")}
                    </MenuItem>
                    <MenuItem
                      color="inherit"
                      disableRipple
                      onClick={() => {
                        handleCertiClose();
                      }}
                    >
                      <WebAssetSharp />
                      {t("Medical")}
                    </MenuItem>
                    
                  </StyledMenu>
        </Box>
        {selectedOrderAndMedication ? (
          <MaterialReactTable          
            columns={labOrderColumn}
            data={selectedLabOrders.filter(
              (so) => parseInt(so.Customer) === parseInt(selectedPatient?.Id)
            )}
            initialState={{
              density: "compact",
              columnVisibility: { Id: false, Item: false, Customer: false },
              pagination: {
                pageIndex: 0,
                pageSize: 12,
              },
            }}
            enableColumnResizing
            muiTopToolbarProps={{
              sx: {
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
              },
            }}
            muiBottomToolbarProps={{
              sx: {
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
              },
            }}
            renderTopToolbarCustomActions={() => (
              <Box>
                {selectedLabOrders && selectedLabOrders.length > 0 && (
                  <>
                    <Button
                      color="inherit"
                      endIcon={<SaveSharp />}
                      onClick={() => handleSubmitOrders()}
                    >
                      Save
                    </Button>
                    |
                  </>
                )}

                {" Selected Ordesrs for " +
                  (selectedPatient !== null && selectedPatient.Name)}
              </Box>
            )}
            enableRowActions
            renderRowActions={({ row }) => (
              <Box>
                <IconButton
                  onClick={() => {
                    handleDeleteOrders(row.original);
                  }}
                >
                  <DeleteSharp style={{ width: "15px", height: "15px" }} />
                </IconButton>
                |
              </Box>
            )}
          ></MaterialReactTable>
        ) : (
          <table>
            <thead>
              <td style={{ width: "50%" }}>Name</td>
              <td style={{ width: "10%" }}>Dosage</td>
              <td style={{ width: "10%" }}>Freq</td>
              <td style={{ width: "10%" }}>Quantity</td>
              <td style={{ width: "10%" }}>O.Source</td>
              <td style={{ width: "10%" }}>Route</td>
              <td style={{ width: "10%" }}></td>
            </thead>
            {selectedPatient &&
              selectedMedicationOrders &&
              selectedMedicationOrders
                .filter((medOrder) =>
                  parseInt(medOrder.Customer) === parseInt(selectedPatient.Id)
                )
                .map((mo) => {
                  return (
                    <tr>
                      <td style={{ width: "50%" }}>
                        {/*  <Autocomplete
                  disablePortal
                  selectOnFocus
                  id='template'
                  autoHighlight
                  value={
                   mo.Item
                  } 
                  onSelect={(e) => {
                    mo.Item=
                  }
                }
                  sx={{
                    width: "95%",
                    borderRadius: "25%",
                  }}
                  options={reportTemplate && reportTemplate.map(temp=>
                    { return temp.Name})
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Template"                      
                  placeholder='Select medicines here'
                      variant="standard"
                    />
                  )}
                /> */}
                        <TextField
                          size="small"
                          label="Medicine"
                          value={mo.Name}
                          onChange={(e) => {
                            mo.Name = e.target.value;
                           
                            
                            if (
                              e.target.value && e.target.value.length > 0 &&
                              selectedMedicationOrders.filter(
                                (medOrd) => ((medOrd.Name.length <= 0) && (parseInt(medOrd.Customer)===parseInt(selectedPatient.Id)))
                              ).length <= 0
                            ) {
                              setSelectedMedicationOrders((medOr) => [
                                ...medOr,
                                {
                                  Customer: selectedPatient.Id,
                                  Id: 0,
                                  Name: "",
                                  Dosage: "",
                                  Freq: "",
                                  Quantity: "",
                                  OutSource: "",
                                  Route: "",
                                },
                              ]);
                            }
                            setSelectedMedicationOrders(()=>
                              selectedMedicationOrders.map((medOrd) => medOrd)
                            );
                          }}
                          sx={{
                            width: "98%",
                            marginTop: 1,
                            color: theme.palette.primary.contrastText,
                          }}
                          InputLabelProps={{
                            placeholder: "Write the medicine here",
                            shrink: true,
                          }}
                        />
                      </td>
                      <td style={{ width: "10%" }}>{mo.Dosage}</td>
                      <td style={{ width: "10%" }}>{mo.Freq}</td>
                      <td style={{ width: "10%" }}>{mo.Quantity}</td>
                      <td style={{ width: "10%" }}>{mo.OutSource}</td>
                      <td style={{ width: "10%" }}>{mo.Route}</td>
                      <td style={{ width: "10%" }}>
                        <IconButton
                          onClick={() => {
                            setSelectedMedicationOrders(selectedMedicationOrders.filter(mOrd=>!((parseInt(mOrd.Customer)===parseInt(selectedPatient.Id)) && (mOrd.Name===mo.Name) && (mOrd.Name.length>0))))
                          }}
                        >
                          <CloseSharp />
                        </IconButton>
                      </td>
                    </tr>
                  );
                })}
          </table>
        )}
      </Grid>
    </Grid>
  );
}
