import { useState, useEffect, useContext } from "react";
import {
  Autocomplete,
  Button,
  Grid,
  IconButton,
  Table,
  TextField,
  Tooltip,
  Card,
  CardContent,
} from "@mui/material";
import {
  Close,
  Delete,
  Add,
  PreviewSharp,
  ShoppingBagSharp,
  ReceiptSharp,
} from "@mui/icons-material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import InvoicePreview from "../Reports/InvoicePreview";

import { ShoppingCartContext } from "../Context/ShoppingCartContext";

import { format } from "date-fns";
import axios from "../../axiosInstances";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";

export default function PurchaseReceiptModel(props) {
  const theme=useTheme();
  var fileObj = [];
  var fileArray = [];
  const [open, setOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const [orderDate, setOrderDate] = useState(
    props.data ? props.data.VoucherDate : format(new Date(), "yyyy-MM-dd")
  );
  const {
    setSelectedItems,
    removeFromCart,
    setglobalCustomer,
    globalUser,
    selectedItems,
    globalCustomers,
    globalToken,
    globalRoleSecurity
  } = useContext(ShoppingCartContext);



  const [receivedLineItems, setReceivedLineItems] = useState(props.ReceivedLineItems);
  const [lineItems, setLineItems] = useState(props.LineItems.map(litem=>{
    let receivedQty=0;
    receivedLineItems && receivedLineItems.length>0 && receivedLineItems.filter(shl=>parseInt(shl.Item)===parseInt(litem.Item)).map(itt=>{
      receivedQty=parseFloat(receivedQty) + parseFloat(itt.Quantity)
    });
    return {...litem,ReceivedQuantity:receivedQty,ReceivingQuantity:(parseFloat(litem.Quantity)-parseFloat(receivedQty))}
  }));


    const [customers, setCustomers] = useState([{...props.data,Id: props.data.Supplier}]);
    
    const [Photofiles, setPhotofiles] = useState(splitPictures());

  function splitPictures() {
    try {
      fileArray = [];
      props.data.Attachment.split(",").map(function (address, index) {
        if (!fileArray.includes(address) && address.length > 0) {
          fileArray.push(address);
          //document.getElementById('file').files.push(new Blob(address))
        }
      });
      return fileArray;
    } catch (err) {
      console.log(err);
    }
  }
  function uploadMultipleFiles(e) {
    fileObj.push(e.target.files);
    for (let i = 0; i < fileObj[0].length; i++) {
      fileArray.push(URL.createObjectURL(fileObj[0][i]));
    }
    setPhotofiles(fileArray);
  }

  const handleSubmit = (id, status, vDate, lItems) => {
    if (
      lItems === undefined ||
      lItems.filter((li) => li.Name.length > 0).length <= 0
    ) {
      alert("First select the items: ");
      return;
    }

    let testResult = false;
    lItems.map((li) => {
      if (isNaN(li.ReceivingQuantity.toString().replace(',','')) || li.ReceivingQuantity.toString().replace(',','') <= 0) {
        alert("First insert the proper quantity of product: " + li.Name);
        testResult = true;
        return;
      }

     
    });

    if (testResult) {
      return;
    }
    
    if (
      window.confirm(
        "Are you sure you want to receive?"
      )
    ) {
    try {
      var ttAmount = 0;


      customers && customers.map((cust) => {
        cust.Amount=0;
    cust.VATAmount=0;
    lItems.map((li) => {
      
      li.TotalPrice=(parseFloat(li.ReceivingQuantity)*parseFloat(li.UnitPrice))
      cust.Amount=parseFloat(cust.Amount)+parseFloat(li.TotalPrice);
      li.TaxAmount=li.VATInclusive?(parseFloat(li.TotalPrice)*0.15):0;
      cust.VATAmount=parseFloat(cust.VATAmount)+parseFloat(li.TaxAmount);  

    })

        var fd = new FormData();
        fd.append("OrderId", props.data.Status!=='Received'?0:props.data.Id);
        fd.append("SourceNo", props.data.OrderId);
        fd.append("Customer", globalUser.Parent);
        fd.append("VoucherDate", vDate);
      
        fd.append("Amount", cust.Amount);
        fd.append("VATAmount", cust.VATAmount);
        fd.append("TotalAmount", (parseFloat(cust.Amount)+parseFloat(cust.VATAmount)));
       
        fd.append("Supplier", cust.Id);
        fd.append("Status", status);
        fd.append("BillTo", cust.Id);
        fd.append("Store", props.data.Store);
        fd.append("User", globalUser.Id);
        Array.from(document.getElementById("Attachment").files).map((file) => {
          fd.append("Attachment", file);
        });
        fd.append(
          "LineItems",
          JSON.stringify(
            lineItems.filter(
              (it) =>
                it.Name.length > 0 && parseInt(it.Supplier) === parseInt(cust.Id)
            ).map(lItem=>{
              if(props.data.Status!=='Received'){
                lItem.Id=0;
              };
              lItem.Quantity=lItem.ReceivingQuantity;
            return lItem})
          )
        );
        fd.append("Type", "Normal")
        axios
          .post("/vouchers", fd, {
            headers: { "Content-Type": "multipart/form-data" , 
                token: globalToken,
             },
          })
          .then((res) => {
            if (!(props.data && !(props.data === null))) {
              setglobalCustomer([]);
              setSelectedItems([]);
            }
          });
      });
    } catch (err) {
    } finally {
      setSelectedItems([]);
      setLineItems([]);
      //setCustomer('')
      //props.setParentUpdate(!props.ParentUpdate);
      setOpen(false);
    }
  }
  };
  const handleDelete = (id) => {
    try {
      axios.delete("/vouchers?Id=" + id, {
        headers: {
          token: globalToken,
        },
      }); //add id
    } catch (err) {
    } finally {
      props.setParentUpdate(!props.ParentUpdate);
      setOpen(false);
    }
  };
  const handleLineItemDelete = (id) => {
    try {
      axios.delete("/lineItems?Id=" + id, {
        headers: {
          token: globalToken,
        },
      }); //add id
    } catch (err) {
    } finally {
    }
  };

 

console.log('line items list')
console.log(lineItems)
console.log(props.data)
console.log(customers)
 return (
    <div>
      <label
        onClick={() => {
          setOpen(true);
        }}
      >
        {props.icon}
      </label>

      <Dialog
      open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <DialogContent>
          <DialogContentText>
           
            {!(customers === undefined) &&
              !(customers === null) &&
              customers.map((cust, index) => {
                
                if(cust && !(cust===null) && cust.Name && cust.Name.length>0){
                  cust.TotalAmount=0;
                  cust.TotalVATAmount=0;
                return (
                  <>
                  <DialogTitle style={{backgroundColor: theme.palette.primary.main,color:theme.palette.primary.contrastText, height:'20px'}} 
                 >
                 Sales Delivery Order: {index + 1 + "              "} Customer:{" "}
                        {cust.Name}
                    </DialogTitle>
                    <Grid container mt={1} key={cust.Id}>
                    

                     
                      <Grid
                        item
                        xs={6}
                       
                      >
                      {"Supplier: " + cust.Name}
                      </Grid>
                      <Grid
                        item
                        xs={6}
                       
                      >
                        <span style={{}}>
                          Doc. No.: {props.data ? props.data.Id : ""}
                        </span>
                      </Grid>
                    
                      <Grid
                        item
                        xs={6}
                        mt={1}
                      
                      >
                        <TextField
                        disabled={true}
                          size="small"
                          label="Order Date"
                          type="date"
                          value={
                            props.data ? props.data.VoucherDate : orderDate
                          }
                          onChange={(e) => {
                            setOrderDate(e.target.value);
                          }}
                          sx={{ width: "70%", marginBottom: 1 }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={6}
                       
                      >
                        <span style={{}}>
                          Status: {props.data ? props.data.Status : "Draft"}<br></br>
                          User:{" "} {props.data ? props.data.UserName : globalUser.Name}
                        </span>
                      </Grid>
                     
                     
                      

                      <Grid item xs={12}>
                        <Grid
                          direction="row"
                          container
                        
                        >
                          <hr></hr>
                          <Grid item xs={12} className="hideInMobile">
                            <Grid container>
                              <Grid
                                item
                                xs={1}
                                style={{
                                  paddingLeft: "5px",
                                  backgroundColor: theme.palette.primary.main,
color:theme.palette.primary.contrastText,
                                }}
                              >
                                No.
                              </Grid>
                              <Grid
                                item
                                xs={3}
                                style={{
                                  paddingLeft: "5px",
                                  backgroundColor: theme.palette.primary.main,
color:theme.palette.primary.contrastText,
                                }}
                              >
                                {" "}
                                Item Name
                              </Grid>
                              <Grid
                                item
                                xs={2}
                                style={{
                                  paddingLeft: "5px",
                                  backgroundColor: theme.palette.primary.main,
color:theme.palette.primary.contrastText,
                                }}
                              >
                               Order Quantity
                              </Grid>
                              <Grid
                                item
                                xs={2}
                                style={{
                                  paddingLeft: "5px",
                                  backgroundColor: theme.palette.primary.main,
color:theme.palette.primary.contrastText,
                                }}
                              >
                               Received Quantity
                              </Grid>
                              <Grid
                                item
                                xs={2}
                                style={{
                                  paddingLeft: "5px",
                                  backgroundColor: theme.palette.primary.main,
color:theme.palette.primary.contrastText,
                                }}
                              >
                                Quantity
                              </Grid>
                              <Grid
                                item
                                xs={2}
                                style={{
                                  paddingLeft: "5px",
                                  backgroundColor: theme.palette.primary.main,
color:theme.palette.primary.contrastText,
                                }}
                              >
                                Remark
                              </Grid>
                             

                              {lineItems && lineItems.length > 0 &&                             
                                lineItems
                                  .filter(
                                    (it) =>(parseInt(it.Supplier) === parseInt(cust.Id))
                                  )
                                  .map((item, index) => {
                                     
                                                                   
                                    return (
                                      <>
                                        <Grid item xs={12}>
                                          <hr></hr>
                                        </Grid>
                                        <Grid item xs={1} key={index}>
                                          {index + 1}
                                        </Grid>
                                        <Grid item xs={3}>
                                          {item.Name}
                                        </Grid>
                                        <Grid item xs={2}>
                                        {item.Quantity}
                                        </Grid>
                                        <Grid item xs={2}>
                                        {item.ReceivedQuantity?.toLocaleString(undefined, {minimumFractionDigits:2,maximumFractionDigits:2})}
                                        </Grid>
<Grid item xs={2}>
                                        <TextField
                                        disabled={(parseFloat(item.Quantity)-parseFloat(item.ReceivedQuantity))<=0?true:false}
                                            size="small"
                                            variant="standard"
                                            type="float"
                                            value={item.ReceivingQuantity}
                                            sx={{ width: "70px" }}
                                            onChange={(e) => {
                                            if(parseFloat(e.target.value)>(parseFloat(item.Quantity)-parseFloat(item.ReceivedQuantity))){
                                              item.ReceivingQuantity = (parseFloat(item.Quantity)-parseFloat(item.ReceivedQuantity));
                                            }else{
                                              item.ReceivingQuantity = e.target.value;
                                            }

                                            item.TotalPrice= (parseFloat(item.UnitPrice) * parseFloat(item.ReceivingQuantity));
                                            item.TaxAmount = item.VATInclusive?(parseFloat(item.UnitPrice)  * parseFloat(item.ReceivingQuantity))*.15:0;
                                            selectedItems.map((litem) => {
                                              props.data.TotalAmount =parseFloat(props.data.TotalAmount ) +parseFloat(item.TotalPrice)+parseFloat(item.TaxAmount);
                                              cust.TotalVATAmount =
                                                cust.TotalVATAmount +
                                                (item.TaxAmount);
                                            })
                                            setLineItems(lineItems.map(li=>li))
                                           

                                            }}
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                          />
                                        </Grid>
                                        <Grid item xs={2}>
                                        <TextField
                                            size="small"
                                            variant="standard"
                                            value={item.Remark}
                                            sx={{ width: "70px" }}
                                            onChange={(e) => {
                                              item.Remark = e.target.value;
                                              setSelectedItems(
                                                selectedItems.map((litem) => {
                                                  return litem;
                                                })
                                              );
                                            }}
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                          />
                                        </Grid>
                                       
                                      </>
                                    );
                                  })}
                              <Grid item xs={12}>
                                <hr></hr>
                              </Grid>
                           
                            </Grid>
                          </Grid>
                          <Grid item className="hideInFull" m={1} xs={12}>
                            {lineItems &&  lineItems.length > 0 &&
                              lineItems
                                .filter(
                                  (it) =>
                                    parseInt(it.Supplier) === parseInt(cust.Id)
                                )
                                .map((item, index) => {
                                  /*  totalAmount =
                        totalAmount + (item.UnitPrice) * item.Quantity;
                      totalVat =
                        totalVat + (item.UnitPrice) * item.Quantity * 0.15; */
                                  return (
                                    <>
                                      <Card sx={{ borderRadius: 10, boxShadow: 3,width:"100%"}} >
                                        <CardContent>
                                          <Grid container>
                                            <Grid item xs={4}>
                                              No.:
                                            </Grid>
                                            <Grid item xs={8} pl={0}>
                                              {index + 1}
                                            </Grid>
                                            <Grid item xs={4}>
                                              Product:
                                            </Grid>
                                            <Grid item xs={8} pl={0}>
                                             {item.Name}
                                            </Grid>
                                            <Grid item xs={4}>
                                              Quantity:
                                            </Grid>
                                            <Grid item xs={8} pl={0}>
                                              <TextField
                                                size="small"
                                                variant="standard"
                                                type="float"
                                                value={item.Quantity}
                                                maxRows={4}
                                                sx={{ width: "100%" }}
                                                onChange={(e) => {
                                                  item.Quantity =
                                                    e.target.value;
                                                    setSelectedItems(
                                                      selectedItems.map((litem) => {
                                                      return litem;
                                                    })
                                                  );
                                                }}
                                                InputLabelProps={{
                                                  shrink: true,
                                                }}
                                              />
                                            </Grid>

                                            <Grid item xs={4}>
                                              Unit Price:
                                            </Grid>
                                            <Grid item xs={8} pl={0}>
                                              <TextField
                                                size="small"
                                                variant="standard"
                                                type="float"
                                                value={item.UnitPrice}
                                                sx={{ width: "100%" }}
                                                onChange={(e) => {
                                                  item.UnitPrice =
                                                    e.target.value;
                                                    setSelectedItems(
                                                      selectedItems.map((litem) => {
                                                      return litem;
                                                    })
                                                  );
                                                }}
                                                InputLabelProps={{
                                                  shrink: true,
                                                }}
                                              />
                                            </Grid>
                                            <Grid item xs={4}>
                                              Total Price:
                                            </Grid>
                                            <Grid item xs={8} pl={8}>
                                              {(
                                                item.UnitPrice * item.Quantity
                                              )?.toLocaleString(undefined, {
                                                minimumFractionDigits: 2,maximumFractionDigits: 2,
                                              })}
                                            </Grid>
                                            <Grid item xs={12}>
                                              <hr></hr>
                                              Remove: {"   "}
                                              <Tooltip title="remove">
                                                <Button
                                                  endIcon={
                                                    <Delete width="10px" />
                                                  }
                                                  onClick={() => {
                                                    handleLineItemDelete(
                                                      lineItems.filter(
                                                        (litem) =>
                                                          litem.Item ===
                                                          item.Item
                                                      )[0].Id
                                                    );
                                                    removeFromCart(item);
                                                    /*   setLineItems(
                                    lineItems.filter(
                                      (litem) => !(litem.Item === item.Item)
                                    )
                                  ); */
                                                    if (
                                                      lineItems.filter(
                                                        (litem) =>
                                                          !(
                                                            litem.Item ===
                                                            item.Item
                                                          )
                                                      ).length <= 0
                                                    ) {
                                                      let newLineItem = {};
                                                      newLineItem.Item = "";
                                                      newLineItem.Name = "";
                                                      newLineItem.Supplier =
                                                        cust.Id;
                                                      newLineItem.UnitCost = 0;
                                                      newLineItem.Quantity = 0;
                                                      newLineItem.UnitPrice = 0;
                                                      newLineItem.TaxAmount = 0;
                                                      newLineItem.TotalPrice = 0;
                                                      setSelectedItems((xx) => [
                                                        ...xx,
                                                        newLineItem,
                                                      ]);
                                                    }
                                                  }}
                                                ></Button>
                                              </Tooltip>
                                            </Grid>
                                          </Grid>
                                        </CardContent>
                                      </Card>
                                      <hr></hr>
                                    </>
                                  );
                                })}
                            <Table>
                              <tr>
                                <td />
                                <td hidden />
                                <td />
                                <td
                                  style={{ border: "solid 1", padding: "5px" }}
                                >
                                  {" "}
                                </td>
                                <td
                                  style={{
                                    border: "solid 1",
                                    padding: "5px",
                                    backgroundColor: theme.palette.primary.main,
color:theme.palette.primary.contrastText,
                                  }}
                                  colSpan={2}
                                >
                                  {" "}
                                  Sub Total:
                                </td>

                                <td
                                  style={{
                                    border: "solid 1",
                                    padding: "5px",
                                    textDecoration: "underline",
                                    backgroundColor: theme.palette.primary.main,
color:theme.palette.primary.contrastText,
                                  }}
                                >
                                  {cust.TotalAmount?.toLocaleString(undefined, {
                                    minimumFractionDigits: 2,maximumFractionDigits: 2,
                                  })}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    border: "solid 1",
                                    padding: "5px",
                                  }}
                                ></td>
                                <td
                                  style={{ border: "solid 1", padding: "5px" }}
                                  hidden
                                >
                                  {" "}
                                </td>
                                <td
                                  style={{ border: "solid 1", padding: "5px" }}
                                >
                                  {" "}
                                </td>
                                <td
                                  style={{ border: "solid 1", padding: "5px" }}
                                >
                                  {" "}
                                </td>

                                <td
                                  style={{
                                    border: "solid 1",
                                    padding: "5px",
                                    backgroundColor: theme.palette.primary.main,
color:theme.palette.primary.contrastText,
                                  }}
                                  colSpan={2}
                                >
                                  {" "}
                                  VAT Amount:
                                </td>
                                <td
                                  style={{
                                    border: "solid 1",
                                    padding: "5px",
                                    backgroundColor: theme.palette.primary.main,
color:theme.palette.primary.contrastText,
                                    textDecorationLine: "underline",
                                  }}
                                >
                                  {cust.TotalVATAmount?.toLocaleString(undefined, {
                                    minimumFractionDigits: 2,maximumFractionDigits: 2,
                                  })}
                                </td>
                              </tr>

                              <tr>
                                <td
                                  style={{
                                    border: "solid 1",
                                    padding: "5px",
                                  }}
                                ></td>
                                <td
                                  style={{ border: "solid 1", padding: "5px" }}
                                  hidden
                                >
                                  {" "}
                                </td>
                                <td
                                  style={{ border: "solid 1", padding: "5px" }}
                                >
                                  {" "}
                                </td>
                                <td
                                  style={{ border: "solid 1", padding: "5px" }}
                                >
                                  {" "}
                                </td>

                                <td
                                  style={{
                                    border: "solid 1",
                                    padding: "5px",
                                    backgroundColor: theme.palette.primary.main,
color:theme.palette.primary.contrastText,
                                  }}
                                  colSpan={2}
                                >
                                  {" "}
                                  Total Amount:
                                </td>
                                <td
                                  style={{
                                    border: "solid 1",
                                    padding: "5px",
                                    textDecoration: "underline",
                                    backgroundColor: theme.palette.primary.main,
color:theme.palette.primary.contrastText,
                                  }}
                                >
                                  {" "}
                                  {(cust.TotalAmount + cust.TotalVATAmount).toLocaleString(
                                    undefined,
                                    {
                                      minimumFractionDigits: 2,maximumFractionDigits: 2,
                                      minFractionDigits: 2,
                                    }
                                  )}{" "}
                                </td>
                              </tr>
                            </Table>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          border={1}
                        
                        >
                          <label htmlFor="Attachment">
                            Uplaod payment document
                            <input
                              id="Attachment"
                              name="Attachment"
                              type="file"
                              multiple
                              hidden
                              onChange={uploadMultipleFiles}
                            />
                          </label>
                          {Photofiles && Photofiles.length > 0 && (
                            <Button
                              style={{ color: "red" }}
                              ml={4}
                              endIcon={<Close />}
                              onClick={() => {
                                setPhotofiles([]);
                              }}
                            />
                          )}
                          <div className="form-group multi-preview">
                            {(Photofiles || []).map((address) => (
                              <img
                                src={address}
                                alt="..."
                                style={{ width: "100%", height: "100" }}
                              />
                            ))}
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                );
              }})}
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{
            backgroundColor: theme.palette.primary.main,
color:theme.palette.primary.contrastText,
            fontSize: "8px",
          }}
        >
          <hr></hr>

     
              <Button variant="standard" endIcon={<PreviewSharp />}>
                
                <InvoicePreview
                  icon={<div style={{ fontSize: "10px" }}> Preview</div>}
                  LineItems={lineItems}
                  Suppliers={[{...props.data,Id:props.data.Supplier,Status:'Received'}]}
                  Type="InvoiceReceving"
                  DocumentType="Purchase Receipt"
                />
              </Button>|
              {globalRoleSecurity.filter(sec=>(sec.Functionality==="Purchase Receipt" && sec.Security>1)).length>0 &&
<>
              <Button
              variant="standard"
                style={{ fontSize: "10px" }}
                endIcon={<ReceiptSharp />}
                autoFocus
                onClick={() =>
                  handleSubmit(
                     0,
                    "Received",
                    orderDate,
                    lineItems.filter((itt) => itt.Name && itt.Name.length > 0)
                  )
                }
              >
                Received 
              </Button>|
              </>
              }
          
          <Button
          variant="standard"
            style={{ fontSize: "10px" }}
          endIcon={<Close />} 
            sx={{ alignSelf: "end" }}
            onClick={() => setOpen(false)}
          >
            close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
