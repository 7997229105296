import { Autocomplete, Button, FormControlLabel, Grid, Radio, RadioGroup, TextField } from "@mui/material";
import { useContext, useRef, useState } from "react";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useTranslation } from "react-i18next";

import { CloseSharp, SaveAsSharp } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import axios from "../../axiosInstances";
import { ShoppingCartContext } from "../Context/ShoppingCartContext";

export default function SecurityModel(props) {
  const theme=useTheme();
  const LogoInputRef = useRef(null);
  const { t, i18n } = useTranslation();
  const { globalUser,globalToken,setGlobalRoleSecurity,globalRoleSecurity,globalStores } =useContext(ShoppingCartContext)
  const [open, setOpen] = useState(false);

  const [id, setId] = useState(props.data.Id);
  const [role, setRole] = useState(props.data.Role); 
  const [store, setStore] = useState(props.data.Store); 
  const [department, setDepartment] = useState(props.data.Department); 
  const [functionality, setFunctionality] =useState(props.data.Functionality);
  const [read, setRead] = useState(props.data.Read===null?0:props.data.Read);
  const [modify, setModify] = useState(props.data.Modify===null?0:props.data.Modify);
  const [create, setCreate] = useState(props.data.Create===null?0:props.data.Create);
  const [remark, setRemark] = useState(props.data.Remark);
  const [rolesList,setRolesList]=useState(props.Roles);

  const [SecurityList]=useState(props.SecurityList);
  const [functionalitiesList,setFunctionalitiesList]=useState(SecurityList.filter(sec=>(sec.Department===props.data.Department &&  sec.Role===role)).map(sec=> {return sec.Functionality}));
  const loadRoleSecurity = () => {
    axios
      .get(
        "/security/getByParentAndRole?Parent=" +
          globalUser.Parent +"&Role="+globalUser.Role, {
            headers: {
              token: globalToken,
            },
          }
      )
      .then((response) => {
        if (response.data.data) {
          setGlobalRoleSecurity(response.data.data);
        } else {
          alert("log out and log in again");
        }
      });
  };
  const handleSubmit = () => {
    if(role===undefined || role===null || role.length<=0){
      alert('select the role first')
      return;
    }
  
    if((department===undefined || department===null || department.length<=0) && (functionality===undefined || functionality===null | functionality.length<=0)){
      alert('select the department or the functionality first')
      return;
    }
    if (
      window.confirm(
        "Are you sure you want to save?"
      )
    ) {
    try {
      var fd = new FormData();
      fd.append("Id", id);
      fd.append("Role", role);
      fd.append("Store", store);
      fd.append("Functionality",JSON.stringify(functionality && functionality.length>0?[functionality]:functionalitiesList));
      fd.append("Read", read?1:0);
      fd.append("Modify", modify?1:0);
      fd.append("Create", create?1:0);  
      fd.append("Parent", globalUser.Parent); 
      fd.append("Remark", remark);

      axios.post("/security", fd, {
        headers: { "Content-Type": "application/json" ,
            token: globalToken,
         },
      })
      .then(()=>loadRoleSecurity())
    } catch (err) {
    } finally {
      

      props.setParentUpdate(!props.ParentUpdate);
      setOpen(false);
    }
  }
  };

/* const clearData=()=>{
  setId(0);
  setDepartment("");
  setRole("")
  setFunctionality("")
  setRead(0);
  setModify(0);
  setCreate(0);
  setRemark("")
} */


  return (
    <div width="500">
      <label onClick={() => setOpen(true)}>{props.icon}</label>
      <Dialog
      open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="responsive-dialog-title"
      >
      
        <DialogTitle  style={{backgroundColor: theme.palette.primary.main,color:theme.palette.primary.contrastText, height:'20px'}}>User Security </DialogTitle> 
        <DialogContent>
          <DialogContentText>
              <hr></hr>

              <Grid container>
              <Grid item xs={12} md={6}>
                  <Autocomplete
                    //disabled={((status === "Received") || (globalUser.Name===transferedTo)) ? true : false}
                    disablePortal
                    selectOnFocus
                    autoHighlight
                    value={role}
                    id="role"
                    onSelect={(e) => {
                      setRole(e.target.value)}}
                    options={!(rolesList === undefined) &&
                      !(rolesList === null) &&
                      rolesList.length > 0
                        && rolesList.map((item) => {
                            return item.Description;
                          })
                    }
                    sx={{
                      width: "95%",
                      marginBottom: 2,
                      borderRadius: "25%",
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select role"
                        variant="standard"
                        //error={!fromAirport ? true : false}
                      />
                    )}
                  />
                   </Grid>
                   <Grid item xs={12} md={6}>
                  <Autocomplete
                    //disabled={((status === "Received") || (globalUser.Name===transferedTo)) ? true : false}
                    disablePortal
                    selectOnFocus
                    autoHighlight
                    contentEditable={false}
                    value={store && globalStores.filter(st=>st.Id===store).length>0 ? globalStores.filter(st=>st.Id===store)[0].Name:''}
                    id="Store"
                    onSelect={(e) => {
                      setStore(globalStores.filter(st=>st.Name===e.target.value).length>0 ? globalStores.filter(st=>st.Name===e.target.value)[0].Id:'')
                    }}
                    options={!(globalStores === undefined) &&
                      !(globalStores === null) &&
                      globalStores.length > 0
                        && globalStores.map((item) => {
                            return item.Name;
                          })
                    }
                    sx={{
                      width: "95%",
                      marginBottom: 2,
                      borderRadius: "25%",
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Store"
                        variant="standard"
                        //error={!fromAirport ? true : false}
                      />
                    )}
                  />
                   </Grid>
                   <Grid item xs={12} md={6}>
                  <Autocomplete
                    //disabled={((status === "Received") || (globalUser.Name===transferedTo)) ? true : false}
                    disablePortal
                    selectOnFocus
                    autoHighlight
                    contentEditable={false}
                    value={department}
                    id="department"
                    onSelect={(e) => {
                      setDepartment(e.target.value);
              setFunctionality("");
                     setFunctionalitiesList(SecurityList.filter(sec=>(sec.Department===e.target.value &&  sec.Role===role)).map(sec=> {return sec.Functionality}));
                     
                    }
                    }
                    options={["Admin","Inventory","Purchase","Sales","Job Order"]
                    }
                    sx={{
                      width: "95%",
                      marginBottom: 2,
                      borderRadius: "25%",
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Department"
                        variant="standard"
                        //error={!fromAirport ? true : false}
                      />
                    )}
                  />
                   </Grid>
                   <Grid item xs={12} md={6}>
                  <Autocomplete
                    //disabled={((status === "Received") || (globalUser.Name===transferedTo)) ? true : false}
                    disablePortal
                    selectOnFocus
                    autoHighlight
                    contentEditable={false}
                    value={functionality}
                    id="role"
                    onSelect={(e) => {
                      setFunctionality(e.target.value)}}
                    options={!(functionalitiesList === undefined) &&
                      !(functionalitiesList === null) &&
                      functionalitiesList.length > 0
                        ? functionalitiesList.map((item) => {
                            return item;
                          })
                        : [""]
                    }
                    sx={{
                      width: "95%",
                      marginBottom: 2,
                      borderRadius: "25%",
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select functionality"
                        variant="standard"
                        //error={!fromAirport ? true : false}
                      />
                    )}
                  />
                   </Grid>
                <Grid item xs={12} md={6}>
                  <RadioGroup
                  aria-labelledby="security"
                  title="sex"
                  defaultValue={read?'Read':modify?'Modify':create?'Create':null}
                  name='rbSecurity'
                  onChange={(e)=>{
                   if(e.target.value==='Deny'){
                    setRead(false);
                    setModify(false);
                    setCreate(false);
                   }else{
                    e.target.value==='Read'?
                     setRead(true):setRead(false)
                     e.target.value==='Modify'?
                     setModify(true):setModify(false)
                     e.target.value==='Create'?
                     setCreate(true):setCreate(false) 
                   }
                    

                  }}>
                    <FormControlLabel value='Deny' control={<Radio />} label="Deny" />
                    <FormControlLabel value='Read' control={<Radio />} label="Read" />
                    <FormControlLabel value='Modify' control={<Radio />} label="Modify" />
                    <FormControlLabel value='Create' control={<Radio />} label="Create" />
                  </RadioGroup>

{/* <CheckBox 
defaultChecked={read}
onChange={(e)=>{
  setRead(e.target.checked)
}}
/>Read
                </Grid>
                <Grid item xs={12} md={6}>
<CheckBox 
checked={modify}
onChange={(e)=>{
  setModify(e.target.checked)
}}
/>Modify
                </Grid>
                <Grid item xs={12} md={6}>
<CheckBox 
checked={create}
onChange={(e)=>{
  setCreate(e.target.checked)
}}
/>Create */}
</Grid>
                <Grid item xs={12} md={6}>
               

                <TextField
                          size="small"
                          label="Remark"
                          value={remark}
                          multiline
                          minRows={2}
                          onChange={(e) => {
                            setRemark(e.target.value)
                          }}
                          sx={{ width: "95%", marginBottom: 2 }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                        </Grid>
              </Grid>
          
            <hr></hr>
          </DialogContentText>
        </DialogContent>
        <DialogActions  style={{backgroundColor: theme.palette.primary.main,color:theme.palette.primary.contrastText}}>
        {globalRoleSecurity.filter(sec=>(sec.Functionality==="Security" && sec.Security>1)).length>0 &&
<>
          <Button variant="standard" autoFocus onClick={()=>handleSubmit()} endIcon={<SaveAsSharp />}>
            Save
          </Button>|
          </>
}

        
          <Button variant="standard"  onClick={() => setOpen(false)} autoFocus endIcon={<CloseSharp />}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
