import { useState, useEffect,useContext } from "react";
import {
  Button,
  Autocomplete,
  TextField,
  Grid,
} from "@mui/material";
import { CloseSharp, DeleteSharp, SaveSharp } from "@mui/icons-material"
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
//import DialogTitle from "@mui/material/DialogTitle";
import { useTranslation } from "react-i18next";
import { ShoppingCartContext } from "../Context/ShoppingCartContext";
import axios from "../../axiosInstances";
import {format} from 'date-fns'
import { useTheme } from "@mui/material/styles";

export default function StockBalancesModel(props) {
  const theme=useTheme();
  const { t, i18n } = useTranslation();

  const [open, setOpen] = useState(false);
  const { globalUser,globalStores,globalToken,globalRoleSecurity}=useContext(ShoppingCartContext)
  var fileObj = [];
  var fileArray = [];
  const [id, setId] = useState(props.data.Id);
  const [item, setItem] = useState(props.data.Item);
  const [storeList, setStoreList] = useState(globalStores);
  const [store, setStore] = useState(props.data?storeList.filter(st=>parseInt(st.Id)===parseInt(props.data.Store)).length>0?storeList.filter(st=>parseInt(st.Id)===parseInt(props.data.Store))[0].Name:'':'');

  const [itemName, setItemName] = useState(props.data.Name);
  const [reference, setReference] = useState(props.data.ReferenceNo);
  const [expiryDate, setExpiryDate] = useState(props.data.expiryDate?props.data.ExpiryDate:format(new Date(),'yyyy-MM-dd'));
  const [manufacturingCountry, setManufacturingCountry] = useState(props.data.MfrCountry);
  const [manufacturingDate, setManufacturingDate] = useState(props.data.MfrDate?props.data.MfrDate:format(new Date(),'yyyy-MM-dd'));
  const [quantity, setQuantity] = useState(props.data.Quantity);
  const [price, setPrice] = useState(props.data.Price);
  const [defaultOrderQty, setDefaultOrderQty] = useState(props.data.DefaultOrderQty);
  const [parentCategoriesList,setParentCategoriesList]=useState([])
 
  const handleSubmit = () => {
    
    if (store===undefined || store===null || store.length<=0){
      alert("First select the store ");   
      return;
    }
    if (
      window.confirm(
        "Are you sure you want to save?"
      )
    ) {
      
    try {
      var fd = new FormData();
      fd.append("Id", id);
      fd.append("Item", item);
      fd.append("Supplier", globalUser.Parent);
      
      fd.append("Reference", reference);

      fd.append("Store", storeList && storeList.filter(st=>st.Name===store).length>0?storeList.filter(st=>st.Name===store)[0].Id:0);
     
      fd.append("ExpiryDate", expiryDate);
      fd.append("MfrCountry", manufacturingCountry);
      fd.append("MfrDate", manufacturingDate);
      fd.append("Quantity", quantity);
          fd.append("Price", price);
      fd.append("DefaultOrderQty", defaultOrderQty);

      axios.post("/stockBalances", fd, {
        headers: { "Content-Type": "application/json" ,
            token: globalToken,
         },
      });
    } catch (err) {
    } finally {
      props.setParentUpdate(!props.ParentUpdate);
      setOpen(false);
    }
  }
  };
  const handleDelete = () => {
    if (
      window.confirm(
        "Are you sure you want to delete?"
      )
    ) {
    try {
      axios.delete("/stockBalances?Id=" + id, {
        headers: {
          token: globalToken,
        },
      });
    } catch (err) {
    } finally {
      props.setParentUpdate(!props.ParentUpdate);
      setOpen(false);
    }
  }
  };

  const getListOfCategories = () => {
    try {
      axios.get("/itemCategories", {
        headers: {
          token: globalToken,
        },
      }).then((result) => {
        setParentCategoriesList(result.data.data);
      });
    } catch (err) {}
  };

  useEffect(() => {
    getListOfCategories();

  }, []);

  return (
    <div width="500">
      <label onClick={() => setOpen(true)}>{props.icon}</label>
      <Dialog
      open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="responsive-dialog-title"
      >
    
        <DialogTitle id="responsive-dialog-title" bgcolor={theme.palette.primary.main} color={theme.palette.primary.contrastText}> 
        Parent Company: {globalUser.ParentName} <br></br>
        Item Id: {item} <br></br>
        Item Name:  {itemName}
        </DialogTitle> 
      
        <DialogContent>
          <DialogContentText>
          <hr></hr>

              <Grid container>
              <Grid item xs={6} mt={1}>
                        <Autocomplete
                          label="Issued To"
                          id="Store"
                          size="small"
                          variant="standard"
                          value={store}
                          onSelect={(e) => {
                            setStore(e.target.value);
                                            }
                          }
                          options={
                            !(storeList === undefined) &&
                            !(storeList === null) &&
                            storeList.length > 0 &&
                            storeList.map((pro) => {
                              return pro.Name;
                            })
                          }
                          sx={{ width: "95%" }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={t("Store")}

                              //error={!from ? true : false}
                            />
                          )}
                        />
                      </Grid>
              <Grid item xs={12} md={6}>
                  <TextField
                  disabled={true}
                  size="small"
                  variant="standard"
                  label="Inventory"
                  defaultValue={quantity}
                  type="float"
                  maxRows={4}
                  sx={{  width: "95%", marginBottom: 2 }}
                  onChange={(e) => {
                    setQuantity(e.target.value);
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                  </Grid>
               <Grid item xs={12} md={6}>
                  <TextField
                    size="small"
                    variant="standard"
                    label="Unit Sales Price"
                    type  ='float'
                    defaultValue={price}
                    sx={{ width: "95%", marginBottom: 2 }}
                    onChange={(e) => {
                      setPrice(e.target.value);
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                    </Grid>
               <Grid item xs={12} md={6}>
                    <TextField
                    size="small"
                    variant="standard"
                    label="Default Order Quantity"
                    type='float'
                    defaultValue={defaultOrderQty}
                                      sx={{ width: "95%", marginBottom: 2 }}
                    onChange={(e) => {
                      setDefaultOrderQty(e.target.value);
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                          
                  <TextField
                    size="small"
                    variant="standard"
                    label="Reference"
                    defaultValue={reference}
                    onChange={(e) => {
                      setReference(e.target.value);
                    }}
                    sx={{ width: "95%", marginBottom: 2 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                    </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    size="small"
                    type="date"
                    variant="standard"
                    label="Expiry Date"
                    value={expiryDate}
                    onChange={(e) => {
                      setExpiryDate(e.target.value);
                    }}
                    sx={{ width: "95%", marginBottom: 2 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                    </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    size="small"
                    variant="standard"
                    label="Manufacturing Country"
                    defaultValue={manufacturingCountry}
                                   sx={{ width: "95%", marginBottom: 2 }}
                    onChange={(e) => {
                      setManufacturingCountry(e.target.value);
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
          

                </Grid>
               <Grid item xs={12} md={6}>
               <TextField
                    size="small"
                    variant="standard"
                    label="Manufacturing Date"
                    type='date'
                    defaultValue={manufacturingDate}
                    sx={{ width: "95%", marginBottom: 2 }}
                    onChange={(e) => {
                      setManufacturingDate(e.target.value);
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />  
                    </Grid>
              
              </Grid>
          
            <hr></hr>
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ backgroundColor: theme.palette.primary.main,color:theme.palette.primary.contrastText,height:'20px' }}>
        {globalRoleSecurity.filter(sec=>(sec.Functionality==="Stock Balance" && sec.Security>1)).length>0 &&
<>
          <Button variant="standard" endIcon={<SaveSharp />} autoFocus onClick={handleSubmit}>
            Save
          </Button>|</>
}
{globalRoleSecurity.filter(sec=>(sec.Functionality==="Stock Balance" && sec.Security>2)).length>0 &&
<>
          <Button variant="standard" endIcon={<DeleteSharp />} autoFocus onClick={handleDelete}>
            Delete
          </Button>|</>
}
          <Button variant="standard" endIcon={<CloseSharp />} onClick={() => setOpen(false)} autoFocus>
            Close
          </Button>
       
        </DialogActions>
      </Dialog>
    </div>
  );
}
