import { Clear, CloseSharp, DeleteSharp, Save } from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import { useTheme } from "@mui/material/styles";
import axios from "../../axiosInstances";
import { ShoppingCartContext } from "../Context/ShoppingCartContext";

export default function ItemCategoriesModel(props) {
  const theme = useTheme();

  const { t, i18n } = useTranslation();
  const { globalToken, globalRoleSecurity } = useContext(ShoppingCartContext);
  const [open, setOpen] = useState(false);
  var fileObj = [];
  var fileArray = [];
  const [id, setId] = useState(props.data.Id);
  const [name, setName] = useState(props.data.Name);

  const [Photofiles, setPhotofiles] = useState(splitPictures());
  const [remark, setRemark] = useState(props.data.Remark);
  const [parentCategoriesList, setParentCategoriesList] = useState(
    props.CategoriesList
  );
  const [parentCategory, setParentCategory] = useState(
    props.data.ParentCategoryName
  );
  function splitPictures() {
    try {
      fileArray = [];
      props.data.Picture?.split(",").map(function (address, index) {
        if (!fileArray.includes(address) && address.length > 0) {
          fileArray.push(address);
          //document.getElementById('file').files.push(new Blob(address))
        }
      });
      return fileArray;
    } catch (err) {
      console.log(err);
    }
  }
  function uploadMultipleFiles(e) {
    fileObj.push(e.target.files);
    for (let i = 0; i < fileObj[0].length; i++) {
      fileArray.push(URL.createObjectURL(fileObj[0][i]));
    }
    setPhotofiles(fileArray);
  }
  const handleSubmit = () => {
    if (name === undefined || name === null || name.length <= 0) {
      alert("write the name first");
      return;
    }
    if (window.confirm("Are you sure you want to save?")) {
      try {
        var fd = new FormData();
        fd.append("Id", id);
        fd.append("Name", name);
        fd.append("ParentCategory", parentCategory);

        if (
          Array.from(document.getElementById("itemCategoryPicture").files)
            .length > 0
        ) {
          Array.from(document.getElementById("itemCategoryPicture").files).map(
            (file) => {
              fd.append("Picture", file);
            }
          );
        } else {
          fd.append("OriginalPicture", Photofiles);
        }
        fd.append("Remark", remark);

        axios.post("/itemCategories", fd, {
          headers: {
            "Content-Type": "multipart/form-data",
            token: globalToken,
          },
        });
      } catch (err) {
      } finally {
        props.setParentUpdate(!props.ParentUpdate);
        setOpen(false);
      }
    }
  };

  const handleDelete = async () => {
    try {
      await axios
        .get("/items/getByCategory?Category=" + id, {
          headers: {
            token: globalToken,
          },
        })
        .then((response) => {
          if (response.data.data.length <= 0) {
            deleteNoTransaction();
          } else {
            alert("Can not delete this category.It has assigned to some items");
            setOpen(false);
            return;
          }
        });
    } catch (err) {
    } finally {
      props.setParentUpdate(!props.ParentUpdate);
      setOpen(false);
    }
  };
  const deleteNoTransaction = () => {
    if (window.confirm("Are you sure you want to delete?")) {
      try {
        axios.delete("/itemCategories/delete?Id=" + id, {
          headers: {
            token: globalToken,
          },
        });
      } catch (err) {
      } finally {
        props.setParentUpdate(!props.ParentUpdate);
        setOpen(false);
      }
    }
  };

  return (
    <div width="500">
      <label onClick={() => setOpen(true)}>{props.icon}</label>
      <Dialog
      open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle
          style={{
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            height: "20px",
          }}
        >
          {props.Title}{" "}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <hr></hr>

            <Grid container>
              <Grid item xs={12} md={6}>
                <TextField
                  size="small"
                  variant="standard"
                  label={t("Name")}
                  defaultValue={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  sx={{ width: "95%", marginBottom: 2 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  //disabled={((status === "Received") || (globalUser.Name===transferedTo)) ? true : false}
                  disablePortal
                  selectOnFocus
                  autoHighlight
                  contentEditable={false}
                  value={parentCategory}
                  id="parentcategory"
                  onSelect={(e) => {
                    setParentCategory(e.target.value);
                  }}
                  options={
                    !(parentCategoriesList === undefined) &&
                    !(parentCategoriesList === null) &&
                    parentCategoriesList.length > 0
                      ? parentCategoriesList.map((item) => {
                          return item.Name;
                        })
                      : [""]
                  }
                  sx={{
                    width: "95%",
                    marginBottom: 2,
                    borderRadius: "25%",
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Parent Parent Category"
                      variant="standard"
                      //error={!fromAirport ? true : false}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  size="small"
                  variant="standard"
                  label="Remark"
                  defaultValue={remark}
                  multiline
                  maxRows={4}
                  sx={{ width: "95%", marginBottom: 2 }}
                  onChange={(e) => {
                    setRemark(e.target.value);
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                {Photofiles && (
                  <IconButton
                    onClick={() => {
                      setPhotofiles([]);
                    }}
                  >
                    {
                      <Clear
                        style={{ width: "20px", height: "20px", color: "red" }}
                      />
                    }
                  </IconButton>
                )}
                <label htmlFor="itemCategoryPicture">
                  Category Picture
                  <input
                    type="file"
                    id="itemCategoryPicture"
                    multiple
                    onChange={uploadMultipleFiles}
                    hidden
                  />
                  <div className="form-group multi-preview">
                    {(Photofiles || []).map((address) => (
                      <img
                        src={address}
                        alt="..."
                        style={{ width: "95%", height: "95" }}
                      />
                    ))}
                  </div>
                </label>
              </Grid>
            </Grid>

            <hr></hr>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
          }}
        >
          {globalRoleSecurity.filter(
            (sec) => sec.Functionality === "Item Categories" && sec.Security > 1
          ).length > 0 && (
            <>
              <Button
                variant="standard"
                autoFocus
                onClick={() => handleSubmit()}
                endIcon={<Save />}
              >
                Save
              </Button>
              |
            </>
          )}
          {globalRoleSecurity.filter(
            (sec) => sec.Functionality === "Item Categories" && sec.Security > 2
          ).length > 0 && (
            <>
              <Button
                variant="standard"
                autoFocus
                onClick={() => handleDelete()}
                endIcon={<DeleteSharp />}
              >
                Delete
              </Button>
              |
            </>
          )}
          <Button
            variant="standard"
            onClick={() => setOpen(false)}
            autoFocus
            endIcon={<CloseSharp />}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
