import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Typography,
  Avatar,
  Grid,
  IconButton,
  Button,
  Box,
  Tooltip,
  MenuItem,
  DialogActions,
} from "@mui/material";
import {
  EditSharp,
  AddSharp,
  FileDownload,
  RefreshSharp,
  WorkOffSharp,
  ShoppingBag,
  TransferWithinAStation,
  PreviewSharp,
  PaymentSharp,
  PaymentsSharp,
} from "@mui/icons-material";
import MaterialReactTable from "material-react-table";
import { useState, useEffect, useMemo, useContext } from "react";
import VouchersModel from "../VouchersModel";

import JobsModel from "../Manufacturing/JobsModel";
//import SupplierModel from "./SuppliersModel"
import { useLocation } from "react-router-dom";
import * as XLSX from "xlsx";
import axios from "../../axiosInstances";
import InvoicePreview from "../Reports/InvoicePreview";
import { ShoppingCartContext } from "../Context/ShoppingCartContext";
import SalesNewOrder from "./SalesNewOrder";
import SalesDeliveryOrder from "./SalesDeliveryOrder";
import ItemTransferModel from "../Inventory/ItemTransferModel";
import PaymentModel from "../PaymentsModel";
import { format } from "date-fns";
import PaymentsModelHistory from "../PaymentsModelHistory";
import { useNavigate } from "react-router-dom";

//import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";

function SalesVouchersComp(props) {
  const navigate = useNavigate();
  const theme = useTheme();
  const [update, setUpdate] = useState(false);
  const location = useLocation();
  //const [SupplierCategoriesList, setSupplierCategoriesList] = useState([]);
  const [VouchersList, setVouchersList] = useState([]);
  const [DeliveryVouchersList, setDeliveryVouchersList] = useState([]);
  const [DeliveryLineItemsList, setDeliveryLineItemsList] = useState([]);
  const [transferList, setTransferList] = useState([]);
  const [transferItemsList, setTransferLineItemsList] = useState([]);
  const [LineItemsList, setLineItemsList] = useState([]);
  const [JobOrdersList, setJobOrdersList] = useState([]);
  const [JobLineItemsList, setJobLineItemsList] = useState([]);
  const [PaymentsOrderList, setPaymentsOrderList] = useState([]);
  const {
    globalUser,
    setPageHeader,
    globalToken,
    globalRoleSecurity,
    setSelectedSalesType,
  } = useContext(ShoppingCartContext);

  const [employeeList, setEmployeeList] = useState([]);

  const [loadTable, setLoadTable] = useState(false);

  const [storesList, setStoresList] = useState([]);
  const [bankList, setBankList] = useState([]);
  const loadBankList = () => {
    axios
      .get(
        "/settings/getByParentAndType?Parent=" +
          globalUser.Parent +
          "&Type=Bank",
        {
          headers: {
            token: globalToken,
          },
        }
      )
      .then((response) => {
        if (response.data.data) {
          setBankList(response.data.data);
        } else {
          // alert("log out and log in again voucher by voucher id");
        }
      });
  };

  //const { t, i18n } = useTranslation();
  const loadStoresList = () => {
    axios
      .get("/stores/getByParent?Parent=" + globalUser.Parent, {
        headers: {
          token: globalToken,
        },
      })
      .then((response) => {
        if (response.data.data) {
          setStoresList(response.data.data);
        } else {
          alert("log out and log in again");
        }
      });
  };
  //const { t, i18n } = useTranslation();

  const salesColumns = useMemo(
    () => [
      {
        header: "Order Id",
        accessorKey: "OrderId",
        size: "100",
      },
      {
        header: "Customer",
        accessorKey: "Name",
      },
      {
        header: "Date",
        accessorKey: "VoucherDate",
        size: "80",
      },
      /*   {
          header: "Preferred Date",
          accessorKey: "PreferredDate", 
        },
        {
          header: "Delivery Date",
          accessorKey: "DeliveryDate", 
        }, */
      {
        header: "Sub Total",
        accessorKey: "Amount",
        size: "150",
      },
      {
        header: "VAT Amount",
        accessorKey: "VATAmount",
        size: "150",
      },
      /*   {
          header: "Discount",
          accessorKey: "Discount", 
          size: "100"
        }, */
      {
        header: "Total Amount",
        accessorKey: "TotalAmount",
        size: "150",
      },
      {
        header: "Paid Amount",
        accessorKey: "PaidAmount",
      },
      /*   {
          header: "External Document",
          accessorKey: "ExternalDocument", 
        },
        {
          header: "User",
          accessorKey: "User", 
        },
        {
          header: "Machine Name",
          accessorKey: "MachineName", 
        },
        {
          header: "Store",
          accessorKey: "Store", 
        },
        {
          header: "Paid Amount",
          accessorKey: "Paid Amount", 
        },
       
        {
          header: "Remaining Amount",
          accessorKey: "RemainingAmount", 
        },
        {
          header: "System Date",
          accessorKey: "SystemDate", 
        },
        {
          header: "Messages",
          accessorKey: "Messages", 
        }, 
        {
          header: "Attachment",
          accessorKey: "Attachment", 
        }, 
        {
          header: "Remark",
          accessorKey: "Remark", 
        },  */

      {
        header: "Status",
        accessorKey: "Status",
        size: "80",
      },
      /*  {
          header: "Closed",
          accessorKey: "Closed", 
        }, */
    ],
    []
  );

  const loadVouchersList = () => {
    setLoadTable(false);

    axios
      .get(
        "/Vouchers/getBySalesTypeAndStatus?Customer=" +
          globalUser.Parent +
          "&Type=" +
          location.state.Type +
          "&Status=" +
          location.state.Status +
          "&Store=" +
          (location.state.Store === undefined ||
          location.state.Store === null ||
          isNaN(location.state.Store)
            ? 0
            : location.state.Store),
        {
          headers: {
            token: globalToken,
          },
        }
      )
      .then((response) => {
        if (response.data.data) {
          setLineItemsList(response.data.data.LineItems);
          setJobOrdersList(response.data.data.JobOrders);
          setJobLineItemsList(response.data.data.JobLines);
          setDeliveryVouchersList(response.data.data.DeliveryOrders);
          setDeliveryLineItemsList(response.data.data.DeliveryLineItems);
          setTransferList(response.data.data.TransferOrders);
          setTransferLineItemsList(response.data.data.TransferLineItems);
          setPaymentsOrderList(response.data.data.PaymentOrders);
          setVouchersList(response.data.data.Orders);
        } else {
          alert("log out and log in again");
        }
      })
      .then(() => {
        setLoadTable(true);
      });
  };
  const loadEmployeeList = () => {
    axios
      .get(
        "/employees/getByParentAndType?Parent=" +
          globalUser.Parent +
          "&Type=Employee",
        {
          headers: {
            token: globalToken,
          },
        }
      )
      .then((response) => {
        if (response.data.data) {
          setEmployeeList(response.data.data);
        } else {
          alert("log out and log in again");
        }
      });
  };

  function handleDownloadFile() {
    const worksheet = XLSX.utils.json_to_sheet(VouchersList);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "vouchers");
    //let buf = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
    XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workbook, "vouchersdata.xlsx");
  }

  useEffect(() => {
    if (!globalUser) {
      navigate("/");
      return;
    }
    setSelectedSalesType(location.state.Status);
    try {
      loadVouchersList();
    } catch (err) {
    } finally {
      loadBankList();
      loadStoresList();
      loadEmployeeList();
    }

    setPageHeader("Sales " + location.state.Status);
  }, [update, location.state]);
  return (
    <div width="100%">
      {loadTable &&
      employeeList !== undefined &&
     bankList !== null &&
      bankList.length > 0  ? (
        employeeList !== null && employeeList.length > 0 ? (
          <Grid container>
            <Grid item className="hideInMobile" m={1}>
              <MaterialReactTable
                columns={salesColumns}
                data={VouchersList}
                initialState={{
                  density: "compact",
                  pagination: {
                    pageIndex: 0,
                    pageSize: 12,
                  },
                }}
                enableGlobalFilter
                // enableColumnOrdering

                enableColumnResizing
                enableRowNumbers
                renderDetailPanel={({ row }) => (
                  <Grid
                    container
                    ml={2}
                    mr={4}
                    sx={{ backgroundColor: "smockwhite" }}
                  >
                    <Grid
                      item
                      xs={12}
                      style={{
                        backgroundColor: theme.palette.primary.light,
                        color: theme.palette.primary.contrastText,
                        fontWeight: "bold",
                      }}
                    >
                      {row.original.Status === "Draft" ? (
                        <IconButton>
                          <SalesNewOrder
                            icon={
                              <EditSharp
                                style={{
                                  width: "13px",
                                  height: "13px",
                                  margin: 1,
                                }}
                              />
                            }
                            Type="Sales"
                            BankList={bankList}
                            SourceNo={row.original.OrderId}
                            data={row.original}
                            LineItems={LineItemsList.filter(
                              (li) =>
                                parseInt(li.Reference) ===
                                parseInt(row.original.OrderId)
                            )}
                            ParentUpdate={update}
                            setParentUpdate={setUpdate}
                          />
                        </IconButton>
                      ) : (
                        <IconButton>
                          <VouchersModel
                            icon={
                              <EditSharp
                                style={{
                                  width: "13px",
                                  height: "13px",
                                  margin: 1,
                                }}
                              />
                            }
                            data={row.original}
                            Type={location.state.Type}
                            LineItems={LineItemsList.filter(
                              (li) => li.Reference === row.original.OrderId
                            )}
                            Title="EDIT Supplier"
                          />
                        </IconButton>
                      )}

                      {"|   Sales Line Items"}
                    </Grid>
                    {LineItemsList &&
                      LineItemsList.filter(
                        (li) => li.Reference === row.original.OrderId
                      ).map((litem, index) => {
                        let DeliveryQty = 0;
                        DeliveryLineItemsList &&
                          DeliveryLineItemsList.filter(
                            (shLine) =>
                              parseInt(shLine.SourceNo) ===
                                parseInt(row.original.OrderId) &&
                              parseInt(shLine.Item) === parseInt(litem.Item)
                          ).map((shItem) => {
                            DeliveryQty = DeliveryQty + shItem.Quantity;
                          });
                        return (
                          <>
                            {index === 0 ? (
                              <>
                                <Grid
                                  item
                                  xs={4}
                                  pl={12}
                                  sx={{
                                    backgroundColor: theme.palette.primary.dark,
                                    color: theme.palette.primary.contrastText,
                                  }}
                                >
                                  Item
                                </Grid>
                                <Grid
                                  item
                                  xs={2}
                                  sx={{
                                    backgroundColor: theme.palette.primary.dark,
                                    color: theme.palette.primary.contrastText,
                                  }}
                                  pl={12}
                                >
                                  Quantity
                                </Grid>
                                <Grid
                                  item
                                  xs={2}
                                  sx={{
                                    backgroundColor: theme.palette.primary.dark,
                                    color: theme.palette.primary.contrastText,
                                  }}
                                  pl={12}
                                >
                                  Delivery Qty
                                </Grid>
                                <Grid
                                  item
                                  xs={2}
                                  sx={{
                                    backgroundColor: theme.palette.primary.dark,
                                    color: theme.palette.primary.contrastText,
                                  }}
                                  pl={12}
                                >
                                  Unit Price
                                </Grid>
                                <Grid
                                  item
                                  xs={2}
                                  sx={{
                                    backgroundColor: theme.palette.primary.dark,
                                    color: theme.palette.primary.contrastText,
                                  }}
                                  pl={12}
                                >
                                  Total Price
                                </Grid>
                                {/*   <Grid
                                item
                                xs={2}
                               pl={12}
                                sx={{backgroundColor: theme.palette.primary.dark,color:theme.palette.primary.contrastText }}

                              >
                                VAT Amount
                              </Grid> */}

                                <Grid item xs={4} sx={{ border: 1 }} pl={2}>
                                  {index + 1 + "-" + litem.Name}
                                </Grid>
                                <Grid item xs={2} sx={{ border: 1 }} pl={2}>
                                  {litem.Quantity?.toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                                </Grid>
                                <Grid item xs={2} sx={{ border: 1 }} pl={2}>
                                  {DeliveryQty}
                                </Grid>

                                <Grid item xs={2} sx={{ border: 1 }} pl={2}>
                                  {litem.UnitPrice?.toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                                </Grid>
                                <Grid item xs={2} sx={{ border: 1 }} pl={2}>
                                  {litem.TotalPrice?.toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                                </Grid>
                                {/* <Grid item xs={2} sx={{ border: 1 }} pl={2}>
                                {litem.TaxAmount?.toLocaleString(undefined, {minimumFractionDigits:2,maximumFractionDigits:2})}
                              </Grid> */}
                              </>
                            ) : (
                              <>
                                <Grid item xs={4} sx={{ border: 1 }} pl={2}>
                                  {index + 1 + "-" + litem.Name}
                                </Grid>
                                <Grid item xs={2} sx={{ border: 1 }} pl={2}>
                                  {litem.Quantity?.toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                                </Grid>
                                <Grid item xs={2} sx={{ border: 1 }} pl={2}>
                                  {DeliveryQty}
                                </Grid>
                                <Grid item xs={2} sx={{ border: 1 }} pl={2}>
                                  {litem.UnitPrice?.toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                                </Grid>
                                <Grid item xs={2} sx={{ border: 1 }} pl={2}>
                                  {litem.TotalPrice?.toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                                </Grid>
                                {/*  <Grid item xs={2} sx={{ border: 1 }} pl={2}>
                                {litem.TaxAmount?.toLocaleString(undefined, {minimumFractionDigits:2,maximumFractionDigits:2})}
                              </Grid> */}
                              </>
                            )}
                          </>
                        );
                      })}

                    {JobOrdersList &&
                      JobOrdersList.filter(
                        (jo) =>
                          parseInt(jo.Order) === parseInt(row.original.OrderId)
                      ).map((jobOrder, index) => {
                        return (
                          <>
                            <Grid
                              item
                              xs={12}
                              style={{
                                backgroundColor: theme.palette.primary.light,
                                color: theme.palette.primary.contrastText,
                                fontWeight: "bold",
                              }}
                            >
                              <IconButton>
                                <JobsModel
                                  icon={
                                    <WorkOffSharp
                                      style={{
                                        width: "13px",
                                        height: "13px",
                                        margin: 1,
                                      }}
                                    />
                                  }
                                  data={row.original}
                                  JobOrders={[jobOrder]}
                                  ProductsList={LineItemsList.filter(
                                    (li) =>
                                      li.Reference === row.original.OrderId
                                  )}
                                  EmployeesList={employeeList}
                                  Jobslines={
                                    JobLineItemsList &&
                                    JobLineItemsList.filter(
                                      (jo) =>
                                        parseInt(jo.Job) ===
                                        parseInt(jobOrder.Id)
                                    )
                                  }
                                  Title="EDIT Supplier"
                                />
                              </IconButton>

                              {"Job Orders: " +
                                (parseInt(index) + 1) +
                                "       Status: " +
                                jobOrder.Status}
                            </Grid>

                            <Grid item xs={3} sx={{ border: 1 }} pl={2}>
                              {"Ordered By:  " + jobOrder.OrderedByName}
                            </Grid>
                            <Grid item xs={3} sx={{ border: 1 }} pl={2}>
                              {"Approved By:  " + jobOrder.ApprovedByName}
                            </Grid>
                            <Grid item xs={3} sx={{ border: 1 }} pl={2}>
                              {"Assigned To: " + jobOrder.AssignedToName}
                            </Grid>
                            <Grid item xs={3} sx={{ border: 1 }} pl={2}>
                              {"Status: " + jobOrder.Status}
                            </Grid>

                            {JobLineItemsList &&
                              JobLineItemsList.filter(
                                (li) =>
                                  parseInt(li.Job) === parseInt(jobOrder.Id)
                              ).map((litem, index) => {
                                return (
                                  <>
                                    {index === 0 ? (
                                      <>
                                        <Grid
                                          item
                                          xs={1}
                                          sx={{ border: 1 }}
                                          pl={2}
                                          style={{
                                            backgroundColor: "lightgray",
                                            color: "white",
                                          }}
                                        >
                                          Ser. No.
                                        </Grid>
                                        <Grid
                                          item
                                          xs={2}
                                          sx={{ border: 1 }}
                                          pl={12}
                                          style={{
                                            backgroundColor: "lightgray",
                                            color: "white",
                                          }}
                                        >
                                          Item
                                        </Grid>
                                        <Grid
                                          item
                                          xs={2}
                                          sx={{ border: 1 }}
                                          pl={12}
                                          style={{
                                            backgroundColor: "lightgray",
                                            color: "white",
                                          }}
                                        >
                                          Quantity
                                        </Grid>
                                        <Grid
                                          item
                                          xs={2}
                                          sx={{ border: 1 }}
                                          pl={12}
                                          style={{
                                            backgroundColor: "lightgray",
                                            color: "white",
                                          }}
                                        >
                                          Start Date
                                        </Grid>
                                        <Grid
                                          item
                                          xs={2}
                                          sx={{ border: 1 }}
                                          pl={12}
                                          style={{
                                            backgroundColor: "lightgray",
                                            color: "white",
                                          }}
                                        >
                                          End Date
                                        </Grid>
                                        <Grid
                                          item
                                          xs={3}
                                          sx={{ border: 1 }}
                                          pl={12}
                                          style={{
                                            backgroundColor: "lightgray",
                                            color: "white",
                                          }}
                                        >
                                          Remark
                                        </Grid>
                                        <Grid
                                          item
                                          xs={1}
                                          sx={{ border: 1 }}
                                          pl={2}
                                        >
                                          {index + 1}
                                        </Grid>
                                        <Grid
                                          item
                                          xs={2}
                                          sx={{ border: 1 }}
                                          pl={2}
                                        >
                                          {litem.Name}
                                        </Grid>
                                        <Grid
                                          item
                                          xs={2}
                                          sx={{ border: 1 }}
                                          pl={2}
                                        >
                                          {litem.OrderQuantity}
                                        </Grid>
                                        <Grid
                                          item
                                          xs={2}
                                          sx={{ border: 1 }}
                                          pl={2}
                                        >
                                          {jobOrder.StartDate}
                                        </Grid>
                                        <Grid
                                          item
                                          xs={2}
                                          sx={{ border: 1 }}
                                          pl={2}
                                        >
                                          {jobOrder.EndDate}
                                        </Grid>
                                        <Grid
                                          item
                                          xs={3}
                                          sx={{ border: 1 }}
                                          pl={2}
                                        >
                                          {litem.Remark}
                                        </Grid>
                                      </>
                                    ) : (
                                      <>
                                        <Grid
                                          item
                                          xs={1}
                                          sx={{ border: 1 }}
                                          pl={2}
                                        >
                                          {index + 1}
                                        </Grid>
                                        <Grid
                                          item
                                          xs={2}
                                          sx={{ border: 1 }}
                                          pl={2}
                                        >
                                          {litem.Name}
                                        </Grid>
                                        <Grid
                                          item
                                          xs={2}
                                          sx={{ border: 1 }}
                                          pl={2}
                                        >
                                          {litem.OrderQuantity}
                                        </Grid>
                                        <Grid
                                          item
                                          xs={2}
                                          sx={{ border: 1 }}
                                          pl={2}
                                        >
                                          {jobOrder.StartDate}
                                        </Grid>
                                        <Grid
                                          item
                                          xs={2}
                                          sx={{ border: 1 }}
                                          pl={2}
                                        >
                                          {jobOrder.EndDate}
                                        </Grid>
                                        <Grid
                                          item
                                          xs={3}
                                          sx={{ border: 1 }}
                                          pl={2}
                                        >
                                          {litem.Remark}
                                        </Grid>
                                      </>
                                    )}
                                  </>
                                );
                              })}
                          </>
                        );
                      })}

                    {transferList &&
                      transferList
                        .filter(
                          (transferOrd) =>
                            parseInt(transferOrd.Order) ===
                            parseInt(row.original.OrderId)
                        )
                        .map((transferOrd, index) => {
                          return (
                            <>
                              <Grid
                                item
                                xs={12}
                                style={{
                                  backgroundColor: theme.palette.primary.light,
                                  color: theme.palette.primary.contrastText,
                                  fontWeight: "bold",
                                }}
                              >
                                <IconButton>
                                  <SalesNewOrder
                                    icon={
                                      <WorkOffSharp
                                        style={{
                                          width: "13px",
                                          height: "13px",
                                          margin: 1,
                                        }}
                                      />
                                    }
                                    data={row.original}
                                    BankList={bankList}
                                    DeliveryOrder={[transferOrd]}
                                    ProductsList={LineItemsList.filter(
                                      (li) => li.Order === row.original.OrderId
                                    )}
                                    EmployeesList={employeeList}
                                    DeliveryLines={
                                      DeliveryLineItemsList &&
                                      DeliveryLineItemsList.filter(
                                        (shLine) =>
                                          parseInt(shLine.Order) ===
                                          parseInt(transferOrd.Id)
                                      )
                                    }
                                    ParentUpdate={update}
                                    setParentUpdate={setUpdate}
                                  />
                                </IconButton>
                                {"Transfer Orders: " + (parseInt(index) + 1)}
                              </Grid>

                              <Grid item xs={3} sx={{ border: 1 }} pl={2}>
                                {"Issued By:  " + transferOrd.IssuedByName}
                              </Grid>
                              <Grid item xs={3} sx={{ border: 1 }} pl={2}>
                                {"Issued To:  " + transferOrd.IssuedToName}
                              </Grid>
                              <Grid item xs={3} sx={{ border: 1 }} pl={2}>
                                {/*  {"Assigned To: " + jobOrder.AssignedToName} */}
                              </Grid>
                              <Grid item xs={3} sx={{ border: 1 }} pl={2}>
                                {/* {"Status: " + DeliveryOrd.Status} */}
                              </Grid>

                              {transferItemsList &&
                                transferItemsList
                                  .filter(
                                    (li) =>
                                      parseInt(li.Order) ===
                                      parseInt(transferOrd.Id)
                                  )
                                  .map((litem, index) => {
                                    return (
                                      <>
                                        {index === 0 ? (
                                          <>
                                            <Grid
                                              item
                                              xs={1}
                                              sx={{ border: 1 }}
                                              pl={2}
                                              style={{
                                                backgroundColor:
                                                  theme.palette.primary.dark,
                                                color:
                                                  theme.palette.primary
                                                    .contrastText,
                                              }}
                                            >
                                              Ser. No
                                            </Grid>
                                            <Grid
                                              item
                                              xs={4}
                                              pl={12}
                                              sx={{
                                                backgroundColor:
                                                  theme.palette.primary.dark,
                                                color:
                                                  theme.palette.primary
                                                    .contrastText,
                                              }}
                                            >
                                              Item
                                            </Grid>

                                            <Grid
                                              item
                                              xs={3}
                                              pl={12}
                                              sx={{
                                                backgroundColor:
                                                  theme.palette.primary.dark,
                                                color:
                                                  theme.palette.primary
                                                    .contrastText,
                                              }}
                                            >
                                              Quanity
                                            </Grid>
                                            <Grid
                                              item
                                              xs={4}
                                              pl={12}
                                              sx={{
                                                backgroundColor:
                                                  theme.palette.primary.dark,
                                                color:
                                                  theme.palette.primary
                                                    .contrastText,
                                              }}
                                            >
                                              Remark
                                            </Grid>

                                            <Grid
                                              item
                                              xs={1}
                                              sx={{ border: 1 }}
                                              pl={2}
                                            >
                                              {index + 1}
                                            </Grid>
                                            <Grid
                                              item
                                              xs={4}
                                              sx={{ border: 1 }}
                                              pl={2}
                                            >
                                              {litem.Name}
                                            </Grid>

                                            <Grid
                                              item
                                              xs={3}
                                              sx={{ border: 1 }}
                                              pl={2}
                                            >
                                              {litem.Quantity?.toLocaleString(
                                                undefined,
                                                {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                                }
                                              )}
                                            </Grid>
                                            <Grid
                                              item
                                              xs={4}
                                              sx={{ border: 1 }}
                                              pl={2}
                                            >
                                              {litem.Remark}
                                            </Grid>
                                          </>
                                        ) : (
                                          <>
                                            <Grid
                                              item
                                              xs={1}
                                              sx={{ border: 1 }}
                                              pl={2}
                                            >
                                              {index + 1}
                                            </Grid>
                                            <Grid
                                              item
                                              xs={4}
                                              sx={{ border: 1 }}
                                              pl={2}
                                            >
                                              {litem.Name}
                                            </Grid>

                                            <Grid
                                              item
                                              xs={3}
                                              sx={{ border: 1 }}
                                              pl={2}
                                            >
                                              {litem.Quantity?.toLocaleString(
                                                undefined,
                                                {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                                }
                                              )}
                                            </Grid>
                                            <Grid
                                              item
                                              xs={4}
                                              sx={{ border: 1 }}
                                              pl={2}
                                            >
                                              {litem.Remark}
                                            </Grid>
                                          </>
                                        )}
                                      </>
                                    );
                                  })}
                            </>
                          );
                        })}

                    {DeliveryVouchersList &&
                      DeliveryVouchersList.filter(
                        (shipOrd) =>
                          parseInt(shipOrd.SourceNo) ===
                          parseInt(row.original.OrderId)
                      ).map((DeliveryOrd, index) => {
                        return (
                          <>
                            <Grid
                              item
                              xs={12}
                              style={{
                                backgroundColor: theme.palette.primary.light,
                                color: theme.palette.primary.contrastText,
                                fontWeight: "bold",
                              }}
                            >
                              <IconButton>
                                <SalesNewOrder
                                  icon={
                                    <WorkOffSharp
                                      style={{
                                        width: "13px",
                                        height: "13px",
                                        margin: 1,
                                      }}
                                    />
                                  }
                                  data={row.original}
                                  BankList={bankList}
                                  DeliveryOrder={[DeliveryOrd]}
                                  ProductsList={LineItemsList.filter(
                                    (li) =>
                                      li.Reference === row.original.OrderId
                                  )}
                                  EmployeesList={employeeList}
                                  DeliveryLines={
                                    DeliveryLineItemsList &&
                                    DeliveryLineItemsList.filter(
                                      (shLine) =>
                                        parseInt(shLine.Reference) ===
                                        parseInt(DeliveryOrd.OrderId)
                                    )
                                  }
                                  ParentUpdate={update}
                                  setParentUpdate={setUpdate}
                                />
                              </IconButton>
                              |
                              {"   Delivery Orders: " +
                                (parseInt(index) + 1) +
                                "       Status: " +
                                DeliveryOrd.Status}
                            </Grid>

                            <Grid item xs={3} sx={{ border: 1 }} pl={2}>
                              {"Delivery By:  " + DeliveryOrd.UserName}
                            </Grid>
                            <Grid item xs={3} sx={{ border: 1 }} pl={2}>
                              {/*  {"Approved By:  " + jobOrder.ApprovedByName} */}
                            </Grid>
                            <Grid item xs={3} sx={{ border: 1 }} pl={2}>
                              {/*  {"Assigned To: " + jobOrder.AssignedToName} */}
                            </Grid>
                            <Grid item xs={3} sx={{ border: 1 }} pl={2}>
                              {"Status: " + DeliveryOrd.Status}
                            </Grid>

                            {DeliveryLineItemsList &&
                              DeliveryLineItemsList.filter(
                                (li) =>
                                  parseInt(li.Reference) ===
                                  parseInt(DeliveryOrd.OrderId)
                              ).map((litem, index) => {
                                return (
                                  <>
                                    {index === 0 ? (
                                      <>
                                        <Grid
                                          item
                                          xs={4}
                                          pl={12}
                                          sx={{
                                            backgroundColor:
                                              theme.palette.primary.dark,
                                            color:
                                              theme.palette.primary
                                                .contrastText,
                                          }}
                                        >
                                          Item
                                        </Grid>
                                        <Grid
                                          item
                                          xs={2}
                                          pl={12}
                                          sx={{
                                            backgroundColor:
                                              theme.palette.primary.dark,
                                            color:
                                              theme.palette.primary
                                                .contrastText,
                                          }}
                                        >
                                          Delivery Quantity
                                        </Grid>
                                        <Grid
                                          item
                                          xs={2}
                                          pl={12}
                                          sx={{
                                            backgroundColor:
                                              theme.palette.primary.dark,
                                            color:
                                              theme.palette.primary
                                                .contrastText,
                                          }}
                                        >
                                          Unit Price
                                        </Grid>
                                        <Grid
                                          item
                                          xs={2}
                                          pl={12}
                                          sx={{
                                            backgroundColor:
                                              theme.palette.primary.dark,
                                            color:
                                              theme.palette.primary
                                                .contrastText,
                                          }}
                                        >
                                          Total Price
                                        </Grid>
                                        <Grid
                                          item
                                          xs={2}
                                          pl={12}
                                          sx={{
                                            backgroundColor:
                                              theme.palette.primary.dark,
                                            color:
                                              theme.palette.primary
                                                .contrastText,
                                          }}
                                        >
                                          VAT Amount
                                        </Grid>

                                        <Grid
                                          item
                                          xs={4}
                                          sx={{ border: 1 }}
                                          pl={2}
                                        >
                                          {index + 1 + "-" + litem.Name}
                                        </Grid>
                                        <Grid
                                          item
                                          xs={2}
                                          sx={{ border: 1 }}
                                          pl={2}
                                        >
                                          {litem.Quantity?.toLocaleString(
                                            undefined,
                                            {
                                              minimumFractionDigits: 2,
                                              maximumFractionDigits: 2,
                                            }
                                          )}
                                        </Grid>
                                        <Grid
                                          item
                                          xs={2}
                                          sx={{ border: 1 }}
                                          pl={2}
                                        >
                                          {litem.UnitPrice?.toLocaleString(
                                            undefined,
                                            {
                                              minimumFractionDigits: 2,
                                              maximumFractionDigits: 2,
                                            }
                                          )}
                                        </Grid>
                                        <Grid
                                          item
                                          xs={2}
                                          sx={{ border: 1 }}
                                          pl={2}
                                        >
                                          {litem.TotalPrice?.toLocaleString(
                                            undefined,
                                            {
                                              minimumFractionDigits: 2,
                                              maximumFractionDigits: 2,
                                            }
                                          )}
                                        </Grid>
                                        <Grid
                                          item
                                          xs={2}
                                          sx={{ border: 1 }}
                                          pl={2}
                                        >
                                          {litem.TaxAmount?.toLocaleString(
                                            undefined,
                                            {
                                              minimumFractionDigits: 2,
                                              maximumFractionDigits: 2,
                                            }
                                          )}
                                        </Grid>
                                      </>
                                    ) : (
                                      <>
                                        <Grid
                                          item
                                          xs={4}
                                          sx={{ border: 1 }}
                                          pl={2}
                                        >
                                          {litem.Name}
                                        </Grid>
                                        <Grid
                                          item
                                          xs={2}
                                          sx={{ border: 1 }}
                                          pl={2}
                                        >
                                          {litem.Quantity?.toLocaleString(
                                            undefined,
                                            {
                                              minimumFractionDigits: 2,
                                              maximumFractionDigits: 2,
                                            }
                                          )}
                                        </Grid>
                                        <Grid
                                          item
                                          xs={2}
                                          sx={{ border: 1 }}
                                          pl={2}
                                        >
                                          {litem.UnitPrice?.toLocaleString(
                                            undefined,
                                            {
                                              minimumFractionDigits: 2,
                                              maximumFractionDigits: 2,
                                            }
                                          )}
                                        </Grid>
                                        <Grid
                                          item
                                          xs={2}
                                          sx={{ border: 1 }}
                                          pl={2}
                                        >
                                          {litem.TotalPrice?.toLocaleString(
                                            undefined,
                                            {
                                              minimumFractionDigits: 2,
                                              maximumFractionDigits: 2,
                                            }
                                          )}
                                        </Grid>
                                        <Grid
                                          item
                                          xs={2}
                                          sx={{ border: 1 }}
                                          pl={2}
                                        >
                                          {litem.TaxAmount?.toLocaleString(
                                            undefined,
                                            {
                                              minimumFractionDigits: 2,
                                              maximumFractionDigits: 2,
                                            }
                                          )}
                                        </Grid>
                                      </>
                                    )}
                                  </>
                                );
                              })}
                          </>
                        );
                      })}

                    {PaymentsOrderList &&
                      PaymentsOrderList.filter(
                        (pay) =>
                          parseInt(pay.OrderNo) ===
                          parseInt(row.original.OrderId)
                      ).map((pay, index) => {
                        return (
                          <>
                            <Grid
                              item
                              xs={12}
                              style={{
                                backgroundColor: theme.palette.primary.light,
                                color: theme.palette.primary.contrastText,
                                fontWeight: "bold",
                              }}
                            >
                              <IconButton>
                                <PaymentsModelHistory
                                  icon={
                                    <PaymentsSharp
                                      style={{ width: "13px", height: "13px" }}
                                    />
                                  }
                                  data={pay}
                                  BankList={bankList}
                                  Type={location.state.Type}
                                />
                              </IconButton>
                              |
                              {"   Payment Orders: " +
                                (parseInt(index) + 1) +
                                "       Status: " +
                                pay.Status}
                            </Grid>
                            <Grid item xs={3} sx={{ border: 1 }} pl={2}>
                              {"Paid Date: " + pay.PaidDate}
                            </Grid>
                            <Grid item xs={3} sx={{ border: 1 }} pl={2}>
                              {"Paid By:  " + pay.PaidByName}
                            </Grid>
                            <Grid item xs={3} sx={{ border: 1 }} pl={2}>
                              {"Paid To:  " + pay.PaidToName}
                            </Grid>

                            <Grid item xs={3} sx={{ border: 1 }} pl={2}>
                              {"Amount: " + pay.Amount}
                            </Grid>
                          </>
                        );
                      })}
                  </Grid>
                )}
                muiTopToolbarProps={{
                  sx: {
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.primary.contrastText,
                  },
                }}
                muiBottomToolbarProps={{
                  sx: {
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.primary.contrastText,
                  },
                }}
                renderTopToolbarCustomActions={() => (
                  <Box>
                    <Button
                      size="small"
                      variant="standard"
                      sx={{ borderRadius: "30px" }}
                      endIcon={
                        <AddSharp style={{ width: "20px", height: "20px" }} />
                      }
                    >
                      <SalesNewOrder
                        icon={<div>Add</div>}
                        BankList={bankList}
                        LineItems={[]}
                        Type="Sales"
                        ParentUpdate={update}
                        setParentUpdate={setUpdate}
                      />
                    </Button>
                    |
                    {location.state.Status === "Order" &&
                      globalRoleSecurity.filter(
                        (gs) => gs.Functionality === "Sales Direct"
                      ).length > 0 &&
                      globalRoleSecurity.filter(
                        (gs) => gs.Functionality === "Sales Direct"
                      )[0].Security > 2 && (
                        <>
                          <Button
                            size="small"
                            variant="standard"
                            sx={{ borderRadius: "30px" }}
                            endIcon={
                              <AddSharp
                                style={{ width: "20px", height: "20px" }}
                              />
                            }
                          >
                            <SalesNewOrder
                              icon={<div>Direct Sales</div>}
                              LineItems={[]}
                              Type="Sales"
                              Status="Direct"
                              ParentUpdate={update}
                              setParentUpdate={setUpdate}
                            />
                          </Button>
                          |
                        </>
                      )}
                        {location.state.Status === "Order" &&
                      globalRoleSecurity.filter(
                        (gs) => (gs.Functionality === "Sales Direct" || gs.Functionality === "Direct Delivery")
                      ).length > 0 &&
                      globalRoleSecurity.filter(
                        (gs) => (gs.Functionality === "Sales Direct" || gs.Functionality === "Direct Delivery")
                      )[0].Security > 2 && (
                        <>
                          <Button
                            size="small"
                            variant="standard"
                            sx={{ borderRadius: "30px" }}
                            endIcon={
                              <AddSharp
                                style={{ width: "20px", height: "20px" }}
                              />
                            }
                          >
                            <SalesNewOrder
                              icon={<div>Direct Delivery</div>}
                              LineItems={[]}
                              Type="Sales"
                              Status="Direct"
                              ParentUpdate={update}
                              setParentUpdate={setUpdate}
                            />
                          </Button>
                          |
                        </>
                      )}
                        {location.state.Status === "Order" &&
                      globalRoleSecurity.filter(
                        (gs) => (gs.Functionality === "Sales Direct" || gs.Functionality === "Direct Delivery & Payment")
                      ).length > 0 &&
                      globalRoleSecurity.filter(
                        (gs) => (gs.Functionality === "Sales Direct" || gs.Functionality === "Direct Delivery & Payment")
                      )[0].Security > 2 && (
                        <>
                          <Button
                            size="small"
                            variant="standard"
                            sx={{ borderRadius: "30px" }}
                            endIcon={
                              <AddSharp
                                style={{ width: "20px", height: "20px" }}
                              />
                            }
                          >
                            <SalesNewOrder
                              icon={<div>Direct Delivery & Payment</div>}
                              LineItems={[]}
                              Type="Sales"
                              Status="Direct"
                              ParentUpdate={update}
                              setParentUpdate={setUpdate}
                            />
                          </Button>
                          |
                        </>
                      )}
                    <Button
                      size="small"
                      variant="standard"
                      sx={{ borderRadius: "30px" }}
                      endIcon={
                        <FileDownload
                          style={{ width: "20px", height: "20px" }}
                        />
                      }
                      type="file"
                      onClick={handleDownloadFile}
                    >
                      Download
                    </Button>
                    |
                    <Tooltip title="refresh">
                      <Button
                        size="small"
                        variant="standard"
                        sx={{ borderRadius: "30px" }}
                        endIcon={<RefreshSharp />}
                        onClick={() => setUpdate(!update)}
                      >
                        Refresh
                      </Button>
                    </Tooltip>
                    |
                    <Tooltip title="New Job">
                      <Button
                        size="small"
                        variant="standard"
                        sx={{ borderRadius: "30px" }}
                        endIcon={<WorkOffSharp />}
                      >
                        <JobsModel
                          icon={<div>New Job</div>}
                          Title="EDIT Supplier"
                          EmployeesList={employeeList}
                          Joblines={[]}
                          JobOrders={[]}
                        />
                      </Button>
                    </Tooltip>
                  </Box>
                )}
                enableRowActions
                renderRowActionMenuItems={({ row }) => [
                  <Tooltip title={"Sales Order"}>
                    {row.original.Status === "Draft" ||
                    row.original.Status === "Proforma" ||
                    row.original.Status === "Order" ? (
                      <MenuItem>
                        <SalesNewOrder
                          icon={
                            <div>
                              <EditSharp
                                style={{
                                  width: "13px",
                                  height: "13px",
                                  margin: 1,
                                  marginRight: 4,
                                }}
                              />
                              Sales Order
                            </div>
                          }
                          Type="Sales"
                          BankList={bankList}
                          SourceNo={row.original.OrderId}
                          data={row.original}
                          LineItems={LineItemsList.filter(
                            (li) =>
                              parseInt(li.Reference) ===
                              parseInt(row.original.OrderId)
                          )}
                          ParentUpdate={update}
                          setParentUpdate={setUpdate}
                        />
                      </MenuItem>
                    ) : (
                      <MenuItem>
                        <VouchersModel
                          icon={
                            <div>
                              <EditSharp
                                style={{
                                  width: "13px",
                                  height: "13px",
                                  margin: 1,
                                  marginRight: 4,
                                }}
                              />
                              Sales Order
                            </div>
                          }
                          data={row.original}
                          Type={location.state.Type}
                          LineItems={LineItemsList.filter(
                            (li) => li.Reference === row.original.OrderId
                          )}
                          Title="EDIT Supplier"
                        />
                      </MenuItem>
                    )}
                  </Tooltip>,
                  row.original.Status === "Order" && (
                    <Tooltip title="Transfer Order">
                      <MenuItem>
                        <ItemTransferModel
                          icon={
                            <div>
                              <TransferWithinAStation
                                style={{
                                  width: "13px",
                                  height: "13px",
                                  margin: 1,
                                  marginRight: 4,
                                }}
                              />
                              Transfer Order
                            </div>
                          }
                          EmployeesList={employeeList}
                          StoresList={storesList}
                          TransferOrders={transferList.filter(
                            (to) => to.Order === row.original.OrderId
                          )}
                          ItemJournalLines={transferItemsList.filter(
                            (til) =>
                              parseInt(til.VoucherNo) ===
                              parseInt(row.original.OrderId)
                          )}
                          data={row.original}
                          LineItems={LineItemsList.filter(
                            (li) => li.Reference === row.original.OrderId
                          )}
                          Title="EDIT itemjournals"
                          length
                          ParentUpdate={update}
                          setParentUpdate={setUpdate}
                        />
                      </MenuItem>
                    </Tooltip>
                  ),
                  row.original.Status === "Order" && (
                    <Tooltip title="Job Order">
                      <MenuItem>
                        <JobsModel
                          icon={
                            <div>
                              <WorkOffSharp
                                style={{
                                  width: "13px",
                                  height: "13px",
                                  marginRight: 4,
                                }}
                              />
                              Job Order
                            </div>
                          }
                          JobOrders={[
                            ...JobOrdersList.filter(
                              (jobO) =>
                                parseInt(jobO.Order) ===
                                parseInt(row.original.OrderId)
                            ),
                            {},
                          ]}
                          data={row.original}
                          Jobslines={JobLineItemsList}
                          EmployeesList={employeeList}
                          ProductsList={LineItemsList.filter(
                            (li) => li.Reference === row.original.OrderId
                          )}
                          Title="EDIT Supplier"
                          ParentUpdate={update}
                          setParentUpdate={setUpdate}
                        />
                      </MenuItem>
                    </Tooltip>
                  ),
                  row.original.Status === "Order" && (
                    <Tooltip title="Sales Delivery">
                      <MenuItem>
                        <SalesDeliveryOrder
                          icon={
                            <div>
                              <ShoppingBag
                                style={{
                                  width: "13px",
                                  height: "13px",
                                  margin: 1,
                                  marginRight: 4,
                                }}
                              />
                              Sales Delivery
                            </div>
                          }
                          Type="Sales"
                          SourceNo={row.original.OrderId}
                          data={row.original}
                          LineItems={LineItemsList.filter(
                            (li) => li.Reference === row.original.OrderId
                          )}
                          DeliveryLineItems={DeliveryLineItemsList}
                          Title="EDIT Vouchers"
                          ParentUpdate={update}
                          setParentUpdate={setUpdate}
                        />
                      </MenuItem>
                    </Tooltip>
                  ),

                  (row.original.Status === "Order" ||
                    row.original.Status === "Delivery" ||
                    row.original.Status === "Received") && (
                    <Tooltip title="Payment">
                      <MenuItem>
                        <PaymentModel
                          icon={
                            <div>
                              <PaymentSharp
                                style={{
                                  width: "13px",
                                  height: "13px",
                                  margin: 1,
                                  marginRight: 4,
                                }}
                              />
                              Payment
                            </div>
                          }
                          BankList={bankList}
                          OrderData={row.original}
                          Title="NEW PAYMENT"
                        />
                      </MenuItem>
                    </Tooltip>
                  ),
                  <Tooltip title="Preview">
                    <MenuItem>
                      <InvoicePreview
                        icon={
                          <div>
                            <PreviewSharp
                              style={{
                                width: "13px",
                                height: "13px",
                                margin: 1,
                                marginRight: 4,
                              }}
                            />
                            Preview
                          </div>
                        }
                        Suppliers={[row.original]}
                        LineItems={LineItemsList.filter(
                          (li) =>
                            parseInt(li.Reference) ===
                            parseInt(row.original.OrderId)
                        )}
                        DocumentType={"Sales " + row.original.Status}
                        Type="Invoice"
                      />
                    </MenuItem>
                  </Tooltip>,
                ]}
              ></MaterialReactTable>
            </Grid>
            <Grid item xs={12} mt={1} className="hideInFull">
              <Box
                sx={{
                  width: "100%",
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.primary.contrastText,
                }}
              >
                <Button
                  variant="standard"
                  size="small"
                  sx={{ borderRadius: "30px" }}
                  endIcon={<AddSharp />}
                >
                  <SalesNewOrder
                    icon={<div>Add</div>}
                    BankList={bankList}
                    Type="Sales"
                    SourceNo="0"
                    data=""
                    LineItems={[]}
                    Title="Add Vouchers"
                    ParentUpdate={update}
                    setParentUpdate={setUpdate}
                  />
                </Button>
                |
                {/*    <Button       size="small"
                    variant="standard" sx={{borderRadius:'30px'}} endIcon={<FileUpload style={{ width: "20px", height: "20px" }} />}>
                    <label htmlFor="file">
                      Upload
                    </label>
                    <input
                      id="file"
                      name="file"
                      hidden
                      type="file"
                      onChange={handleUploadFile}
                    />
                  </Button>| */}
                <Button
                  size="small"
                  variant="standard"
                  sx={{ borderRadius: "30px" }}
                  endIcon={
                    <FileDownload style={{ width: "20px", height: "20px" }} />
                  }
                  type="file"
                  onClick={handleDownloadFile}
                >
                  Download
                </Button>
                |
                <Tooltip title="refresh">
                  <Button
                    size="small"
                    variant="standard"
                    sx={{ borderRadius: "30px" }}
                    endIcon={<RefreshSharp />}
                    onClick={() => setUpdate(!update)}
                  >
                    Refresh
                  </Button>
                </Tooltip>
              </Box>
            </Grid>
            {!(VouchersList === undefined) &&
              VouchersList.length > 0 &&
              VouchersList.map((item) => {
                return (
                  <Grid
                    item
                    key={item.Id}
                    xs={5.5}
                    m={1}
                    className="hideInFull"
                  >
                    <Card
                      sx={{ borderRadius: 10, boxShadow: 3, width: "100%" }}
                    >
                      <CardHeader
                        avatar={
                          <Avatar
                            src={
                              item.Picture != null && item.Picture.length > 0
                                ? item.Picture.split(",")[0]
                                : "/Images/Products/default.png"
                            }
                          />
                        }
                        className="CardHeader"
                        title={"Customer: " + item.Name} //.substring(0, 20)}
                        subheader={"Total Amount: " + item.TotalAmount}
                      />

                      <CardContent>
                        <Typography variant="body2" align="left">
                          Order Id: {item.OrderId}
                        </Typography>
                        <Typography variant="body2" align="left">
                          Date: {item.VoucherDate}
                        </Typography>
                        <Typography variant="body2" align="left">
                          Amount: {item.Amount}
                        </Typography>
                        <Typography variant="body2" align="left">
                          VAT Amount: {item.VATAmount}
                        </Typography>
                        <Typography variant="body2" align="left">
                          Total Amount: {item.TotalAmount}
                        </Typography>
                      </CardContent>
                      <hr></hr>
                      <CardActions
                        sx={{
                          backgroundColor: theme.palette.primary.main,
                          color: theme.palette.primary.contrastText,
                        }}
                      >
                        <Box>
                          <Tooltip title={"Sales Order"}>
                            {item.Status === "Draft" ||
                            item.Status === "Proforma" ||
                            item.Status === "Order" ? (
                              <IconButton>
                                <SalesNewOrder
                                  icon={
                                    <div>
                                      <EditSharp
                                        style={{
                                          width: "13px",
                                          height: "13px",
                                          margin: 1,
                                          marginRight: 2,
                                        }}
                                      />
                                      |
                                    </div>
                                  }
                                  Type="Sales"
                                  BankList={bankList}
                                  SourceNo={item.OrderId}
                                  data={item}
                                  LineItems={LineItemsList.filter(
                                    (li) =>
                                      parseInt(li.Reference) ===
                                      parseInt(item.OrderId)
                                  )}
                                  Title="EDIT Vouchers"
                                  ParentUpdate={update}
                                  setParentUpdate={setUpdate}
                                />
                              </IconButton>
                            ) : (
                              <IconButton>
                                <VouchersModel
                                  icon={
                                    <div>
                                      <EditSharp
                                        style={{
                                          width: "13px",
                                          height: "13px",
                                          margin: 1,
                                          marginRight: 2,
                                        }}
                                      />
                                      |
                                    </div>
                                  }
                                  data={item}
                                  Type={location.state.Type}
                                  LineItems={LineItemsList.filter(
                                    (li) => li.Reference === item.OrderId
                                  )}
                                  Title="EDIT Supplier"
                                />
                              </IconButton>
                            )}
                          </Tooltip>
                          {item.Status === "Order" && (
                            <>
                              <Tooltip title="Transfer Order">
                                <IconButton>
                                  <ItemTransferModel
                                    icon={
                                      <TransferWithinAStation
                                        style={{
                                          width: "13px",
                                          height: "13px",
                                          margin: 1,
                                          marginRight: 2,
                                        }}
                                      />
                                    }
                                    EmployeesList={employeeList}
                                    StoresList={storesList}
                                    TransferOrders={transferList.filter(
                                      (to) => to.Order === item.OrderId
                                    )}
                                    ItemJournalLines={transferItemsList.filter(
                                      (til) =>
                                        parseInt(til.VoucherNo) ===
                                        parseInt(item.OrderId)
                                    )}
                                    data={item}
                                    LineItems={LineItemsList.filter(
                                      (li) => li.Reference === item.OrderId
                                    )}
                                    Title="EDIT itemjournals"
                                    length
                                    ParentUpdate={update}
                                    setParentUpdate={setUpdate}
                                  />
                                </IconButton>
                              </Tooltip>
                              |{" "}
                            </>
                          )}
                          {item.Status === "Order" && (
                            <>
                              <Tooltip title="Job Order">
                                <IconButton>
                                  <JobsModel
                                    icon={
                                      <WorkOffSharp
                                        style={{
                                          width: "13px",
                                          height: "13px",
                                          marginRight: 2,
                                        }}
                                      />
                                    }
                                    JobOrders={[
                                      ...JobOrdersList.filter(
                                        (jobO) =>
                                          parseInt(jobO.Order) ===
                                          parseInt(item.OrderId)
                                      ),
                                      {},
                                    ]}
                                    data={item}
                                    Jobslines={JobLineItemsList}
                                    EmployeesList={employeeList}
                                    ProductsList={LineItemsList.filter(
                                      (li) => li.Reference === item.OrderId
                                    )}
                                    Title="EDIT Supplier"
                                    ParentUpdate={update}
                                    setParentUpdate={setUpdate}
                                  />
                                </IconButton>
                              </Tooltip>
                              |
                            </>
                          )}
                          {item.Status === "Order" && (
                            <>
                              <Tooltip title="Sales Delivery">
                                <IconButton>
                                  <SalesDeliveryOrder
                                    icon={
                                      <ShoppingBag
                                        style={{
                                          width: "13px",
                                          height: "13px",
                                          margin: 1,
                                          marginRight: 2,
                                        }}
                                      />
                                    }
                                    Type="Sales"
                                    SourceNo={item.OrderId}
                                    data={item}
                                    LineItems={LineItemsList.filter(
                                      (li) => li.Reference === item.OrderId
                                    )}
                                    DeliveryLineItems={DeliveryLineItemsList}
                                    Title="EDIT Vouchers"
                                    ParentUpdate={update}
                                    setParentUpdate={setUpdate}
                                  />
                                </IconButton>
                              </Tooltip>
                              |
                            </>
                          )}

                          {(item.Status === "Order" ||
                            item.Status === "Delivery" ||
                            item.Status === "Received") && (
                            <>
                              <Tooltip title="Payment">
                                <IconButton>
                                  <PaymentModel
                                    icon={
                                      <PaymentSharp
                                        style={{
                                          width: "13px",
                                          height: "13px",
                                          margin: 1,
                                          marginRight: 2,
                                        }}
                                      />
                                    }
                                    BankList={bankList}
                                    OrderData={item}
                                    Title="NEW PAYMENT"
                                  />
                                </IconButton>
                              </Tooltip>
                              |
                            </>
                          )}
                          <Tooltip title="Preview">
                            <IconButton>
                              <InvoicePreview
                                icon={
                                  <PreviewSharp
                                    style={{
                                      width: "13px",
                                      height: "13px",
                                      margin: 1,
                                      marginRight: 2,
                                    }}
                                  />
                                }
                                Suppliers={[item]}
                                LineItems={LineItemsList.filter(
                                  (li) =>
                                    parseInt(li.Reference) ===
                                    parseInt(item.OrderId)
                                )}
                                DocumentType={"Sales " + item.Status}
                                Type="Invoice"
                              />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </CardActions>
                    </Card>
                  </Grid>
                );
              })}
          </Grid>
        ) : (
          <div>There is no employee in this company</div>
        )
      ) : (
        <div>There is no bank in this company</div>
      )}
    </div>
  );
}

export default SalesVouchersComp;
