import { useState, useEffect, useContext } from 'react'
import {
  Autocomplete,
  Button,
  Grid,
  IconButton,
  Table,
  TextField,
  Tooltip,
  Card,
  CardContent,
} from '@mui/material'
import {
  Close,
  Delete,
  Add,
  ContentPasteOffSharp,
  Troubleshoot,
  SignalCellularNullTwoTone,
} from '@mui/icons-material'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContentText from '@mui/material/DialogContentText'
import InvoicePreview from '../Reports/InvoicePreview'
import PaymentsModel from '../PaymentsModel'
import { ShoppingCartContext } from '../Context/ShoppingCartContext'

import { format } from 'date-fns'
import axios from '../../axiosInstances'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@mui/material/styles'

export default function SalesDeliveryOrder(props) {
  const theme = useTheme()
  var fileObj = []
  var fileArray = []
  const [open, setOpen] = useState(false)
  const { t, i18n } = useTranslation()
  const [orderDate, setOrderDate] = useState(
    props.data ? props.data.VoucherDate : format(new Date(), 'yyyy-MM-dd'),
  )
  const {
    addtoCart,
    setSelectedItems,
    removeFromCart,
    setglobalCustomer,
    globalUser,
    selectedItems,
    allItems,
    globalCustomers,
    globalToken,
  } = useContext(ShoppingCartContext)

  const [deliveryLineItems, setDeliveryLineItems] = useState(
    props.DeliveryLineItems,
  )
  const [lineItems, setLineItems] = useState(
    props.LineItems?.map((litem) => {
      let shippedQty = 0
      deliveryLineItems &&
        deliveryLineItems.length > 0 &&
        deliveryLineItems
          .filter((dl) => parseInt(dl.Item) === parseInt(litem.Item))
          .map((itt) => {
            shippedQty = parseFloat(shippedQty) + parseFloat(itt.Quantity)
          })
       
      return {
        ...litem,
        deliveredQuantity: shippedQty,
        deliveringQuantity: (parseFloat(litem.Quantity) - parseFloat(shippedQty)),
      }
    }),
  )

  const [customer, setCustomer] = useState(
    props.data ? props.data.Customer : '',
  )

  const [selectedVoucher, setSelectedVoucher] = useState(
    !(globalCustomers === undefined) &&
      !(globalCustomers === null) &&
      globalCustomers.length > 0 &&
      globalCustomers[0].Id,
  )
  const [customers, setCustomers] = useState([])
  const [Photofiles, setPhotofiles] = useState(splitPictures())

  const [storeList, setStoreList] = useState([])
  const [store, setStore] = useState(0)
  function splitPictures() {
    try {
      fileArray = []
      props.data.Attachment.split(',').map(function (address, index) {
        if (!fileArray.includes(address) && address.length > 0) {
          fileArray.push(address)
          //document.getElementById('file').files.push(new Blob(address))
        }
      })
      return fileArray
    } catch (err) {
      console.log(err)
    }
  }
  function uploadMultipleFiles(e) {
    fileObj.push(e.target.files)
    for (let i = 0; i < fileObj[0].length; i++) {
      fileArray.push(URL.createObjectURL(fileObj[0][i]))
    }
    setPhotofiles(fileArray)
  }
  /*   const loadLineItems = (reference, cust) => {
    axios
      .get(
        "/lineItems/ByVoucherId?Reference=" + reference + "&Customer=" + cust
      )
      .then((response) => {
        if (response.data.data) {
          setLineItems(response.data.data);
        } else {
          // alert("log out and log in again voucher by voucher id");
        }
      });
  }; */

  const handleSubmit = (id, status, vDate, lItems) => {
    if (
      lItems === undefined ||
      lItems.filter((li) => li.Name.length > 0).length <= 0
    ) {
      alert('First select the items: ')
      return
    }

    let testResult = false
    lItems.map((li) => {
      if (
        isNaN(li.Quantity.toString().replace(',', '')) ||
        li.Quantity.toString().replace(',', '') <= 0
      ) {
        alert('First insert the proper quantity of product: ' + li.Name)
        testResult = true
        return
      }

      if (
        isNaN(li.UnitPrice.toString().replace(',', '')) ||
        li.UnitPrice.toString().replace(',', '') <= 0
      ) {
        alert('First insert the proper unit price of product: ' + li.Name)
        testResult = true
        return
      }
    })

    if (testResult) {
      return
    }
    if (window.confirm('Are you sure you want to save?')) {
      try {
        var ttAmount = 0

        customers &&
          customers.map((cust) => {
            /*   lItems
          .filter(
            (it) =>
              it.Name.length > 0 && parseInt(it.Supplier) === parseInt(cust.Id)
          )
          .map((li) => {
            ttAmount = ttAmount + li.TotalPrice;
             ttAmount = ttAmount + li.TotalPrice;
          }); */

            var fd = new FormData()
            fd.append('SourceNo', id)
            fd.append('Supplier', globalUser.Parent)
            fd.append('VoucherDate', vDate)
            fd.append('Amount', cust.Amount.toLocaleString().replace(',', ''))
            fd.append(
              'VATAmount',
              cust.VATAmount.toLocaleString().replace(',', ''),
            )
            fd.append(
              'TotalAmount',
              cust.TotalAmount.toLocaleString().replace(',', ''),
            )
            fd.append('Customer', cust.Id)
            fd.append('Status', status)
            fd.append('BillTo', globalUser.Id)
            fd.append('Store', store)
            fd.append('User', globalUser.Id)
            alert('yry')
            Array.from(document.getElementById('Attachment').files).map(
              (file) => {
                fd.append('Attachment', file)
              },
            )

            fd.append(
              'LineItems',
              JSON.stringify(
                lItems
                  .filter(
                    (it) =>
                      it.Name.length > 0 &&
                      parseInt(it.Supplier) === parseInt(cust.Id),
                  )
                  .map((lItem) => {
                    lItem.Id = 0
                    lItem.Quantity = lItem.deliveringQuantity
                    return lItem
                  }),
              ),
            )
            fd.append('Type', 'Normal')

            axios
              .post('/vouchers', fd, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                  token: globalToken,
                },
              })
              .then((res) => {
                if (!(props.data && !(props.data === null))) {
                  setglobalCustomer([])
                  setSelectedItems([])
                }
              })
          })
      } catch (err) {
      } finally {
        setSelectedItems([])
        setLineItems([])
        //setCustomer('')
        //props.setParentUpdate(!props.ParentUpdate);
        setOpen(false)
      }
    }
  }
  const handleDelete = (id) => {
    try {
      axios.delete('/vouchers?Id=' + id, {
        headers: {
          token: globalToken,
        },
      }) //add id
    } catch (err) {
    } finally {
      props.setParentUpdate(!props.ParentUpdate)
      setOpen(false)
    }
  }
  const handleLineItemDelete = (id) => {
    try {
      axios.delete('/lineItems?Id=' + id, {
        headers: {
          token: globalToken,
        },
      }) //add id
    } catch (err) {
    } finally {
    }
  }
  const getStoresBySupplier = (supplier) => {
    if (supplier === undefined || supplier === null || supplier === '') {
      setStoreList([])
      return
    }
    axios
      .get('/stores/getBySupplier?Supplier=' + supplier, {
        headers: {
          token: globalToken,
        },
      })
      .then((response) => {
        if (response.data.data) {
          setStoreList(response.data.data)
        } else {
          // alert("log out and log in again voucher by voucher id");
        }
      })
  }

  useEffect(() => {
    try {
      if (
        customers === undefined ||
        customers === null ||
        customers.length <= 0
      ) {
        setCustomers(
          props.data
            ? [
                {
                  Id: props.data.Supplier,
                  Name: props.data.SupplierName,
                  TotalAmount: 0,
                },
              ]
            : globalCustomers && globalCustomers.length > 0
            ? [
                {
                  Id: globalCustomers[0].Id,
                  Name: globalCustomers[0].Name,
                  TotalAmount: 0,
                },
              ]
            : [],
        )
        setSelectedVoucher(
          props.data
            ? props.data.Supplier
            : globalCustomers && globalCustomers.length > 0
            ? globalCustomers[0].Id
            : 0,
        )
      }
    } catch {}
  }, [selectedItems])
  return (
    <div>
      <label
        onClick={() => {
          setOpen(true)
        }}
      >
        {props.icon}
      </label>

      <Dialog
        open={open}
        onClose={() => {
          setOpen(false)
        }}
      >
        <DialogContent>
          <DialogContentText>
            {!(customers === undefined) &&
              !(customers === null) &&
              customers.map((cust, index) => {
                if (
                  cust &&
                  !(cust === null) &&
                  cust.Name &&
                  cust.Name.length > 0
                ) {
                  cust.TotalAmount = 0
                  cust.TotalVATAmount = 0
                  return (
                    <>
                      <DialogTitle
                        style={{
                          backgroundColor: theme.palette.primary.main,
                          color: theme.palette.primary.contrastText,
                          height: '20px',
                        }}
                        onClick={() =>
                          cust.Id === selectedVoucher
                            ? setSelectedVoucher(
                                customers[
                                  index + 1 >= customers.length ? 0 : index + 1
                                ].Id,
                              )
                            : setSelectedVoucher(cust.Id)
                        }
                      >
                        Sales Delivery Order: {index + 1 + '              '}{' '}
                        Customer: {cust.Name}
                      </DialogTitle>
                      <Grid container mt={1} key={cust.Id}>
                        <Grid
                          item
                          xs={6}
                          style={{
                            display:
                              selectedVoucher === cust.Id ? 'block' : 'none',
                          }}
                        >
                          <Autocomplete
                            label="Customer"
                            id="Customer"
                            size="small"
                            variant="standard"
                            value={cust.Name}
                            onSelect={(e) => {
                              if (
                                !(e.target.value === '') &&
                                !(e.target.value === null) &&
                                e.target.value.length > 0 &&
                                customers.filter(
                                  (sf) => sf.Name === e.target.value,
                                ).length <= 0
                              ) {
                                if (
                                  lineItems.filter(
                                    (li) =>
                                      parseInt(li.Supplier) ===
                                      parseInt(cust.id),
                                  ).length <= 0
                                ) {
                                  setCustomers(
                                    customers.filter(
                                      (cst) => cst.Name !== cust.Name,
                                    ),
                                  )
                                }

                                setCustomers((sp) => [
                                  ...sp,
                                  globalCustomers.filter(
                                    (ss) => ss.Name === e.target.value,
                                  )[0],
                                ])
                                setSelectedVoucher(
                                  globalCustomers.filter(
                                    (ss) => ss.Name === e.target.value,
                                  ).length > 0
                                    ? globalCustomers.filter(
                                        (ss) => ss.Name === e.target.value,
                                      )[0].Id
                                    : selectedVoucher,
                                )
                              }
                            }}
                            options={
                              !(globalCustomers === undefined) &&
                              !(globalCustomers === null) &&
                              globalCustomers.length > 0 &&
                              globalCustomers.map((pro) => {
                                return pro.Name
                              })
                            }
                            sx={{ width: '95%' }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={t('Customer')}

                                //error={!from ? true : false}
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          style={{
                            display:
                              selectedVoucher === cust.Id ? 'block' : 'none',
                          }}
                        >
                          <span style={{}}>
                            Doc. No.: {props.data ? props.data.OrderId : ''}
                          </span>
                        </Grid>

                        <Grid
                          item
                          xs={6}
                          mt={1}
                          style={{
                            display:
                              selectedVoucher === cust.Id ? 'block' : 'none',
                          }}
                        >
                          <TextField
                            disabled={true}
                            size="small"
                            label="Order Date"
                            type="date"
                            value={
                              props.data ? props.data.VoucherDate : orderDate
                            }
                            onChange={(e) => {
                              setOrderDate(e.target.value)
                            }}
                            sx={{ width: '70%', marginBottom: 1 }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          style={{
                            display:
                              selectedVoucher === cust.Id ? 'block' : 'none',
                          }}
                        >
                          <span style={{}}>
                            Status: {props.data ? props.data.Status : 'Draft'}
                            <br></br>
                            User:{' '}
                            {props.data ? props.data.UserName : globalUser.Name}
                          </span>
                        </Grid>

                        <Grid item xs={12}>
                          <Grid
                            direction="row"
                            container
                            style={{
                              display:
                                selectedVoucher === cust.Id ? 'block' : 'none',
                            }}
                          >
                            <hr></hr>
                            <Grid item xs={12} className="hideInMobile">
                              <Grid container>
                                <Grid
                                  item
                                  xs={1}
                                  style={{
                                    paddingLeft: '5px',
                                    backgroundColor: theme.palette.primary.main,
                                    color: theme.palette.primary.contrastText,
                                  }}
                                >
                                  No.
                                </Grid>
                                <Grid
                                  item
                                  xs={4}
                                  style={{
                                    paddingLeft: '5px',
                                    backgroundColor: theme.palette.primary.main,
                                    color: theme.palette.primary.contrastText,
                                  }}
                                >
                                  {' '}
                                  Item Name
                                </Grid>

                                <Grid
                                  item
                                  xs={2}
                                  style={{
                                    paddingLeft: '5px',
                                    backgroundColor: theme.palette.primary.main,
                                    color: theme.palette.primary.contrastText,
                                  }}
                                >
                                  Shipped Quantity
                                </Grid>
                                <Grid
                                  item
                                  xs={2}
                                  style={{
                                    paddingLeft: '5px',
                                    backgroundColor: theme.palette.primary.main,
                                    color: theme.palette.primary.contrastText,
                                  }}
                                >
                                  Quantity
                                </Grid>
                                <Grid
                                  item
                                  xs={2}
                                  style={{
                                    paddingLeft: '5px',
                                    backgroundColor: theme.palette.primary.main,
                                    color: theme.palette.primary.contrastText,
                                  }}
                                >
                                  Remark
                                </Grid>
                                <Grid
                                  item
                                  xs={1}
                                  style={{
                                    paddingLeft: '5px',
                                    backgroundColor: theme.palette.primary.main,
                                    color: theme.palette.primary.contrastText,
                                  }}
                                >
                                  <IconButton
                                    size="small"
                                    id={cust.Id}
                                    onClick={(e) => {
                                      if (
                                        lineItems &&
                                        lineItems.filter(
                                          (itt) =>
                                            itt.Item === '' &&
                                            parseInt(itt.Supplier) ===
                                              parseInt(cust.Id),
                                        ).length <= 0
                                      ) {
                                        let newLineItem = {}
                                        newLineItem.Item = ''
                                        newLineItem.Name = ''
                                        newLineItem.Type = 'Sales'
                                        newLineItem.Supplier = cust.Id
                                        newLineItem.UnitCost = 0
                                        newLineItem.Quantity = 0
                                        newLineItem.SupplierName = cust.Name

                                        setSelectedItems((xx) => [
                                          ...xx,
                                          newLineItem,
                                        ])
                                      } else {
                                        alert('First complete the data')
                                      }
                                    }}
                                  >
                                    <Add
                                      sx={{
                                        color:
                                          theme.palette.primary.contrastText,
                                      }}
                                    />
                                  </IconButton>
                                </Grid>

                                {lineItems &&
                                  lineItems.length > 0 &&
                                  lineItems
                                    .filter(
                                      (it) =>
                                        parseInt(it.Supplier) ===
                                        parseInt(cust.Id),
                                    )
                                    .map((item, index) => {
                                      item.TotalPrice =
                                        item.UnitPrice * item.Quantity
                                      item.TaxAmount = item.VATInclusive
                                        ? item.UnitPrice * item.Quantity * 0.15
                                        : 0
                                      //cust.TotalAmount=cust.TotalAmount+totalAmount;
                                      cust.TotalAmount =
                                        cust.TotalAmount +
                                        item.TotalPrice +
                                        item.TaxAmount
                                      cust.TotalVATAmount =
                                        cust.TotalVATAmount + item.TaxAmount
                                      return (
                                        <>
                                          <Grid item xs={12}>
                                            <hr></hr>
                                          </Grid>
                                          <Grid item xs={1} key={index}>
                                            {index + 1}
                                          </Grid>
                                          <Grid item xs={4}>
                                            <Autocomplete
                                              label="Items"
                                              id="Items"
                                              size="small"
                                              variant="standard"
                                              value={item.Name}
                                              onSelect={(e) => {
                                                if (
                                                  lineItems &&
                                                  lineItems.length > 0 &&
                                                  lineItems === null &&
                                                  lineItems.filter(
                                                    (li) =>
                                                      li.Name ===
                                                        e.target.value &&
                                                      parseInt(li.Supplier) ===
                                                        parseInt(cust.Id),
                                                  ).length > 0
                                                ) {
                                                  item.Item = ''
                                                  item.Name = ''
                                                  item.Supplier = cust.Id
                                                  item.Quantity = 0
                                                  item.UnitPrice = 0
                                                  item.UnitCost = 0
                                                  item.TotalPrice = 0
                                                  item.TaxAmount = 0
                                                  item.Supplier = 0
                                                  setSelectedItems(
                                                    selectedItems.map(
                                                      (litem) => {
                                                        return litem
                                                      },
                                                    ),
                                                  )
                                                  //alert('The selected item is already registered')
                                                  return
                                                } else {
                                                  let selectedProduct = allItems.filter(
                                                    (selItem) =>
                                                      selItem.Name ===
                                                      e.target.value,
                                                  )

                                                  if (
                                                    selectedProduct &&
                                                    selectedProduct.length > 0
                                                  ) {
                                                    item.Type = 'Sales'
                                                    item.Quantity =
                                                      selectedProduct[0]
                                                        .DefaultOrderQty !== 0
                                                        ? selectedProduct[0]
                                                            .DefaultOrderQty
                                                        : 1
                                                    item.UnitPrice = selectedProduct[0]
                                                      .Price
                                                      ? selectedProduct[0].Price
                                                      : 0
                                                    item.TaxAmount = item.VATInclusive
                                                      ? item.UnitPrice * 0.15
                                                      : 0

                                                    item.UOM = selectedProduct[0]
                                                      .UOM
                                                      ? selectedProduct[0].UOM
                                                      : 'PCS'

                                                    item.Item =
                                                      selectedProduct[0].Item
                                                    item.Name =
                                                      selectedProduct[0].Name
                                                    item.TotalPrice =
                                                      item.UnitPrice *
                                                      item.Quantity
                                                    item.TaxAmount = selectedProduct[0]
                                                      .VATInclusive
                                                      ? item.UnitPrice *
                                                        item.Quantity *
                                                        0.15
                                                      : 0
                                                    item.Image =
                                                      selectedProduct[0].Picture
                                                    item.Supplier = cust.Id
                                                    addtoCart(item)
                                                    cust.VATAmount = 0
                                                    cust.Amount = 0
                                                    lineItems &&
                                                      lineItems
                                                        .filter(
                                                          (li) =>
                                                            parseInt(
                                                              li.Supplier,
                                                            ) ===
                                                            parseInt(cust.Id),
                                                        )
                                                        .map((it) => {
                                                          cust.VATAmount =
                                                            cust.VATAmount +
                                                            it.TaxAmount
                                                          cust.Amount =
                                                            cust.Amount +
                                                            it.TotalPrice
                                                        })
                                                    setSelectedItems(
                                                      selectedItems.map(
                                                        (litem) => {
                                                          return litem
                                                        },
                                                      ),
                                                    )
                                                  } else {
                                                    item.Quantity = 0
                                                    item.UnitPrice = 0
                                                    item.UnitCost = 0
                                                    item.TotalPrice = 0
                                                    item.TaxAmount = 0
                                                    item.Supplier = 0
                                                    item.Item = ''
                                                    item.Name = ''
                                                    item.Supplier = cust.Id

                                                    setSelectedItems(
                                                      selectedItems.map(
                                                        (litem) => {
                                                          return litem
                                                        },
                                                      ),
                                                    )
                                                  }
                                                }
                                              }}
                                              options={
                                                !(allItems === undefined) &&
                                                !(allItems === null) &&
                                                allItems.length > 0
                                                  ? allItems.map((it) => {
                                                      return it.Name
                                                    })
                                                  : ['']
                                              }
                                              sx={{ width: '95%', height: 5 }}
                                              renderInput={(params) => (
                                                <TextField
                                                  {...params}
                                                  label={item.Name}
                                                  sx={{ height: 5 }}
                                                  //error={!from ? true : false}
                                                />
                                              )}
                                            />
                                          </Grid>

                                          <Grid item xs={2}>
                                            {item.deliveredQuantity?.toLocaleString(
                                              undefined,
                                              {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                              },
                                            )}
                                          </Grid>

                                          <Grid item xs={2}>
                                            <TextField
                                              size="small"
                                              variant="standard"
                                              type="number"
                                              value={item.deliveringQuantity?.toLocaleString(
                                                undefined,
                                                {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                                },
                                              )}
                                              maxRows={4}
                                              sx={{ width: '70px' }}
                                              onChange={(e) => {
                                                item.deliveringQuantity =
                                                  e.target.value
                                                if (
                                                  parseFloat(e.target.value) >
                                                  parseFloat(item.Quantity) -
                                                    parseFloat(
                                                      item.deliveredQuantity,
                                                    )
                                                ) {
                                                  item.deliveringQuantity =
                                                    parseFloat(item.Quantity) -
                                                    parseFloat(
                                                      item.deliveredQuantity,
                                                    )
                                                } else {
                                                  item.deliveringQuantity =
                                                    e.target.value
                                                }

                                                item.TotalPrice =
                                                  parseFloat(item.UnitPrice) *
                                                  parseFloat(
                                                    item.deliveringQuantity,
                                                  )
                                                item.TaxAmount = item.VATInclusive
                                                  ? parseFloat(item.UnitPrice) *
                                                    parseFloat(
                                                      item.deliveringQuantity,
                                                    ) *
                                                    0.15
                                                  : 0

                                                cust.VATAmount = 0
                                                cust.Amount = 0
                                                lineItems &&
                                                  lineItems
                                                    .filter(
                                                      (li) =>
                                                        parseInt(
                                                          li.Supplier,
                                                        ) === parseInt(cust.Id),
                                                    )
                                                    .map((it) => {
                                                      cust.VATAmount =
                                                        cust.VATAmount +
                                                        it.TaxAmount
                                                      cust.Amount =
                                                        cust.Amount +
                                                        it.TotalPrice
                                                    })
                                                setSelectedItems(
                                                  selectedItems.map((litem) => {
                                                    return litem
                                                  }),
                                                )
                                              }}
                                              InputLabelProps={{
                                                shrink: true,
                                              }}
                                            />
                                          </Grid>
                                          <Grid item xs={2}>
                                            <TextField
                                              size="small"
                                              variant="standard"
                                              type="float"
                                              value={item.Remark}
                                              maxRows={4}
                                              sx={{ width: '70px' }}
                                              onChange={(e) => {
                                                item.Remark = e.target.value
                                                setSelectedItems(
                                                  selectedItems.map((litem) => {
                                                    return litem
                                                  }),
                                                )
                                              }}
                                              InputLabelProps={{
                                                shrink: true,
                                              }}
                                            />
                                          </Grid>
                                          <Grid item xs={1}>
                                            <Tooltip title="remove">
                                              <Button
                                                endIcon={
                                                  <Delete width="10px" />
                                                }
                                                onClick={() => {
                                                  if (
                                                    lineItems.filter(
                                                      (litem) =>
                                                        litem.Item ===
                                                          item.Item &&
                                                        parseInt(
                                                          litem.Supplier,
                                                        ) ===
                                                          parseInt(
                                                            item.Supplier,
                                                          ),
                                                    ).Id
                                                  ) {
                                                    handleLineItemDelete(
                                                      lineItems.filter(
                                                        (litem) =>
                                                          litem.Item ===
                                                            item.Item &&
                                                          parseInt(
                                                            litem.Supplier,
                                                          ) ===
                                                            parseInt(
                                                              item.Supplier,
                                                            ),
                                                      )[0].Id,
                                                    )
                                                  }
                                                  removeFromCart(item)

                                                  /*    setLineItems(
                                    lineItems.filter(
                                      (litem) => !(litem.Item === item.Item && parseInt(litem.Supplier)===parseInt(item.Supplier))
                                    )
                                  ); */
                                                  if (
                                                    lineItems.filter(
                                                      (litem) =>
                                                        parseInt(
                                                          litem.Supplier,
                                                        ) === parseInt(cust.Id),
                                                    ).length <= 0
                                                  ) {
                                                    setCustomers(
                                                      customers.filter(
                                                        (ssp) =>
                                                          parseInt(ssp.Id) !==
                                                          parseInt(cust.Id),
                                                      ),
                                                    )
                                                    /* setOpen(false);
                                                   let newLineItem = {};
                                    newLineItem.Item = "";
                                    newLineItem.Name = "";
                                    newLineItem.Supplier = cust.Id;
                                    newLineItem.UnitCost = 0;
                                    newLineItem.Quantity = 0;
                                    newLineItem.UnitPrice = 0;
                                    newLineItem.TotalPrice = 0;
                                    setLineItems((xx) => [...xx, newLineItem]); */
                                                  }
                                                }}
                                              ></Button>
                                            </Tooltip>
                                          </Grid>
                                        </>
                                      )
                                    })}
                                <Grid item xs={12}>
                                  <hr></hr>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item className="hideInFull" m={1} xs={12}>
                              {lineItems &&
                                lineItems.length > 0 &&
                                lineItems
                                  .filter(
                                    (it) =>
                                      parseInt(it.Supplier) ===
                                      parseInt(cust.Id),
                                  )
                                  .map((item, index) => {
                                    /*  totalAmount =
                        totalAmount + (item.UnitPrice) * item.Quantity;
                      totalVat =
                        totalVat + (item.UnitPrice) * item.Quantity * 0.15; */
                                    return (
                                      <>
                                        <Card
                                          sx={{
                                            borderRadius: 10,
                                            boxShadow: 3,
                                            width: '100%',
                                          }}
                                        >
                                          <CardContent>
                                            <Grid container>
                                              <Grid item xs={4}>
                                                No.:
                                              </Grid>
                                              <Grid item xs={8} pl={0}>
                                                {index + 1}
                                              </Grid>
                                              <Grid item xs={4}>
                                                Product:
                                              </Grid>
                                              <Grid item xs={8} pl={0}>
                                                <Autocomplete
                                                  label="Items"
                                                  id="Items"
                                                  size="small"
                                                  variant="standard"
                                                  value={item.Name}
                                                  onSelect={(e) => {
                                                    if (
                                                      lineItems.filter(
                                                        (li) =>
                                                          li.Name ===
                                                          e.target.value,
                                                      ).length > 0
                                                    ) {
                                                      item.Item = ''
                                                      item.Name = ''
                                                      item.Quantity = 0
                                                      item.Supplier = cust.Id
                                                      item.UnitPrice = 0
                                                      item.UnitCost = 0
                                                      item.TotalPrice = 0
                                                      item.TaxAmount = 0
                                                      setSelectedItems(
                                                        selectedItems.map(
                                                          (litem) => {
                                                            return litem
                                                          },
                                                        ),
                                                      )

                                                      return
                                                    } else {
                                                      let selectedProduct = lineItems.filter(
                                                        (selItem) =>
                                                          selItem.Name ===
                                                          e.target.value,
                                                      )
                                                      if (
                                                        selectedProduct &&
                                                        selectedProduct.length >
                                                          0
                                                      ) {
                                                        item.Quantity =
                                                          selectedProduct[0]
                                                            .DefaultOrderQty !==
                                                          0
                                                            ? selectedProduct[0]
                                                                .DefaultOrderQty
                                                            : 1
                                                        item.UnitPrice = selectedProduct[0]
                                                          .UnitPrice
                                                          ? selectedProduct[0]
                                                              .UnitPrice
                                                          : 0
                                                        item.TaxAmount = selectedProduct.VATInclusive
                                                          ? item.TotalPrice *
                                                            0.15
                                                          : 0
                                                        item.Item =
                                                          selectedProduct[0].Item
                                                        item.Name =
                                                          selectedProduct[0].Name
                                                        item.Supplier = cust.Id
                                                        item.TotalPrice =
                                                          item.UnitPrice *
                                                          item.Quantity
                                                        setSelectedItems(
                                                          selectedItems.map(
                                                            (litem) => {
                                                              return litem
                                                            },
                                                          ),
                                                        )
                                                      } else {
                                                        item.Quantity = 0
                                                        item.UnitPrice = 0
                                                        item.UnitCost = 0
                                                        item.TotalPrice = 0
                                                        item.TaxAmount = 0
                                                        item.Item = ''
                                                        item.Name = ''
                                                        item.Supplier = cust.Id
                                                        setSelectedItems(
                                                          selectedItems.map(
                                                            (litem) => {
                                                              return litem
                                                            },
                                                          ),
                                                        )
                                                      }
                                                    }
                                                  }}
                                                  options={
                                                    !(
                                                      lineItems === undefined
                                                    ) &&
                                                    !(lineItems === null) &&
                                                    lineItems.length > 0
                                                      ? lineItems.map((it) => {
                                                          return it.Name
                                                        })
                                                      : ['']
                                                  }
                                                  sx={{ width: '100%' }}
                                                  renderInput={(params) => (
                                                    <TextField
                                                      {...params}
                                                      label={item.Name}

                                                      //error={!from ? true : false}
                                                    />
                                                  )}
                                                />
                                              </Grid>
                                              <Grid item xs={4}>
                                                Quantity:
                                              </Grid>
                                              <Grid item xs={8} pl={0}>
                                                <TextField
                                                  size="small"
                                                  variant="standard"
                                                  type="float"
                                                  value={item.Quantity}
                                                  maxRows={4}
                                                  sx={{ width: '100%' }}
                                                  onChange={(e) => {
                                                    item.Quantity =
                                                      e.target.value
                                                    setSelectedItems(
                                                      selectedItems.map(
                                                        (litem) => {
                                                          return litem
                                                        },
                                                      ),
                                                    )
                                                  }}
                                                  InputLabelProps={{
                                                    shrink: true,
                                                  }}
                                                />
                                              </Grid>

                                              <Grid item xs={4}>
                                                Unit Price:
                                              </Grid>
                                              <Grid item xs={8} pl={0}>
                                                <TextField
                                                  size="small"
                                                  variant="standard"
                                                  type="float"
                                                  value={item.UnitPrice}
                                                  sx={{ width: '100%' }}
                                                  onChange={(e) => {
                                                    item.UnitPrice =
                                                      e.target.value
                                                    setSelectedItems(
                                                      selectedItems.map(
                                                        (litem) => {
                                                          return litem
                                                        },
                                                      ),
                                                    )
                                                  }}
                                                  InputLabelProps={{
                                                    shrink: true,
                                                  }}
                                                />
                                              </Grid>
                                              <Grid item xs={4}>
                                                Total Price:
                                              </Grid>
                                              <Grid item xs={8} pl={8}>
                                                {(
                                                  item.UnitPrice * item.Quantity
                                                )?.toLocaleString(undefined, {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                                })}
                                              </Grid>
                                              <Grid item xs={12}>
                                                <hr></hr>
                                                Remove: {'   '}
                                                <Tooltip title="remove">
                                                  <Button
                                                    endIcon={
                                                      <Delete width="10px" />
                                                    }
                                                    onClick={() => {
                                                      handleLineItemDelete(
                                                        lineItems.filter(
                                                          (litem) =>
                                                            litem.Item ===
                                                            item.Item,
                                                        )[0].Id,
                                                      )
                                                      removeFromCart(item)
                                                      /*   setLineItems(
                                    lineItems.filter(
                                      (litem) => !(litem.Item === item.Item)
                                    )
                                  ); */
                                                      if (
                                                        lineItems.filter(
                                                          (litem) =>
                                                            !(
                                                              litem.Item ===
                                                              item.Item
                                                            ),
                                                        ).length <= 0
                                                      ) {
                                                        let newLineItem = {}
                                                        newLineItem.Item = ''
                                                        newLineItem.Name = ''
                                                        newLineItem.Supplier =
                                                          cust.Id
                                                        newLineItem.UnitCost = 0
                                                        newLineItem.Quantity = 0
                                                        newLineItem.UnitPrice = 0
                                                        newLineItem.TaxAmount = 0
                                                        newLineItem.TotalPrice = 0
                                                        setSelectedItems(
                                                          (xx) => [
                                                            ...xx,
                                                            newLineItem,
                                                          ],
                                                        )
                                                      }
                                                    }}
                                                  ></Button>
                                                </Tooltip>
                                              </Grid>
                                            </Grid>
                                          </CardContent>
                                        </Card>
                                        <hr></hr>
                                      </>
                                    )
                                  })}
                              <Table>
                                <tr>
                                  <td />
                                  <td hidden />
                                  <td />
                                  <td
                                    style={{
                                      border: 'solid 1',
                                      padding: '5px',
                                    }}
                                  >
                                    {' '}
                                  </td>
                                  <td
                                    style={{
                                      border: 'solid 1',
                                      padding: '5px',
                                      backgroundColor:
                                        theme.palette.primary.main,
                                      color: theme.palette.primary.contrastText,
                                    }}
                                    colSpan={2}
                                  >
                                    {' '}
                                    Sub Total:
                                  </td>

                                  <td
                                    style={{
                                      border: 'solid 1',
                                      padding: '5px',
                                      textDecoration: 'underline',
                                      backgroundColor:
                                        theme.palette.primary.main,
                                      color: theme.palette.primary.contrastText,
                                    }}
                                  >
                                    {cust.TotalAmount?.toLocaleString(
                                      undefined,
                                      {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      },
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    style={{
                                      border: 'solid 1',
                                      padding: '5px',
                                    }}
                                  ></td>
                                  <td
                                    style={{
                                      border: 'solid 1',
                                      padding: '5px',
                                    }}
                                    hidden
                                  >
                                    {' '}
                                  </td>
                                  <td
                                    style={{
                                      border: 'solid 1',
                                      padding: '5px',
                                    }}
                                  >
                                    {' '}
                                  </td>
                                  <td
                                    style={{
                                      border: 'solid 1',
                                      padding: '5px',
                                    }}
                                  >
                                    {' '}
                                  </td>

                                  <td
                                    style={{
                                      border: 'solid 1',
                                      padding: '5px',
                                      backgroundColor:
                                        theme.palette.primary.main,
                                      color: theme.palette.primary.contrastText,
                                    }}
                                    colSpan={2}
                                  >
                                    {' '}
                                    VAT Amount:
                                  </td>
                                  <td
                                    style={{
                                      border: 'solid 1',
                                      padding: '5px',
                                      backgroundColor:
                                        theme.palette.primary.main,
                                      color: theme.palette.primary.contrastText,
                                      textDecorationLine: 'underline',
                                    }}
                                  >
                                    {cust.TotalVATAmount?.toLocaleString(
                                      undefined,
                                      {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      },
                                    )}
                                  </td>
                                </tr>

                                <tr>
                                  <td
                                    style={{
                                      border: 'solid 1',
                                      padding: '5px',
                                    }}
                                  ></td>
                                  <td
                                    style={{
                                      border: 'solid 1',
                                      padding: '5px',
                                    }}
                                    hidden
                                  >
                                    {' '}
                                  </td>
                                  <td
                                    style={{
                                      border: 'solid 1',
                                      padding: '5px',
                                    }}
                                  >
                                    {' '}
                                  </td>
                                  <td
                                    style={{
                                      border: 'solid 1',
                                      padding: '5px',
                                    }}
                                  >
                                    {' '}
                                  </td>

                                  <td
                                    style={{
                                      border: 'solid 1',
                                      padding: '5px',
                                      backgroundColor:
                                        theme.palette.primary.main,
                                      color: theme.palette.primary.contrastText,
                                    }}
                                    colSpan={2}
                                  >
                                    {' '}
                                    Total Amount:
                                  </td>
                                  <td
                                    style={{
                                      border: 'solid 1',
                                      padding: '5px',
                                      textDecoration: 'underline',
                                      backgroundColor:
                                        theme.palette.primary.main,
                                      color: theme.palette.primary.contrastText,
                                    }}
                                  >
                                    {' '}
                                    {(
                                      cust.TotalAmount + cust.TotalVATAmount
                                    ).toLocaleString(undefined, {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                      minFractionDigits: 2,
                                    })}{' '}
                                  </td>
                                </tr>
                              </Table>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            border={1}
                            style={{
                              display:
                                selectedVoucher === cust.Id ? 'block' : 'none',
                            }}
                          >
                            <label htmlFor="Attachment">
                              Uplaod payment document
                              <input
                                id="Attachment"
                                name="Attachment"
                                type="file"
                                multiple
                                hidden
                                onChange={uploadMultipleFiles}
                              />
                            </label>
                            {Photofiles && Photofiles.length > 0 && (
                              <Button
                                style={{ color: 'red' }}
                                ml={4}
                                endIcon={<Close />}
                                onClick={() => {
                                  setPhotofiles([])
                                }}
                              />
                            )}
                            <div className="form-group multi-preview">
                              {(Photofiles || []).map((address) => (
                                <img
                                  src={address}
                                  alt="..."
                                  style={{ width: '100%', height: '100' }}
                                />
                              ))}
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  )
                }
              })}
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            fontSize: '8px',
          }}
        >
          <hr></hr>
          <Button variant="standard">
            <InvoicePreview
              icon={<div style={{ fontSize: '10px' }}>Preview</div>}
              data={{
                Id: 0,
                VoucherDate: orderDate,
                Amount: 0,
                VATAmount: 0.15,
                TotalAmount: 0.15,
                Customer: globalUser.Id,
                CustomerName: globalUser.Name,
                CustomerRegion: globalUser.Region,
                CustomerMobile: globalUser.Mobile,
                CustomerEmail: globalUser.Email,
                BillTo: globalUser.Id,
                Store: store,
                User: globalUser.Id,
              }}
              LineItems={lineItems}
              Suppliers={customers}
              Type="Invoice"
            />
          </Button>
          |
          <Button
            variant="standard"
            style={{ fontSize: '10px' }}
            /*  endIcon={<Send />} */
            autoFocus
          >
            <PaymentsModel
              icon={<div>Payment</div>}
              data=""
              OrderData={props.data}
              Title="NEW PAYMENT"
            />
          </Button>
          |
          <Button
            variant="standard"
            style={{ fontSize: '10px' }}
            /*  endIcon={<Send />} */
            autoFocus
            onClick={() =>
              handleSubmit(
                props.data ? props.data.OrderId : 0,
                'Delivery',
                orderDate,
                lineItems.filter((itt) => itt.Name && itt.Name.length > 0),
              )
            }
          >
            Deliver
          </Button>
          |
          {/*  <Button
              variant="standard"
                style={{ fontSize: "10px" }}
                endIcon={<Send />} 
                autoFocus
                onClick={() =>
                  handleSubmit(
                    props.data ? props.data.OrderId : 0,
                    "Order",
                    orderDate,
                    lineItems.filter((itt) => itt.Name && itt.Name.length > 0)
                  )
                }
              >
                Order
              </Button>
              |
              <Button
              variant="standard"
                style={{ fontSize: "10px" }}
                endIcon={<Send />} 
                autoFocus
                onClick={() =>
                  handleSubmit(
                    props.data ? props.data.OrderId : 0,
                    "Proforma",
                    orderDate,
                    lineItems.filter((itt) => itt.Name && itt.Name.length > 0)
                  )
                }
              >
                Proforma
              </Button>
              |
              <Button
              variant="standard"
                style={{ fontSize: "10px" }}
                endIcon={<Send />} 
                autoFocus
                onClick={() =>
                  handleSubmit(
                    props.data ? props.data.OrderId : 0,
                    "Draft",
                    orderDate,
                    lineItems.filter((itt) => itt.Name && itt.Name.length > 0)
                  )
                }
              >
                Draft
              </Button>
              | */}
          <Button
            variant="standard"
            style={{ fontSize: '10px' }}
            /* endIcon={<Send />} */
            autoFocus
            onClick={() => handleDelete(props.data.OrderId)}
          >
            Delete
          </Button>
          |
          <Button
            variant="standard"
            style={{ fontSize: '10px' }}
            /* endIcon={<Close />} */
            sx={{ alignSelf: 'end' }}
            onClick={() => setOpen(false)}
          >
            close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
