import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Typography,
  Avatar,
  Grid,
  Button,
    IconButton,
    Box,
  Tooltip
  } from "@mui/material";
  import {
    EditSharp,
    AddSharp,
    FileDownload,
    FileUpload,
    RefreshSharp,
    HistorySharp,
  } from "@mui/icons-material";
  import MaterialReactTable from "material-react-table";
  import { useState, useEffect, useMemo,useContext } from "react";
  import PaymentsModel from "./PaymentsModel";
  import * as XLSX from "xlsx";
  import axios from "../axiosInstances";
  import { useTranslation } from "react-i18next";
  import { ShoppingCartContext } from "./Context/ShoppingCartContext";

import { useLocation } from "react-router-dom";
import { Stack } from "@mui/system";
import PaymentsModelHistory from "./PaymentsModelHistory";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from 'react-router-dom'

  function StoresComp(props) {
    const navigate = useNavigate();
    const theme=useTheme();
    const [update, setUpdate] = useState(false);
    const location = useLocation();
    const {setPageHeader,globalUser,globalCustomers,globalProviders,globalToken } =    useContext(ShoppingCartContext);
    const { t, i18n } = useTranslation();
     const [paymentsList, setPaymentsList] = useState([]);
     const [customerList,setCustomerList]= useState([]);
     const columns = useMemo(        
      () => [
        {
          header: "Order Id",
          accessorKey: "OrderNo", 
        },
        {
          header: "Payment Id",
          accessorKey: "Id", 
        },
        {
            header: "Paid By",
            accessorKey: "PaidByName", 
          },
          {
            header: "Paid To",
            accessorKey: "PaidToName", 
          },
      
        {
          header: "Date",
          accessorKey: "PaidDate", 
        
        },
       
        {
            header: "Amount",
            accessorKey: "Amount", 
          
          },
         
          {
            header: "Remark",
            accessorKey: "Remark", 
          },
        
      ],
      []
    );
    const [bankList, setBankList] = useState([]);
    const loadBankList = () => {
      axios
        .get(
          "/settings/getByParentAndType?Parent=" + globalUser.Parent + "&Type=Bank" , {
            headers: {
              token: globalToken,
            },
          })
        .then((response) => {
          if (response.data.data) {
            setBankList(response.data.data);
          } else {
            // alert("log out and log in again voucher by voucher id");
          }
        });
    };
    const loadPaymentList =  () => {
     axios.get("/payments/getByPaidByAndTo?PaidBy="+ (location.state.Type==='Sales'?0:globalUser.Parent) +"&PaidTo="+ (location.state.Type==='Sales'?globalUser.Parent:0)+"&Status=''", {
      headers: {
        token: globalToken,
      },
    })
     .then(response=>{
          if (response.data.data){
            setPaymentsList(response.data.data);
          }else{
            alert('log out and log in again')
          }
        })
        
      };
     
      const handleUploadFile = async (e) => {
      const file = e.target.files[0];
      const data = await file.arrayBuffer();
      const workbook = XLSX.read(data);
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      var jsonData = XLSX.utils.sheet_to_json(worksheet);
  
      var fdata = new FormData();
      fdata.append("JsonData", JSON.stringify(jsonData));
      await axios.post("/items/Excel", fdata, {
        headers: {
          token: globalToken,
        },
      });
    };
    function handleDownloadFile() {
      const worksheet = XLSX.utils.json_to_sheet(paymentsList);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "stores");
      //let buf = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
      XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(workbook, "storesdata.xlsx");
    }
      
    useEffect(() => {
      if(!globalUser){
        navigate('/');
        return;
      }
      setCustomerList([]);
      setPageHeader(location.state.Type + " Payment" );
      loadBankList();
        loadPaymentList();
        setCustomerList(location.state.Type==="Sales"?globalCustomers:globalProviders);
    }, [update,location.state.Type]);
    return (   
           
      <Grid container>
   {bankList && bankList.length>0 ? 
   customerList && customerList.length>0 ?  
      <>
      <Grid item className="hideInMobile" m={1}>
    
            <MaterialReactTable
              columns={columns}
              data={paymentsList}
               initialState={{ 
                density: "compact",
                grouping:['OrderNo'],
                pagination:{
                  pageIndex:0,
                  pageSize:12,
                   }
              }}
              enableGlobalFilter
              enableColumnOrdering
              enableColumnResizing
              enableColumnFilters
              enableRowNumbers
                muiTopToolbarProps= {{
                sx: {backgroundColor: theme.palette.primary.main,color:theme.palette.primary.contrastText}
              }}
              muiBottomToolbarProps={{
                sx: {backgroundColor: theme.palette.primary.main,color:theme.palette.primary.contrastText}
              }}
              renderTopToolbarCustomActions={() => (
                <Box>
                  <Button      size="small"
                    variant="standard" sx={{borderRadius:'30px'}} endIcon={<AddSharp style={{ width: "20px", height: "20px" }} />}>
                    <PaymentsModel
                      icon={
                        <div>Add</div>
                      }
                      Title="New Payment"
                      BankList={bankList}
                      CustomersList={customerList}
                      Type={location.state.Type +" Payment"}
                      ParentUpdate={update}
                      setParentUpdate={setUpdate}
                  
                    />
                  </Button>|
                  <Button      size="small"
                    variant="standard" sx={{borderRadius:'30px'}} endIcon={<AddSharp style={{ width: "20px", height: "20px" }} />}>
                    <PaymentsModel
                      icon={
                        <div>Deposit</div>
                      }
                      Title="New Deposit Payment"
                      Status="Deposit"
                      BankList={bankList}
                      CustomersList={customerList}
                      Type={location.state.Type }
                      ParentUpdate={update}
                      setParentUpdate={setUpdate}
                  
                    />
                  </Button>|
  
                  <Button      size="small"
                    variant="standard" sx={{borderRadius:'30px'}} endIcon={<FileDownload style={{ width: "20px", height: "20px" }} />} type="file" onClick={handleDownloadFile}>
                   Download
                  </Button>|
                  <Tooltip title="refresh">
                  <Button      size="small"
                    variant="standard" sx={{borderRadius:'30px'}} endIcon={<RefreshSharp />}  onClick={() => setUpdate(!update)}>
                      Refresh
                    </Button>
                    </Tooltip>|
                    <Button variant="standard" style={{fontSize:'24px',borderRadius:'25px'}}>Balance: {globalUser.Balance}</Button>
                  
                </Box>
              )}
              renderDetailPanel={({ row }) => (
                <Stack>
                  Attachment
                  <img src={row.original.DepositSlip} alt="There is not attachment here" width='100%' height='300'/>
                </Stack>
              )}
              enableRowActions
              renderRowActions={(table) => (
                <Box>
                  <Tooltip title={t("History")}> 
                  <IconButton > 
                  
                   <PaymentsModelHistory
                  icon={<HistorySharp style={{ width: "20px", height: "20px" }} />}
                  data={table.row.original}
                  BankList={bankList}
                  Type={location.state.Type}
                  ParentUpdate={update}
                  setParentUpdate={setUpdate}
                 
                />   
                  </IconButton>
                 </Tooltip>
                </Box>
              )}
            ></MaterialReactTable> 
             </Grid>
             <Grid item xs={12} mt={1} className="hideInFull">
             <Box sx={{  width: "100%",backgroundColor: theme.palette.primary.main,color: theme.palette.primary.contrastText}}>
                  <Button size="small"
                    variant="standard" sx={{borderRadius:'30px'}} endIcon={<AddSharp style={{ width: "20px", height: "20px" }} />}>
                    <PaymentsModel
                      icon={
                        <div>Add</div>
                      }
                      Title="New Payment"
                     BankList={bankList}
                      Type={location.state.Type}
                      CustomersList={customerList}
                    />
                  </Button>|
               {/*    <Button size="small"
                    variant="standard" sx={{borderRadius:'30px'}} endIcon={<FileUpload style={{ width: "20px", height: "20px" }} />}>
                    <label htmlFor="file">
                      Upload
                    </label>
                    <input
                      id="file"
                      name="file"
                      hidden
                      type="file"
                      onChange={handleUploadFile}
                    />
                  </Button>| */}
  
                  <Button size="small"
                    variant="standard" sx={{borderRadius:'30px'}} endIcon={<FileDownload style={{ width: "20px", height: "20px" }} />} type="file" onClick={handleDownloadFile}>
                   Download
                  </Button>|
                  <Tooltip title="refresh">
                  <Button size="small"
                    variant="standard" sx={{borderRadius:'30px'}} endIcon={<RefreshSharp />}  onClick={() => setUpdate(!update)}>
                      Refresh
                    </Button>
                  </Tooltip>
                </Box>
             </Grid>
        {!(paymentsList === undefined) &&
          paymentsList.length > 0 &&
          paymentsList.map((item) => {
            return (
              <Grid item key={item.Id} xs={5} m={2} className="hideInFull">
                <Card sx={{ borderRadius: 10, boxShadow: 3}}>
                  <CardHeader
                   avatar={
                    <Avatar
                      src={
                        item.DepositSlip != null && item.DepositSlip.length > 0
                          ? item.DepositSlip.split(",")[0]
                          : "/Images/Products/default.png"
                      }
                    />
                  }
                    className="CardHeader"
                    title={item.PaidToName} 
                    subheader={"Date: "+item.PaidDate} 
                  />

                  <CardContent>
                    <Typography variant="body2" align="left">
                      Amount: {item.Amount}
                    </Typography>
                    <Typography variant="body2" align="left">
                      Paid Date: {item.PaidDate}
                    </Typography>
                    <Typography variant="body2" align="left">
                      Status: {item.Status}
                    </Typography>
                    <Typography variant="body2" align="left">
                      Type: {item.Type}
                    </Typography>
                    <Typography variant="body2" align="left">
                      Remark: {item.Remark}
                    </Typography>
                 </CardContent>
                  <CardActions
                  sx={{
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.primary.contrastText,
                      }} >
                    <Box>
                    <Tooltip title={t("Edit Store")}> 
                  <IconButton > 
                  
                   <PaymentsModel
                  icon={<EditSharp style={{ width: "20px", height: "20px" }} />}
                  data={item}
                  BankList={bankList}
                 Title={location.state.Type+ " Payment" }
                 Type={location.state.Type }
                  paymentsList={paymentsList}
                  ParentUpdate={update}
                  setParentUpdate={setUpdate}
                  CustomersList={customerList}
                 
                />   
                  </IconButton>
                 </Tooltip>
                    </Box>
                  </CardActions>
                </Card>
              </Grid>
            );
          })}
          </>
          :<div>There is no employee in this company</div>
          :<div>There is no bank in this company</div>
        }

      </Grid>
        
         );
  }
  
  export default StoresComp;