import { useState, useContext } from "react";
import {
  Button,
  Grid,
  Table,
  Card,
  CardContent
} from "@mui/material";
import {
  Close,
  Drafts,
  PreviewSharp,
  PaymentSharp,
} from "@mui/icons-material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
/* import PaymentModel from "./PaymentTransferModel"; */
import { ShoppingCartContext } from "./Context/ShoppingCartContext";
import PaymentsModel from "./PaymentsModel";
import axios from "../axiosInstances"
import InvoicePreview from "./Reports/InvoicePreview";
import { format } from "date-fns";
import { useTheme } from "@mui/material/styles";

export default function VouchersModel(props) {
  const theme=useTheme();
  const [open, setOpen] = useState(false);

  const {
    globalUser, 
    globalRoleSecurity,
    globalToken
  } = useContext(ShoppingCartContext);


  const [lineItems] = useState(props.LineItems);
  //const [supplier, setSupplier] = useState([{...props.data,Id:props.data.Supplier}]);

  const [isLineItemCollapsed, setIsLineItemCollapsed] = useState(false);


/* 
  const getCustomerById = (id) => {
    setSupplier();
     axios
      .get("/suppliers/getById?Id=" + id, {
        headers: {
          token: globalToken,
        },
      })
      .then((response) => {
        if (response.data.data) {          
          setSupplier(response.data.data[0]);
        }
      });
  }; */
 
  const handleReturn=()=>{
    if (
      window.confirm(
        "Are you sure you want to return the " + props.Type + " " + props.data.Status +"?"
      )
    ) {
    try {
      var fd = new FormData();
      fd.append("OrderId",props.data.OrderId)
      fd.append("ReturnedBy",globalUser.Id)
      fd.append("ReturnDate",format(new Date(),"yyyy-MM-dd"))
      fd.append(
        "LineItems",
        JSON.stringify(
          lineItems
        )
      );
      axios.post("/vouchers/ReturnOrder",fd, {
        headers: { 
          "Content-Type": "application/json" , 
            token: globalToken,
        },
      })
    } catch (err) {
    } finally {
      props.setParentUpdate(!props.ParentUpdate);
      setOpen(false);
    }
  }
  }

  const handleChangeStatus = (id,status) => {
    if (
      window.confirm(
        "Are you sure you want clear?"
      )
    ) {
    try {
      axios.get("/vouchers/ChangeStatus?Id=" + id +"&Status="+status, {
        headers: {
          token: globalToken,
        },
      });
    } catch (err) {
    } finally {
      props.setParentUpdate(!props.ParentUpdate);
      setOpen(false);
    }
  }
  };


 /*   useEffect(() => {
     if (props.data && !(props.data === null)) {
      setLineItems(props.LineItems);
    } else {
      setLineItems(selectedItems);
    } 

 if (props.data && !(props.data === null)) {
      getCustomerById(props.data.Supplier);
    } else { 
      setSupplier(
        globalUser
      );
     }  
  }, []);  */

  return (
    <div>
      <label
        onClick={() => {
          setOpen(true);
          setIsLineItemCollapsed(false);
        }}
      >
        {props.icon}
      </label>

      <Dialog
      open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="responsive-dialog-title"
      >
         {props.data && !(props.data===null) && 
       <>
       
      
       <DialogTitle sx={{backgroundColor: theme.palette.primary.main,color:theme.palette.primary.contrastText,}}  height="25px" onClick={()=>
      setIsLineItemCollapsed(!isLineItemCollapsed)}>
      {props.Type +  " " + props.data.Status }
      
    
       </DialogTitle >
      
      <DialogContent>
          <DialogContentText>
                      
                    <Grid
                      //key={cust.Id}
                      container
                      mt={1}
                      style={{
                                width: "100%"}} 

                      
                      className="collapse-button"
                    >
                    
                     
                    
                      <Grid item xs={12} md={6} borderBottom={1}>
                      <Grid container  paddingLeft={1}>
                       {/*  <Grid item xs={6} md ={4} >
                        Name:{" "}
                       </Grid>
                      <Grid item xs={6} md={8}>
                        {props.data.Name}
                      </Grid> */}
                      <Grid item xs={6} md ={4} >
                        BillTo:{" "}
                      </Grid>
                      <Grid item xs={6} md ={8}>
                        {props.data
                          ? props.data.Name
                          : globalUser.ParentName}
                      </Grid>
                      <Grid item xs={6} md ={4} >
                        Status:
                      </Grid>
                      <Grid item xs={6} md ={8}>
                        <label>
                          {props.data && !(props.data === null)
                            ? props.data.Status
                            : "Draft"}
                        </label>
                      </Grid>
                      <Grid item xs={6} md ={4} >
                        Store:{" "}
                       </Grid>
                      <Grid item xs={6} md={8}>
                        {props.data.StoreName}
                      </Grid>
                      {props.data.Status==='Return' &&
                      <>
                       <Grid item xs={6} md ={4} >
                        Returned_By:{" "}
                      </Grid>
                      <Grid item xs={6} md ={8} paddingLeft={3}>
                     {props.data.ReturnedByName}
                      </Grid>
                      </>
}
                        </Grid>
                      </Grid>
                     <Grid item xs={12} md={6} borderBottom={1}>
                      
                     <Grid container  paddingLeft={1}>
                 
                      <Grid item xs={6} md ={5} >
                        Doc. No.:{" "}
                      </Grid>
                      <Grid item xs={6} md ={6}>
                      {props.data && !(props.data === null)
                          ? props.data.OrderId
                          : 0}{" "} 
                      </Grid>
                      <Grid item xs={6} md ={4} >
                        Date:{" "}
                      </Grid>
                      <Grid item xs={6} md ={8} paddingLeft={3}>
                        {props.data && !(props.data === null)
                          ? props.data.VoucherDate
                          : format(new Date(),"yyyy-MM-dd")}{" "}
                      </Grid>
                     
                      
                      <Grid item xs={6} md ={4} >
                        User:{" "}
                      </Grid>
                      <Grid item xs={6} md ={8} paddingLeft={3}>
                     {props.data.UserName}
                      </Grid>
                    
                      {props.data.Status==='Return' &&
                      <>
                      
                      <Grid item xs={6} md ={4} >
                        Returned_Date:{" "}
                      </Grid>
                      <Grid item xs={6} md ={8} paddingLeft={3}>
                     {props.data.ReturnedDate}
                      </Grid>
                      </>
                      }

                        </Grid>
                     </Grid>
                     
                     
                      {/* <Grid item xs={3} >
                        Loan Amount:{" "}
                      </Grid>
                      <Grid item xs={3}>
                        {nf.format(props.data.Loan)}
                      </Grid> */}

                      

                     
                    </Grid>
                  

                  <hr></hr>

                  <Table
                    id="table"
                    style={{
                      width:"100%",
                      border: "solid 1",
                      display: +!isLineItemCollapsed ? "block" : "none",
                    }}
                      
                    aria-expanded={isLineItemCollapsed}
                  >
                     <Grid container>
                 <Grid item className="hideInMobile" m={1} xs={12}>
                    <thead
                     style={{
                      width:"100%",
                     fontSize: "12px",                 
                    backgroundColor: theme.palette.primary.main,
                    color:theme.palette.primary.contrastText
                     
                    }} 
                    >
                      <td
                        style={{
                          border: "solid 1",
                          padding: "5px",
                          width: '7%'
                        }}
                      >
                        No.{" "}
                      </td>
                      <td
                        style={{
                          border: "solid 1",
                          paddingRight: "5px",
                         
                        }}
                        hidden
                      >
                        Item{" "}
                      </td>
                      <td
                        style={{
                          width: "30%",
                          border: "solid 1",
                          padding: "5px",
                          
                        }}
                      >
                        Description{" "}
                      </td>
                      <td
                        style={{
                          width: "15%",
                          border: "solid 1",
                          
                          padding:"5px"
                        }}
                      >
                        UOM
                      </td>
                      <td
                        style={{
                          width: "15%",
                          border: "solid 1",
                          padding: "5px",
                          
                        }}
                      >
                        Quantity
                      </td>
                    
                      <td
                        style={{
                          width: "15%",
                          border: "solid 1",
                          
                        }}
                      >
                        Unit Price
                      </td>
                      <td
                        style={{
                          width: "18%",
                          border: "solid 1",
                          
                        }}
                      >
                        Total
                      </td>
                 
                    </thead>
                    <tbody>
                      {
                      
                      !(lineItems === undefined) &&
                        !(lineItems === null) &&
                        lineItems.length > 0 &&
                        lineItems.map((item, index) => {
                          
                            return (
                              <tr key={item.Item}
                              style={{
                                width:"100%",
                                border: "solid 1",
                                paddingLeft: "5px",
                              }}
                              >
                                <td
                                  style={{
                                    border: "solid 1",
                                    padding: "5px",
                                  }}
                                >
                                  {index + 1}{" "}
                                </td>
                                <td
                                  style={{
                                    border: "solid 1",
                                    padding: "5px",
                                  }}
                                  hidden
                                >
                                  {item.Item}{" "}
                                </td>
                                <td
                                  style={{
                                    border: "solid 1",
                                    padding: "5px",
                                  }}
                                >
                                  {item.Name}{" "}
                                </td>
                                <td
                                  style={{
                                    border: "solid 1",
                                    padding: "5px",
                                  }}
                                >
                                  {item.UOM}{" "}
                                </td>
                                <td
                                  style={{
                                    border: "solid 1",
                                    padding: "5px",
                                  }}
                                >
                           
                                  {item.Quantity?.toLocaleString(undefined, {maximumFractionDigits:2,minimumFractionDigits:2})}
                                </td>
                              
                                <td
                                  style={{
                                    border: "solid 1",
                                    padding: "5px",

                                  }}
                                >
                                  {item.UnitPrice?.toLocaleString(undefined, {maximumFractionDigits:2,minimumFractionDigits:2})}
                                </td>
                                <td
                                  style={{
                                    border: "solid 1",
                                    padding: "5px",
                                  }}
                                >
                                  {item.TotalPrice?.toLocaleString(undefined, {maximumFractionDigits:2,minimumFractionDigits:2})}
                                </td>
                               
                              </tr>
                            );
                          })}
                          <tr><td colSpan={6}><hr></hr></td></tr>
                      <tr
                        style={{
                          border: "solid 1",
                        }}
                      >
                        <td
                          style={{
                            border: "solid 1",
                            padding: "5px",
                          }}
                        ></td>
                        <td
                          style={{ border: "solid 1", padding: "5px" }}
                          hidden
                        >
                          {" "}
                        </td>
                        <td style={{ border: "solid 1", padding: "5px" }}> </td>
                        <td style={{ border: "solid 1", padding: "5px" }}> </td>
                        <td
                          style={{
                            padding: "5px",
                           /*  borderLeft: "solid",
                            borderBottom: "solid", */
                            fontWeight:'bold',
                          }}
                          colSpan={2}
                        >
                          {" "}
                          Sub Total:
                        </td>
                        <td
                           style={{
                          
                            padding: "5px",
                            textDecoration:'underline'
                           /*  border: "solid 1",
                            borderBottom: "solid",
                            borderRight: "solid", */
                          }}
                        >
                          {props.data.Amount?.toLocaleString(undefined, {maximumFractionDigits:2,minimumFractionDigits:2})}
                        </td>
                       
                        <td style={{ /* border: "solid 1", */ padding: "5px" }}> </td>
                      </tr>
                      <tr
                        style={{
                          /* border: "solid 1", */
                          padding: "5px",
                        }}
                      >
                        <td
                          style={{
                          /*   border: "solid 1", */
                            padding: "5px",
                          }}
                        ></td>
                        <td
                          style={{ /* border: "solid 1", */ padding: "5px" }}
                          hidden
                        >
                          {" "}
                        </td>
                        <td style={{ /* border: "solid 1", */ padding: "5px" }}> </td>
                        <td style={{/*  border: "solid 1", */ padding: "5px" }}> </td>
                        <td
                          style={{
                           /*  borderLeft: "solid",
                            borderBottom: "solid", */
                            padding: "5px",
                            fontWeight:'bold'
                          /*   backgroundColor: "#0971f1",
                            color: "white", */
                          }}
                          colSpan={2}
                        >
                          {" "}
                          VAT Amount:
                        </td>
                        <td
                           style={{
                          /*   borderBottom: "solid",
                            borderRight: "solid", */
                            textDecoration:'underline',
                            padding: "5px",
                            fontWeight:'bold'
                            }}
                        >
                          {props.data.VATAmount?.toLocaleString(undefined, {maximumFractionDigits:2,minimumFractionDigits:2})}
                        </td>
                        
                        <td style={{ border: "solid 1", padding: "5px" }}> </td>
                      </tr>

                      <tr
                        style={{
                          border: "solid 1",
                        }}
                      >
                        <td
                          style={{
                            /* border: "solid 1", */
                            padding: "5px",
                          }}
                        ></td>
                        <td
                          style={{ /* border: "solid 1", */ padding: "5px" }}
                          hidden
                        >
                          {" "}
                        </td>
                        <td style={{ /* border: "solid 1", */ padding: "5px" }}> </td>
                        <td style={{ border: "solid 1", padding: "5px" }}> </td>

                        <td
                           style={{
                            border: "solid 1",
                            padding: "5px",
                           /*  border: "solid 1",
                            borderBottom: "solid",
                            borderLeft: "solid", */
                            fontWeight:'bold'
                          }}
                          colSpan={2}
                        >
                          {" "}
                          Total Amount:
                        </td>
                        <td
                          style={{
                            
                            padding: "5px",
                            fontWeight:'bold',
                            textDecoration:'underline',
                          /*   border: "solid 1",
                            borderRight: "solid",
                            borderBottom: "solid", */
                          }}
                        >
                          {" "}
                          {props.data.TotalAmount?.toLocaleString(undefined, {maximumFractionDigits:2,minimumFractionDigits:2})}{" "}
                        </td>
                    
                       
                      </tr>
                 
                    </tbody>
                    </Grid>
                    <Grid item className="hideInFull" m={1}>
                     
                {lineItems.length > 0 &&
                    lineItems.map((item, index) => {
                      
                      return (
                        <>
                 <Card sx={{ borderRadius: 10, boxShadow: 3,width:"100%"}} >
                 
                  <CardContent>
                    <Grid container>
                      <Grid item xs={4}>
                        Item:
                        </Grid>
                        <Grid item xs={8} pl={0}>
                        {item.Name}
                      </Grid>
                      <Grid item xs={4}>
                        Quantity:
                        </Grid>
                        <Grid item xs={8} pl={0}>               
                        {item.Quantity?.toLocaleString(undefined, {maximumFractionDigits:2,minimumFractionDigits:2})}
                            </Grid>
                         <Grid item xs={4}>
                        UOM:
                        </Grid>
                        <Grid item xs={8} pl={0}>
                        {item.UOM}
                         </Grid>
                         <Grid item xs={4}>
                        Unit Price:
                        </Grid>
                        <Grid item xs={8} pl={0}>
                        {item.UnitPrice?.toLocaleString(undefined, {maximumFractionDigits:2,minimumFractionDigits:2})}
                              </Grid>
                         <Grid item xs={4}>
                        Total Price:
                        </Grid>
                        <Grid item xs={8} pl={8}>
                         
                             {item.TotalPrice?.toLocaleString(undefined, {maximumFractionDigits:2,minimumFractionDigits:2})}
                             </Grid>
                      
                          </Grid>
                 
                  </CardContent>
              
                
                  
                </Card>
                <hr></hr>
                </>
                      )})
                  }
              <Table>
             
               
                   <tr
                    
                    >
                      <td />
                      <td hidden />
                      <td />
                      <td style={{ border: "solid 1", padding: "5px" }}> </td>
                      <td  style={{
                               padding: "5px",
                               borderLeft: "solid",
                               borderBottom: "solid",
                               fontWeight:'bold',
                            }}
                            colSpan={2}>
                        {" "}
                        Sub Total:
                      </td>
                      
                      <td
                        style={{
                          border: "solid 1",
                            padding: "5px",
                            borderBottom: "solid",
                            borderRight: "solid",
                            fontWeight:'bold'
                        }}
                      >
                        {props.data.Amount?.toLocaleString(undefined, {maximumFractionDigits:2,minimumFractionDigits:2})}
                      </td>
                      
                      
                    </tr>
                    <tr
                     
                    >
                      <td
                        style={{
                          border: "solid 1",
                          padding: "5px",
                        }}></td>
                      <td style={{ border: "solid 1", padding: "5px" }} hidden>
                        {" "}
                      </td>
                      <td style={{ border: "solid 1", padding: "5px" }}> </td>
                      <td style={{ border: "solid 1", padding: "5px" }}> </td>
  
                      <td
                        style={{
                          padding: "5px",
                            borderLeft: "solid",
                            borderBottom: "solid",
                            fontWeight:'bold',
                        }}
                        colSpan={2}
                      >
                        {" "}
                        VAT Amount:
                      </td>
                      <td
                        style={{
                          border: "solid 1",
                          padding: "5px",
                          borderBottom: "solid",
                          borderRight: "solid",
                          fontWeight:'bold'
                        }}
                      >
                         {props.data.VATAmount?.toLocaleString(undefined, {maximumFractionDigits:2,minimumFractionDigits:2})}
                      </td>
                    
                    </tr>
  
                    <tr
                    
                    >
                      <td
                        style={{
                          border: "solid 1",
                          padding: "5px",
                        }}
                      ></td>
                      <td style={{ border: "solid 1", padding: "5px" }} hidden>
                        {" "}
                      </td>
                      <td style={{ border: "solid 1", padding: "5px" }}> </td>
                      <td style={{ border: "solid 1", padding: "5px" }}> </td>
  
                      <td
                        style={{
                          padding: "5px",
                          borderLeft: "solid",
                          borderBottom: "solid",
                          fontWeight:'bold',
                          
                        }}
                        colSpan={2}
                      >
                        {" "}
                        Total Amount:
                      </td>
                      <td
                        style={{
                          border: "solid 1",
                          padding: "5px",
                          borderBottom: "solid",
                          borderRight: "solid",
                          fontWeight:'bold'
                        }}
                      >
                        {" "}
                        {props.data.TotalAmount?.toLocaleString(undefined, {maximumFractionDigits:2,minimumFractionDigits:2})}{" "}
                      </td>
                    
                     
                    </tr>
                  </Table>
                </Grid>
                </Grid>
              </Table>
              
             
                 </DialogContentText>
        </DialogContent>
        </>
         }

                  <DialogActions sx={{backgroundColor: theme.palette.primary.main, color:theme.palette.primary.contrastText}}>
                  {props.data.Status!=='Draft' && props.data.Status!=='Request' && globalRoleSecurity.filter(
                  (sec) =>
                    (sec.Functionality === (props.Type +" Payment"))
                )[0].Security>1 && 
                <>
                  <Button
                   variant="standard"
                endIcon={<PaymentSharp />}
                >
                 <PaymentsModel
                      icon={
                        <div>Payment</div>
                      }
                      data=""
                      BankList={props.BankList}
                      OrderData={props.data}
                      Status='Send'
                      Title="NEW PAYMENT"
                  
                    />
                
              </Button>
              |</>
}
{props.data  &&(globalRoleSecurity.filter(
                  (sec) =>
                    (sec.Functionality === ("Purchase Request Approval"))
                )[0].Security>1 ) &&
                    <>
                   
                    <Button
                     variant="standard"
                      endIcon={<Drafts />}
                      
                      onClick={() => {
                        //handleUnApprove()
                      }}
                    >
                      Unapprove
                    </Button>
                    |
                    </>
                    } 
                    {props.data && ((globalRoleSecurity.filter(
                  (sec) =>
                    (sec.Functionality === ("Sales Return"))
                )[0].Security>1 && props.data.Status==="Delivery") || (globalRoleSecurity.filter(
                  (sec) =>
                    (sec.Functionality === ("Purchase Return"))
                )[0].Security>1 && props.data.Status==="Received")) &&
                    <>
                   
                    <Button
                     variant="standard"
                      endIcon={<Drafts />}
                      
                      onClick={() => {
                        handleReturn()
                      }}
                    >
                      Return
                    </Button>
                    |
                    </>
                    } 
                    {props.data && 
                    (props.data.Status==="Order" && ((globalRoleSecurity.filter(
                  (sec) =>
                    (sec.Functionality === ("Purchase Order"))
                )[0].Security>1 ) || ((globalRoleSecurity.filter(
                  (sec) =>
                    (sec.Functionality === ("Sales Order"))
                )[0].Security>1)) )) &&
                <>
                     <Button
                      variant="standard"
                      endIcon={<Drafts />}
                      
                      onClick={() => {
                        handleChangeStatus(props.data.OrderId,"Cleared");
                      }}
                    >
                      Cleared
                    </Button>
                    |
                    </>
}


                      <Button
                       variant="standard"
                      endIcon={<PreviewSharp />}
                      
                     
                    >
                   <InvoicePreview 
                    icon={<div>Preview</div>}
                        Suppliers={[{...props.data,Id:props.data.Supplier}]}
                        LineItems={lineItems}
                        Type="Invoice"
                        DocumentType={props.Type + " " + props.data.Status}
                        />
                    </Button>|
                    <Button
                     variant="standard"
                     size="small"
                      endIcon={<Close />}
                      
                      onClick={() => {  alert(props.data.Status);setOpen(false)}}
                      autoFocus
                    >
                      
                      close
                    </Button>
                  </DialogActions>
              
       
      </Dialog>
    </div>
  );
}
