import { useContext, useState } from "react";
import {
  Text,
  Page,
  Document,
  View,
  PDFViewer,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Button,Box } from "@mui/material";
import { Close } from "@mui/icons-material";
import { format } from "date-fns";
import { ShoppingCartContext } from "../Context/ShoppingCartContext"
import { useTheme } from "@mui/material/styles";

const numberToText = require('number-to-text')
require('number-to-text/converters/en-us'); // load converter
const borderColor = "#90e5fc";
const styles = StyleSheet.create({
    page: {
        fontFamily: "Helvetica",
        fontSize: 11,
        paddingTop: 30,
        paddingLeft:60,
        paddingRight:60,
        lineHeight: 1.5,
        flexDirection: 'column',
        objectFit:'cover'
       
    }, 
    logo: {
        width: 500,
        height: 45,
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    SignaturePic: {
      width: 80,
      height: 45,
  },
  remark: {
    left: 50,
},
pageNumber: {
  position: 'absolute',
  //fontSize: 11,
  bottom: 0,
  marginTop:10,
  paddingLeft:10,
  //padding:0,
  width:'800',
  //textAlign: 'center',
  color: 'gray',
  borderTop: 1,
},
  titleContainer: {
    flexDirection: "row",
    width:'100%',
    marginTop: 24,
  },
  reportTitle: {
    color: "#61dafb",
    width:'100%',
    letterSpacing: 4,
    fontSize: 20,
    textAlign: "center",
    textTransform: "uppercase",
  },
  headerContainer: {
    marginTop: 36,
  },
  billTo: {
    marginTop: 20,
    paddingBottom: 3,
    fontFamily: "Helvetica-Oblique",
  },
  invoiceNoContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
  
  },
  invoiceDateContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  invoiceDate: {
    fontSize: 12,
    fontStyle: "bold",
  },
  label: {
    marginRight:4,
  },
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 24,
    borderWidth: 1,
    borderColor: "#bff0fd",
  },
  row: {
    flexDirection: "row",
    borderBottomColor: "#bff0fd",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    fontStyle: "bold",
    width: "100%",
  },
  description: {
    width: "40%",
    textAlign: "left",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 8,
  },
  signature: {
    textAlign: "left",
    left:40
  },

  qty: {
    width: "20%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "right",
    paddingRight: 8,
  },
  rate: {
    width: "20%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "right",
    paddingRight: 8,
  },
  amount: {
    width: "20%",
    textAlign: "right",
    paddingRight: 8,
  },
  total: {
    width: '50%',
    textAlign: 'right',
    paddingRight: 2,
    alignContent: 'right',
    alignItems:'right',
    alignSelf:'right'
},
totalAmount: {
  width: "50%",
  textAlign: "left",
  borderRightColor: borderColor,
  borderRightWidth: 1,
  paddingLeft: 8,
},
jobdescription: {
  width: "50%",
  textAlign: "left",
  borderRightColor: borderColor,
  borderRightWidth: 1,
  paddingLeft: 8,
},
jobRemark: {
  width: "30%",
  textAlign: "left",
  borderRightColor: borderColor,
  borderRightWidth: 1,
  paddingLeft: 8,
},
reportFooter: {
    color: "#61dafb",
    width:'100%',
    letterSpacing: 4,
    fontSize: 12,
    textAlign: "center",
    textTransform: "uppercase",
  },
  Payment:{    
    letterSpacing: 4,
    fontSize: 12,
    textAlign: "left",
    textTransform: "uppercase",
    textDecoration:'underline',
    textAlign:"center"
  },
 
});

export default function InvoicePreview(props) {
  const theme=useTheme();
  const [open, setOpen] = useState(false);
  const { globalUser,setPageHeader }=useContext(ShoppingCartContext)
  const MyVoucherDocument = (props) => (    
    <Document>
      
      {props.Suppliers && (props.Suppliers!==null) && props.Suppliers.map(sup=>{
      return(
      <Page size="A4" style={styles.page} key={sup.Id}>
        {globalUser.CompanyHeader &&
       <Image style={styles.logo} src={globalUser.CompanyHeader} />  
        }
       <View style={styles.row}></View>
        <View style={styles.titleContainer}>
          <Text style={styles.reportTitle}>{props.DocumentType}</Text>
        </View>

        <View style={styles.invoiceNoContainer}>
          <Text style={styles.label}>Invoice No:</Text>
          <Text style={styles.invoiceDate}>{sup.OrderId}</Text>
        </View>
        <View style={styles.invoiceDateContainer}>
          <Text style={styles.label}>Date:</Text>
          <Text style={styles.invoiceDate}>{sup.VoucherDate?sup.VoucherDate.toLocaleString().substring(0, 10):format(new Date(),"yyyy-MM-dd")}</Text>
        </View>
        {((sup.Status!==undefined) && (props.DocumentType.includes('Sales') || (sup.Status!=='Draft' && sup.Status!=='Request' && sup.Status!=='Approved'))) &&
        <View style={styles.headerContainer}>
          <Text style={styles.billTo}>Bill To:</Text>
          <Text>{sup.Name==='undefined'?'':sup.Name}</Text>
          <Text>{sup.Region==='undefined'?'':sup.Region}</Text>
      <Text>{sup.Mobile==='undefined'?'':sup.Mobile}</Text>
      <Text>{sup.Email==='undefined'?'':sup.Email}</Text>  
        </View>
        }
        <View style={styles.tableContainer}>
          <View style={[styles.row,{backgroundColor:'lightgrey'}]}>
            <Text style={styles.description}>Item Description</Text>
            <Text style={styles.qty}>Qty</Text>
            {((sup.Status!==undefined) && (props.DocumentType.includes('Sales') || (sup.Status!=='Draft' && sup.Status!=='Request' && sup.Status!=='Approved'))) &&
            <>
            <Text style={styles.rate}>Unit Price</Text>
            <Text style={styles.amount}>Amount</Text>
            </>
      }
          </View>

          {/* <InvoiceTableRow items={invoice.items} />  */}

          {props.LineItems &&
            props.LineItems.map((item) => {
              if(parseInt(item.Supplier)===parseInt(sup.Id)){
              return (
                  <View style={styles.row} key={item.Item}>
                    <Text style={styles.description}>{item.Name}</Text>
                    <Text style={styles.qty}>
                    {props.Type==="InvoiceReceving"?
                    (item.ReceivingQuantity?item.ReceivingQuantity:0)?.toLocaleString(undefined, {maximumFractionDigits:2,minimumFractionDigits:2}):
                    (item.Quantity?item.Quantity:0)?.toLocaleString(undefined, {maximumFractionDigits:2,minimumFractionDigits:2})
                    }
                    </Text>

                    {((sup.Status!==undefined) && (props.DocumentType.includes('Sales') || (sup.Status!=='Draft' && sup.Status!=='Request' && sup.Status!=='Approved')))  &&
            <>
                    <Text style={styles.rate}>{(item.UnitPrice?item.UnitPrice:0)?.toLocaleString(undefined, {maximumFractionDigits:2,minimumFractionDigits:2})}</Text>
                    <Text style={styles.amount}>{(item.TotalPrice?item.TotalPrice:0)?.toLocaleString(undefined, {minimumFractionDigits: 2,maximumFractionDigits: 2})}</Text>
                    </>
              }
                  </View>
                );
              }
              }
                    
            )}

         
        <View style={styles.row}>
            <Text style={styles.description}>-</Text>
            <Text style={styles.qty}>-</Text>
            <Text style={styles.rate}>-</Text>
            <Text style={styles.amount}>-</Text>
        </View>
          
          {((sup.Status!==undefined) && (props.DocumentType.includes('Sales') || (sup.Status!=='Draft' && sup.Status!=='Request' && sup.Status!=='Approved')))  &&
            <>
          <View style={styles.row}>
            <Text style={styles.totalAmount}>SUB TOTAL</Text>
            <Text style={styles.total}>{((sup.Amount || sup.Amount==null)?sup.Amount:0)?.toLocaleString(undefined, {maximumFractionDigits:2,minimumFractionDigits:2})}</Text>
        </View>
        <View style={styles.row}>
            <Text style={styles.totalAmount}>VAT</Text>
            <Text style={styles.total}>{ ((sup.VATAmount || sup.VATAmount===null)?sup.VATAmount:0)?.toLocaleString(undefined, {maximumFractionDigits:2,minimumFractionDigits:2})}</Text>
        </View>
        <View style={styles.row}>     
            <Text style={styles.totalAmount}>TOTAL AMOUNT</Text>
            <Text style={styles.total}>{ (sup.TotalAmount || (sup.TotalAmount===null)?sup.TotalAmount:0)?.toLocaleString(undefined, {maximumFractionDigits:2,minimumFractionDigits:2})}</Text>
        </View>
        </>
      }
        </View>
        {sup.Status==='Return' &&
         <View style={styles.headerContainer}>     
         <Text style={styles.billTo}>{ ("Returned By " + sup.ReturnedByName) + "   Returned Date: " + sup.ReturnedDate}</Text>
     </View>
        }
  {((sup.Status===undefined) || sup.Status==='Draft' || sup.Status==='Request' || sup.Status==='Approved' || sup.Status==='Received')   &&
  <View style={styles.headerContainer}>     
  <Text style={styles.billTo}>{ "Requested By " + sup.RequestedByName + "              Requested Date: " + sup.RequestedDate}</Text>
  {(sup.Status==='Approved')  &&
   <Text style={styles.billTo}>{ "Approved By " + sup.ApprovedByName +"                   Approved Date: " + sup.ApprovedDate}</Text>
  }
    {(sup.Status==='Received')  &&
   <Text style={styles.billTo}>{ "Received By " + sup.UserName +"                   Received Date: " + sup.VoucherDate}</Text>
  }
</View>
      }

        
        <View style={styles.titleContainer}>
        <Text style={styles.reportFooter}>Thank you for your business</Text>
    </View>
    <Text   style={styles.pageNumber} render={({ pageNumber, totalPages }) => ('Designed By ARMA IT Solution PLC. Mobile: 251-913-4040-58   Email: ArmaItSolution@gmail.com         Page: ' +
                        `${pageNumber} / ${totalPages}`
                    )}  fixed /> 
      </Page>
              )
      })
    }
    </Document>
  );
  const MyPaymentDocument = (props) => (
    
    <Document  style={{ width: "500px", height: "500px" }}>
     
      <Page size="A4" style={styles.page} scale={2} >
       <Image style={styles.logo} src={globalUser.CompanyHeader} />  
       <View style={styles.row}></View>
        <View style={styles.titleContainer}>
          <Text style={styles.reportTitle}>{props.DocumentType}</Text>
        </View>

        <View style={styles.invoiceNoContainer}>
          <Text style={styles.label}>Payment No:</Text>
          <Text style={styles.invoiceDate}>{props.data.Id}</Text>
        </View>
        <View style={styles.invoiceDateContainer}>
          <Text style={styles.label}>Date:</Text>
          <Text style={styles.invoiceDate}>{props.data.PaidDate}</Text>
        </View>

        <View style={styles.headerContainer}>
        <Text style={styles.label}>Paid By: {"                   "} <Text style={styles.Payment}>{props.data.PaidByName}</Text></Text>                 
          <Text style={styles.label}>Paid To: {"                   "} <Text style={styles.Payment}>{props.data.PaidToName}</Text></Text>                 
          <Text style={styles.label}>Amount In Words: {"    "}<Text style={{textAlign:'justify',textDecoration:'underline'}}>{numberToText.convertToText((props.data.Amount?props.data.Amount:0).split('.')[0],{case:"upperCase"}) + " BIRR AND " + ((props.data.Amount?props.data.Amount:0).split('.').length>0?(props.data.Amount?props.data.Amount:0).split('.')[1]>0?numberToText.convertToText((props.data.Amount?props.data.Amount:0).split('.')[1],{case:"upperCase"}): " 00/100": " 00/100") +  " CENTS ONLY"}</Text></Text>
          <Text style={styles.label}>Amount In Figure:{"     "}<Text style={styles.Payment}>{(props.data.Amount?props.data.Amount:0)?.toLocaleString(undefined, {maximumFractionDigits:2,minimumFractionDigits:2})}</Text></Text>
        </View>
        <View style={styles.titleContainer}>
        <Text style={styles.reportFooter}>Thank you for your business</Text>
         </View>
         <Text   style={styles.pageNumber} render={({ pageNumber, totalPages }) => ('Designed By ARMA IT Solution PLC. Mobile: 251-913-4040-58   Email: ArmaItSolution@gmail.com         Page: ' +
                        `${pageNumber} / ${totalPages}`
                    )}  fixed /> 
      </Page>
   
    </Document>
  );
  const MyPostingDocument = (props) => (
    
    <Document  style={{ width: "500px", height: "500px" }}>
     
      <Page size="A4" style={styles.page} scale={2} >
       <Image style={styles.logo} src={globalUser.CompanyHeader} />  
       <View style={styles.row}></View>
        <View style={styles.titleContainer}>
          <Text style={styles.reportTitle}>{props.DocumentType}</Text>
        </View>
        <View style={[styles.invoiceNoContainer,{marginTop:36}]}>
          <Text style={styles.label}>Type:{props.data[0].Type}</Text>
        </View>
        <View style={styles.invoiceNoContainer}>
          <Text style={styles.label}>Ext. Doc. No:{props.data[0].DocumentNo}</Text>
        </View>
       
        <View style={styles.invoiceNoContainer}>
          <Text style={styles.label}>Document No:{props.data[0].DocumentNo}</Text>
        </View>
       
        <View style={styles.invoiceDateContainer}>
          <Text style={styles.label}>Date:{props.data[0].PostingDate}</Text>
        </View>
{props.data && props.data.map(jl=>{
return(<View style={styles.headerContainer}>
        <Text>Debit:{"     "+jl.DebitAccountName} <Text>{"                        "+(jl.Amount?jl.Amount:0)}</Text></Text>
        <Text>Credit:{"    "+jl.CreditAccountName} <Text>{"                                                  "+(jl.Amount?jl.Amount:0)}</Text></Text>
        </View>
)
        })}
        <View style={styles.headerContainer}>
        <Text>{"Remark: "+ props.data[0].Remark?props.data[0].Remark:''}</Text>
        </View>
         <Text   style={styles.pageNumber} render={({ pageNumber, totalPages }) => ('Designed By ARMA IT Solution PLC. Mobile: 251-913-4040-58   Email: ArmaItSolution@gmail.com         Page: ' +
                        `${pageNumber} / ${totalPages}`
                    )}  fixed /> 
      </Page>
   
    </Document>
  );
  const MyJobOrderDocument = (props) => (   
   
    <Document  style={{ width: "500px", height: "500px" }}>
      
      {props.data && (props.data!==null) && props.data.map(Ord=>{
      return(
      <Page size="A4" style={styles.page} scale={2} key={Ord.Id}>
        {globalUser.CompanyHeader && 
       <Image style={styles.logo} src={globalUser.CompanyHeader} />  
}
<View style={styles.row}></View>
        <View style={styles.titleContainer}>
          <Text style={styles.reportTitle}>{props.DocumentType}</Text>
        </View>

        <View style={styles.invoiceNoContainer}>
          <Text style={styles.label}>Order No:</Text>
          <Text style={styles.invoiceDate}>{Ord.Id}</Text>
        </View>
        <View style={styles.invoiceDateContainer}>
          <Text style={styles.label}>Date:</Text>
          <Text style={styles.invoiceDate}>{Ord.OrderDate?Ord.OrderDate:format(new Date(),"yyyy-MM-dd")}</Text>
          
        </View>
        <View style={styles.invoiceDateContainer}>
          <Text style={styles.label}>Status:</Text>
        <Text style={styles.invoiceDate}>{Ord.OrderDate?Ord.Status:'Draft'}</Text>
        </View>

        <View style={styles.headerContainer}>
          <Text style={styles.billTo}>Assigned To:</Text>
          <Text>{Ord.AssignedToName}</Text>          
      <Text>{Ord.AssignedToMobile}</Text>
      <Text>{Ord.AssignedToEmail}</Text>  
        </View>
        <View style={styles.tableContainer}>
          <View style={styles.row}>
            <Text style={styles.jobdescription}>Job Description</Text>
            <Text style={styles.qty}>Qty</Text>
            <Text style={styles.jobremark}>Remark</Text>
          </View>

          {/* <InvoiceTableRow items={invoice.items} />  */}

          {props.jobLines &&
            props.jobLines.map((item) => {
              //if(parseInt(item.AssignedTo)===parseInt(Ord.AssignedTo)){
              return (
                  <View style={styles.row} key={item.Item}>
                    <Text style={styles.jobdescription}>{item.Name}</Text>
                    <Text style={styles.qty}>{item.OrderQuantity?item.OrderQuantity?.toLocaleString(undefined, {maximumFractionDigits:2,minimumFractionDigits:2}):0.00}</Text>
                    <Text style={styles.jobremark}>{item.Remark!=='null'?item.Remark:''}</Text>
                   </View>
                );
             // }
              }
                    
            )}

           {/* <InvoiceTableBlankSpace rowsCount={ tableRowsCount - props.LineItems.length} />  */}
           <View style={styles.row}>
            <Text style={styles.jobdescription}>-</Text>
            <Text style={styles.qty}>-</Text>
            <Text style={styles.jobremark}>-</Text>
        </View>
       
         
        </View>
        <View style={styles.signature}><Text>{" "}</Text></View>
        <View><Text style={styles.remark}>{Ord.Remark!=='null' && Ord.Remark!=='undefined'?"Remark:":''}</Text></View>
        <View><Text style={[styles.remark,{marginLeft:40}]}>{Ord.Remark!=='null' && Ord.Remark!=='undefined'?Ord.Remark:''}</Text></View>
        
        {/*   <InvoiceThankYouMsg /> */}
        <View style={styles.signature}><Text style={styles.jobdescription}>{" "}</Text></View>
        <View style={styles.signature}>
        <Text style={styles.jobdescription}>{Ord.Status==="Approved"?"Approved by":"Requested By"}</Text> 
        </View>
        <View style={styles.signature}>
{Ord.Status==="Approved"? Ord.ApprovedBySignature && 
                  <Image style={styles.SignaturePic} src={Ord.ApprovedBySignature} />:
                  Ord.OrderedBySignature && 
                  <Image style={styles.SignaturePic} src={Ord.OrderedBySignature} />
      }
        </View>
        <View style={styles.signature}>
        <Text style={styles.jobdescription}>{Ord.Status==="Approved"?Ord.ApprovedByName:Ord.OrderedByName}</Text>
        
    </View>
    <Text   style={styles.pageNumber} render={({ pageNumber, totalPages }) => ('Designed By ARMA IT Solution PLC. Mobile: 251-913-4040-58   Email: ArmaItSolution@gmail.com         Page: ' +
                        `${pageNumber} / ${totalPages}`
                    )}  fixed /> 
      </Page>
              )
      })
    }
    </Document>
  );
  const MyItemJournalDocument = (props) => (   
   
    <Document  style={{ width: "500px", height: "500px" }}>
      
      {props.data && (props.data!==null) && props.data.map(Ord=>{
      return(
      <Page size="A4" style={styles.page} scale={2} key={Ord.Id}>
        {globalUser.CompanyHeader && 
       <Image style={styles.logo} src={globalUser.CompanyHeader} />  
}
<View style={styles.row}></View>
        <View style={styles.titleContainer}>
          <Text style={styles.reportTitle}>{props.DocumentType}</Text>
        </View>

        <View style={styles.invoiceNoContainer}>
          <Text style={styles.label}>Order No:</Text>
          <Text style={styles.invoiceDate}>{Ord.Id}</Text>
        </View>
        <View style={styles.invoiceDateContainer}>
          <Text style={styles.label}>Date:</Text>
          <Text style={styles.invoiceDate}>{Ord.OrderDate?Ord.OrderDate:format(new Date(),"yyyy-MM-dd")}</Text>
        </View>
        {props.Type!=="TransferOrder" && Ord.Status==='Issued' &&
        <View style={styles.invoiceNoContainer}>
        <Text style={styles.label}>Issue By:</Text>
          <Text style={styles.invoiceDate}>{Ord.IssuedByName}</Text>    
        </View>
        }
        
      {props.Type==="TransferOrder"?
        <View style={styles.headerContainer}>
          <Text style={styles.label}>From Store:</Text>
          <Text style={styles.invoiceDate}>{Ord.FromStoreName}</Text>   
          <Text style={styles.label}>To Store:</Text>
          <Text style={styles.invoiceDate}>{Ord.ToStoreName}</Text>        
       </View>:
       <View style={styles.headerContainer}>
       <Text style={styles.billTo}>Issue To:</Text>
       <Text style={styles.invoiceDate}>{Ord.IssuedToName}</Text>          
    </View>

      }
        <View style={styles.tableContainer}>
          <View style={styles.row}>
            <Text style={styles.jobdescription}>Job Description</Text>
            <Text style={styles.qty}>Qty</Text>
            <Text style={styles.jobremark}>Remark</Text>
          </View>

          {/* <InvoiceTableRow items={invoice.items} />  */}

          {props.jobLines &&
            props.jobLines.map((item) => {
              //if(parseInt(item.AssignedTo)===parseInt(Ord.AssignedTo)){
              return (
                  <View style={styles.row} key={item.Item}>
                    <Text style={styles.jobdescription}>{item.Name}</Text>
                    <Text style={styles.qty}>
                      {props.Type==="IssueOrderIssuing"?
                      item.ReceivingQuantity?item.ReceivingQuantity?.toLocaleString(undefined, {maximumFractionDigits:2,minimumFractionDigits:2}):0.00:
                      item.Quantity?item.Quantity?.toLocaleString(undefined, {maximumFractionDigits:2,minimumFractionDigits:2}):0.00
}
                      </Text>

                    <Text style={styles.jobremark}>{item.Remark!=='null'?item.Remark:''}</Text>
                   </View>
                );
             // }
              }
                    
            )}

           {/* <InvoiceTableBlankSpace rowsCount={ tableRowsCount - props.LineItems.length} />  */}
        
        <View style={styles.row}>
            <Text style={styles.jobdescription}>-</Text>
            <Text style={styles.qty}>-</Text>
            <Text style={styles.jobremark}>-</Text>
        </View>
         
        </View>
        <View style={styles.signature}><Text>{" "}</Text></View>
        <View><Text style={styles.remark}>{Ord.Remark!=='null' && Ord.Remark!=='undefined'?"Remark:":''}</Text></View>
        <View><Text style={[styles.remark,{marginLeft:40}]}>{Ord.Remark!=='null' && Ord.Remark!=='undefined'?Ord.Remark:''}</Text></View>
        
        {/*   <InvoiceThankYouMsg /> */}
        <View style={styles.signature}><Text style={styles.jobdescription}>{" "}</Text></View>
        {props.Type==="TransferOrder" ?
         Ord.Status==="Issued" || Ord.Status==="Received"?
         <View style={{flexDirection:'row'}}>
         {/*   Requested by */}
         <View>
             <View style={styles.signature}>
             <Text style={styles.jobdescription}>Requested By</Text> 
             </View>
             <View style={styles.signature}>
              {Ord.RequestedBySignature && 
             <Image style={styles.SignaturePic} src={Ord.RequestedBySignature} />
              }
             </View>
             <View style={styles.signature}>
             <Text style={styles.jobdescription}>{Ord.RequestedByName}</Text>
             <Text style={styles.jobdescription}>{Ord.RequestedDate}</Text>
             </View>
             </View>
              {/*   Approved by */}
              <View style={{marginLeft:'30'}}>
              <View style={styles.signature}>
             <Text style={styles.jobdescription}>Approved By</Text> 
             </View>
             <View style={styles.signature}>
               {Ord.ApprovedBySignature && 
             <Image style={styles.SignaturePic} src={Ord.ApprovedBySignature} />
               }
             </View>
             <View style={styles.signature}>
             <Text style={styles.jobdescription}>{Ord.ApprovedByName}</Text>
             <Text style={styles.jobdescription}>{Ord.ApprovedDate}</Text>
             </View>
             </View>
                 {/*   Issued by */}
               
             <View style={{marginLeft:'30'}}>
              <View style={styles.signature}>
             <Text style={styles.jobdescription}>Issued By</Text> 
             </View>
             <View style={styles.signature}>
               {Ord.IssuedBySignature && 
             <Image style={styles.SignaturePic} src={Ord.IssuedBySignature} />
               }
             </View>
             <View style={styles.signature}>
             <Text style={styles.jobdescription}>{Ord.IssuedByName}</Text>
             <Text style={styles.jobdescription}>{Ord.IssuedDate}</Text>
             </View>
             </View>
      
             {/*   Received by */}
             {Ord.Status==="Received" &&
             <View style={{marginLeft:'30'}}>
              <View style={styles.signature}>
             <Text style={styles.jobdescription}>Received By</Text> 
           
             </View>
             <View style={styles.signature}>
               {Ord.ReceivedBySignature && 
             <Image style={styles.SignaturePic} src={Ord.ReceivedBySignature} />
               }
             </View>
             <View style={styles.signature}>
             <Text style={styles.jobdescription}>{Ord.ReceivedByName}</Text>
             <Text style={styles.jobdescription}>{Ord.ReceivedDate}</Text>
             </View>
             </View>
      }
             </View>
             :
             Ord.Status==="Approved"?
             <View style={{flexDirection:'row'}}>
             {/*   Requested by */}
             <View>
                 <View style={styles.signature}>
                 <Text style={styles.jobdescription}>Requested By</Text> 
                 </View>
                 <View style={styles.signature}>
                  {Ord.RequestedBySignature && 
                 <Image style={styles.SignaturePic} src={Ord.RequestedBySignature} />
                  }
                 </View>
                 <View style={styles.signature}>
                 <Text style={styles.jobdescription}>{Ord.RequestedByName}</Text>
                 </View>
                 </View>
                  {/*   Approved by */}
                  <View style={{marginLeft:'100'}}>
                  <View style={styles.signature}>
                 <Text style={styles.jobdescription}>Approved By</Text> 
                 </View>
                 <View style={styles.signature}>
                   {Ord.ApprovedBySignature && 
                 <Image style={styles.SignaturePic} src={Ord.ApprovedBySignature} />
                   }
                 </View>
                 <View style={styles.signature}>
                 <Text style={styles.jobdescription}>{Ord.ApprovedByName}</Text>
                 </View>
                 </View>
              
                 </View>
             :
             <View style={{flexDirection:'row'}}>
             {/*   Requested by */}
             <View>
                 <View style={styles.signature}>
                 <Text style={styles.jobdescription}>Requested By</Text> 
                 </View>
                 <View style={styles.signature}>
                  {Ord.RequestedBySignature && 
                 <Image style={styles.SignaturePic} src={Ord.RequestedBySignature} />
                  }
                 </View>
                 <View style={styles.signature}>
                 <Text style={styles.jobdescription}>{Ord.RequestedByName}</Text>
                 </View>
            </View>
            </View>:
             Ord.Status==="Approved"  || Ord.Status==="Cleared"?
             <View style={{flexDirection:'row'}}>
             <View>
                 <View style={styles.signature}>
                 <Text style={styles.jobdescription}>Requested By</Text> 
                 </View>
                 <View style={styles.signature}>
                  {Ord.RequestedBySignature && 
                 <Image style={styles.SignaturePic} src={Ord.RequestedBySignature} />
                  }
                 </View>
                 <View style={styles.signature}>
                 <Text style={styles.jobdescription}>{Ord.RequestedByName}</Text>
                 </View>
            </View>
            <View style={{marginLeft:150}}>
                 <View style={styles.signature}>
                 <Text style={styles.jobdescription}>Approved By</Text> 
                 </View>
                 <View style={styles.signature}>
                  {Ord.ApprovedBySignature && 
                 <Image style={styles.SignaturePic} src={Ord.ApprovedBySignature} />
                  }
                 </View>
                 <View style={styles.signature}>
                 <Text style={styles.jobdescription}>{Ord.ApprovedByName}</Text>
                 </View>
            </View>
            </View>:
            Ord.Status==="Issued"?
            <View style={{flexDirection:'row'}}>
             <View>
                 <View style={styles.signature}>
                 <Text style={styles.jobdescription}>Requested By</Text> 
                 </View>
                 <View style={styles.signature}>
                  {Ord.RequestedBySignature && 
                 <Image style={styles.SignaturePic} src={Ord.RequestedBySignature} />
                  }
                 </View>
                 <View style={styles.signature}>
                 <Text style={styles.jobdescription}>{Ord.RequestedByName}</Text>
                 </View>
            </View>
            <View style={{marginLeft:100}}>
                 <View style={styles.signature}>
                 <Text style={styles.jobdescription}>Approved By</Text> 
                 </View>
                 <View style={styles.signature}>
                  {Ord.ApprovedBySignature && 
                 <Image style={styles.SignaturePic} src={Ord.ApprovedBySignature} />
                  }
                 </View>
                 <View style={styles.signature}>
                 <Text style={styles.jobdescription}>{Ord.ApprovedByName}</Text>
                 </View>
            </View>
            <View style={{marginLeft:100}}>
                 <View style={styles.signature}>
                 <Text style={styles.jobdescription}>Issued By</Text> 
                 </View>
                 <View style={styles.signature}>
                  {Ord.IssuedBySignature && 
                 <Image style={styles.SignaturePic} src={Ord.IssuedBySignature} />
                  }
                 </View>
                 <View style={styles.signature}>
                 <Text style={styles.jobdescription}>{Ord.IssuedByName}</Text>
                 </View>
            </View>
            </View>:
            <View style={{marginLeft:150}}>
            <View style={styles.signature}>
            <Text style={styles.jobdescription}>{Ord.Status==='Direct'?"Approved and Issued By":"Requested By"}</Text> 
            </View>
            <View style={styles.signature}>
             {Ord.RequestedBySignature && 
            <Image style={styles.SignaturePic} src={Ord.RequestedBySignature} />
             }
            </View>
            <View style={styles.signature}>
            <Text style={styles.jobdescription}>{Ord.RequestedByName}</Text>
            </View>
       </View>
      }
          <Text   style={styles.pageNumber} render={({ pageNumber, totalPages }) => ('Designed By ARMA IT Solution PLC. Mobile: 251-913-4040-58   Email: ArmaItSolution@gmail.com         Page: ' +
                        `${pageNumber} / ${totalPages}`
                    )}  fixed /> 
      </Page>
              )
      })
    }
    </Document>
  );
  const MySupplierDocument = (props) => (    
    <Document  style={{ width: "600px", height: "800px" }}>
      
      {props.Suppliers && (props.Suppliers!==null) && props.Suppliers.map(sup=>{
      return(
      <Page size="A4" style={styles.page} scale={2} key={sup.Id}>
        {globalUser.CompanyHeader &&
       <Image style={styles.logo} src={globalUser.CompanyHeader} />  
        }
       <View style={styles.row}></View>
        <View style={styles.titleContainer}>
          <Text style={styles.reportTitle}>{props.DocumentType}</Text>
        </View>
        <View style={styles.invoiceNoContainer}>
          <Text style={styles.label}>ID No:</Text>
          <Text style={styles.invoiceDate}>{sup.Id}</Text>
        </View>
        <View style={styles.invoiceDateContainer}>
          <Text style={styles.label}>Print Date:</Text>
          <Text style={styles.invoiceDate}>{format(new Date(),"yyyy-MM-dd")}</Text>
        </View>
<View style={{flexDirection:'row'}}>
  <View>
      
     
        <View style={styles.titleContainer}>
          <Text style={styles.label}>Name:</Text>
          <Text style={styles.label}>{sup.Name==='undefined'?'':sup.Name}</Text>
        </View>
        <View style={styles.titleContainer}>
          <Text style={styles.label}>Region:</Text>
          <Text style={styles.label}>{sup.Region==='undefined'?'':sup.Region}</Text>
        </View>
        <View style={styles.titleContainer}>
          <Text style={styles.label}>Mobile:</Text>
          <Text style={styles.label}>{sup.Mobile==='undefined'?'':sup.Mobile}</Text>
        </View>
        <View style={styles.titleContainer}>
          <Text style={styles.label}>Email:</Text>
          <Text style={styles.label}>{sup.Email==='undefined'?'':sup.Email}</Text>
        </View>
        </View>
        {sup.Logo && sup.logo!==null  && <View><Image style={{width:90,height:110,left:150,paddingTop:20}} src={sup.Logo} /></View>}
  </View>

        {/*   <InvoiceThankYouMsg /> */}
        <View style={styles.titleContainer}>
        <Text style={styles.reportFooter}>Thank you for your business</Text>
    </View>
      </Page>
              )
      })
    }
    </Document>
  );
  const Apps = (props) => (
   
    <PDFViewer style={{ width: "98%", height: "95%",flex:1}}>
      {(props.Type==='Invoice' || props.Type==="InvoiceReceving")?
      <MyVoucherDocument DocumentType={props.DocumentType}   Suppliers={props.Suppliers} LineItems={props.LineItems} Type={props.Type} />
      :props.Type==='JobOrder'?
      <MyJobOrderDocument  data={props.data} DocumentType={props.DocumentType}   jobLines={props.LineItems}  />:
      (props.Type==='IssueOrder' ||  props.Type==="TransferOrder" || props.Type==="IssueOrderIssuing")?
      <MyItemJournalDocument  data={props.data} DocumentType={props.DocumentType} Type={props.Type} jobLines={props.LineItems}  />:
      props.Type==='Supplier'?
      <MySupplierDocument Suppliers={props.Suppliers} DocumentType={props.DocumentType} />:
      props.Type==='Posting'?
      <MyPostingDocument data={props.data} DocumentType={props.DocumentType}/>:
      <MyPaymentDocument data={props.data} DocumentType={props.DocumentType}/>
    }
    </PDFViewer>
  );

  return (
    <div>
      <label onClick={() => setOpen(true)}>{props.icon}</label>
      <Dialog
        
        PaperProps={{
          sx: {
            width: "700px",
            height: "800px"
          }
        }}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent>
      <Apps Type={props.Type} DocumentType={props.DocumentType}  data={props.data} Suppliers={props.Suppliers} LineItems={props.LineItems}/>:

         </DialogContent>
    <DialogActions style={{
          backgroundColor: theme.palette.primary.main,
color:theme.palette.primary.contrastText,
color:theme.palette.primary.contrastText,  fontSize: "8px",
          }}>
          <Box>
            <Button variant="standard" endIcon={<Close />} onClick={()=>setOpen(false)}>
              Close              
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </div>
  );
}
