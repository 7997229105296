import { useState, useEffect,useContext } from "react";
import { Button, Autocomplete, TextField, Grid, RadioGroup,FormControlLabel,Radio,FormLabel } from "@mui/material";
import {Close, Delete, PaymentSharp, Preview, PreviewSharp} from "@mui/icons-material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useTranslation } from "react-i18next";
import { ShoppingCartContext } from "./Context/ShoppingCartContext";
import InvoicePreview from "./Reports/InvoicePreview";
import CheckPreview from "./Reports/CheckPreview";
import {format} from 'date-fns'
import axios from "../axiosInstances";
import { useTheme } from "@mui/material/styles";
export default function PaymentsModel(props) {
  const theme=useTheme();
  const { t, i18n } = useTranslation();

  const [open, setOpen] = useState(false);
  const { globalUser,globalProviders,globalCustomers,globalToken }=useContext(ShoppingCartContext)
  var fileObj = [];
  var fileArray = [];
  const [id, setId] = useState(props.data?props.data.Id:0);
  const [paidBy, setPaidBy] = useState(props.data?props.data.PaidBy:props.OrderData?props.Type==='Purchase'?props.OrderData.Customer:props.OrderData.Supplier:0);
  const [paidByName, setPaidByName] = useState(props.data?props.data.PaidByName:props.OrderData?props.Type==='Purchase'?props.OrderData.CustomerName:props.OrderData.Name:globalUser.ParentName);

  const [paidTo, setPaidTo] = useState(props.data?props.data.PaidTo:props.OrderData?props.Type==='Purchase'?props.OrderData.Supplier:props.OrderData.Customer:0);
  const [paidToName, setPaidToName] = useState(props.data?props.data.PaidToName:props.OrderData?props.Type==='Purchase'?props.OrderData.Name:props.OrderData.CustomerName:globalUser.ParentName);
  const [paidDate, setPaidDate] = useState(props.data?props.data.PaidDate:format(new Date(),'yyyy-MM-dd'));
  const [orderNo, setOrderNo] =useState(props.data?props.data.OrderNo:props.OrderData?props.OrderData.OrderId:0);
  const [amount, setAmount] =useState(props.data?props.data.TotalAmount?.replace(',',''):props.OrderData?(parseFloat(props.OrderData.TotalAmount?.replace(',',''))-parseFloat(props.OrderData.PaidAmount?.replace(',',''))).toString().replace(',',''):0);
  const [depositSlip, setDepositSlip] = useState(props.data?props.data.DepositSlip.split(','):[]);
 
  const [bankList] = useState(props.BankList);
  const [bank, setBank] = useState(props.data?props.data.bank:'');
  const [remark, setRemark] = useState(props.data?props.data.Remark:'');
  const [paymentType, setPaymentType] = useState(props.data?props.data.Type:'Deposit');

  const [PaidToPaidByList,setPaidToPaidByList]=useState(props.Type==='Sales'?globalCustomers:globalProviders)
 
  function uploadMultipleFiles(e) {
    fileObj.push(e.target.files);
    for (let i = 0; i < fileObj[0].length; i++) {
      fileArray.push(URL.createObjectURL(fileObj[0][i]));
    }
    setDepositSlip(fileArray);
  }
  const handleSubmit = (stat) => {
    
    if(paidTo===null || paidTo===0){
      alert('Paid to field is required');
      return;
    }
    if(amount===null || amount<=0){
      alert('Amount field is required');
      return;
    }
    if(paymentType==='Deposit'){
      if(depositSlip===undefined || depositSlip===null || depositSlip.length<=0){
        alert('Attachment field is required');
        return;
      }
    }//check wallet balance here in else when wallet and process telbir here too
    if (
      window.confirm(
        "Are you sure you want to pay?"
      )
    ) {
    try {
      var fd = new FormData();
      fd.append("Id", id);
      fd.append("PaidBy", paidBy);
      fd.append("PaidTo", paidTo);
      fd.append("OrderNo", orderNo);
        fd.append("Amount", amount);
        fd.append("PaidDate", paidDate);
       
        Array.from(document.getElementById("DepositSlip").files).map((file) => {
         fd.append("DepositSlip", file);
        });
        fd.append("Type", paymentType);
        fd.append("Status", stat);
        fd.append("User", globalUser.Id);
        fd.append("Remark", remark);
       
      axios.post("/payments", fd, {
        headers: { "Content-Type": "multipart/form-data", 
            token: globalToken,
          },
      });
    } catch (err) {
    } finally {
      if(props.data){
        props.OrderData && props.setParentUpdate(!props.ParentUpdate);
      }      
      setOpen(false);
    }
  }
  };
  const handleDelete = () => {
    try {
      axios.delete("/stores?Id=" + id, {
        headers: {
          token: globalToken,
        },
      });
    } catch (err) {
    } finally {
      setOpen(false);
    }
  };

  return (
    <div width="500">
      <label onClick={() => setOpen(true)}>{props.icon}</label>
      <Dialog
      open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="responsive-dialog-title"
      >
     
        <DialogTitle bgcolor={theme.palette.primary.main} color={theme.palette.primary.contrastText}>{props.Title} </DialogTitle>
        <DialogContent>
          <DialogContentText>
         
              <hr></hr>

              <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                  <TextField
                  disabled={true}
                    size="small"
                    variant="standard"
                    label={props.Type==="Sales"? t("Paid To"):t("Paid By")}
                    defaultValue={paidByName}
                    onChange={(e) => {
                      setPaidByName(e.target.value);
                    }}
                    sx={{ width: "95%", marginBottom: 1 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  </Grid>
            
               
                  <Grid item xs={12} md={6}>
                  <Autocomplete
                        disablePortal
                        selectOnFocus
                        autoHighlight
                        defaultValue={paidToName}
                        id="Paidto"
                        onSelect={(e) => {
                          setPaidToName(e.target.value);
                          //setPaidTo(globalCustomers && globalCustomers.filter(gp=>gp.Name===e.target.value).length>0?globalCustomers.filter(gp=>gp.Name===e.target.value)[0].Id:0)
                        }}
                        options={PaidToPaidByList && PaidToPaidByList.length>0 && PaidToPaidByList.map((item) => {
                             return item.Name;
                        })}
                        sx={{
                          width: "100%",
                          marginBottom: 2,
                          borderRadius: "25%",
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={props.Type==="Sales"?t("Paid By"):t("Paid To")}
                            variant="standard"
                            //error={!fromAirport ? true : false}
                          />
                        )}
                      /> 
  </Grid>
  <Grid item xs={12} md={6}>
                  <TextField
                    size="small"
                    type='date'
                    variant="standard"
                    label={t("Paid Date")}
                    defaultValue={paidDate}
                    onChange={(e) => {
                      setPaidDate(e.target.value);
                    }}
                    sx={{ width: "95%", marginBottom: 1 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  </Grid>
  <Grid item xs={12} md={6}>
  <TextField
                    size="small"
                    type='number'
                    variant="standard"
                    label={t("Amount")}
                    defaultValue={amount?.toLocaleString(undefined, {minimumFractionDigits:2,maximumFractionDigits:2})}
                    onChange={(e) => {
                      setAmount(e.target.value);
                    }}
                    sx={{ width: "95%", marginBottom: 1 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
  </Grid>
  <Grid item xs={12} md={12}>
  <FormLabel id="demo-controlled-radio-buttons-group">Payment Type</FormLabel>
  <RadioGroup
    sx={{display: 'flex',flexDirection:'row'}}
    size="small"
    name="controlled-radio-buttons-group"
    value={paymentType}
    onChange={(e)=>(setPaymentType(e.target.value))}
  >
    <FormControlLabel  value="Deposit" control={<Radio size="small" />} label="Deposit" />
    <FormControlLabel  value="Cash" control={<Radio size="small" />} label="Cash" />
    <FormControlLabel value="Wallet" control={<Radio size="small" />} label="Wallet" />
     <FormControlLabel value="Telebir" control={<Radio size="small" />} label="Telebir" />
  </RadioGroup>
  </Grid>
  <Grid item xs={12} md={6}>
   <Autocomplete
                        disablePortal
                        selectOnFocus
                        autoHighlight
                        defaultValue={bank}
                        id="bank"
                        onSelect={(e) => {
                          setBank(e.target.value);                          
                        }}
                        options={bankList &&  !(bankList===null) && bankList.length>0 && bankList.map((item) => {
                             return item.Description;
                        })}
                        sx={{
                          width: "100%",
                          marginBottom: 2,
                          borderRadius: "25%",
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Bank"
                            variant="standard"
                            //error={!fromAirport ? true : false}
                          />
                        )}
                      /> <br></br>
                      Order No: {orderNo}

  </Grid>
  <Grid item xs={12} md={6}>
  <TextField
                    size="small"
                    multiline
                    variant="standard"
                    label={t("Remark")}
                    defaultValue={remark}
                    onChange={(e) => {
                      setRemark(e.target.value);
                    }}
                    sx={{ width: "95%", marginBottom: 1 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
  </Grid>
               
                  <Grid item xs={12} style={{backgroundColor: theme.palette.primary.main,color:theme.palette.primary.contrastText,cursor:'pointer'}} >
                <label htmlFor="DepositSlip">
                  Attachment
                  <input
                    type="file"
                    id="DepositSlip"
                    multiple
                    onChange={uploadMultipleFiles}
                    hidden
                  />
                  <div className="form-group multi-preview">
                    {(depositSlip || []).map((address, index) => (
                      <img
                        key={index}
                        src={address}
                        alt="..."
                        style={{ width: "100%", height: "100" }}
                      />
                    ))}
                  </div>
                </label>
              </Grid>
              </Grid>
           
          </DialogContentText>
        </DialogContent>
         <DialogActions  style={{ backgroundColor: theme.palette.primary.main,color:theme.palette.primary.contrastText,height:'20px' }}>
        <Button
        variant="standard"
          endIcon={<PreviewSharp />}
          >
                   <CheckPreview 
                    icon={<div>Check</div>}
                        data={
                        {   
                          "Id":id,
                          "PaidByName": paidByName,
                        "PaidToName": paidToName,
                          "Amount": amount,
                          "PaidDate": paidDate,
                          "Type": paymentType,
                          "Status": props.data?props.data.Status:'Send',
                        }
                        }
                     
                        />
                    </Button>|
        <Button
        variant="standard"
          endIcon={<PreviewSharp />}
          >
                   <InvoicePreview 
                    icon={<div>Preview</div>}
                        data={
                        {   
                          "Id":id,
                          "PaidBy": paidByName,
                        "PaidTo": paidToName,
                          "Amount": amount,
                          "PaidDate": paidDate,
                          "Type": paymentType,
                          "Status": props.data?props.data.Status:'Send',
                        }
                        }
                        Type="Payment"
                        DocumentType={props.Type + " Payment"}
                        />
                    </Button>|
          <Button variant="standard" autoFocus onClick={()=>handleSubmit('Paid')} endIcon={<PaymentSharp />}>
            Paid
          </Button>|
          <Button variant="standard" autoFocus onClick={handleDelete} endIcon={<Delete />}>
            Delete
          </Button>|
          <Button variant="standard" onClick={() => setOpen(false)} endIcon={<Close />} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
