import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Typography,
  Avatar,
  Grid,
       IconButton,
       Button,
    Box,
    Tooltip,
  } from "@mui/material";
  import {
    EditSharp,
    FileDownload,
    RefreshSharp,
  } from "@mui/icons-material";
  import MaterialReactTable from "material-react-table";
  import { useState, useEffect, useMemo, useContext } from "react";
  import SecurityModel from "./SecurityModel";
  import * as XLSX from "xlsx";
  import axios from "../../axiosInstances";
  import { useTranslation } from "react-i18next";
import { ShoppingCartContext } from "../Context/ShoppingCartContext"
import { useTheme } from "@mui/material/styles";
import { useNavigate } from 'react-router-dom'

  function SecurityComp(props) {
    const navigate = useNavigate();
    const theme=useTheme();
    const { t, i18n } = useTranslation();
    const [update, setUpdate] = useState(false);
    const [SecurityList, setSecurityList] = useState([]);
    const { setPageHeader,globalUser,globalToken } = useContext(ShoppingCartContext);
    const [rolesList,setRolesList]=useState([]);
    const [functionalitiesList,setFunctionalitiesList]=useState([])

    const columns = useMemo(
      () => [
       
       /*  {
          header: "Id",
          accessorKey: "Id",
          size:100
                  }, */
                  {
                    header: "Department",
                    accessorKey: "Department", 
                    size:150
                  },
                  {
                    header: "Store",
                    accessorKey: "StoreName", 
                    size:150
                  },
        {
          header: "Role",
          accessorKey: "Role", 
          size:120
        },
        {
          header: "Functionality",
          accessorKey: "Functionality", 
        },
        {
          header: "Read",
          accessorKey: "Read", 
          size:100
        },
        {
          header: "Modify",
          accessorKey: "Modify", 
          size:100
        },
        {
          header: "Create",
          accessorKey: "Create", 
          size:100 ,
        },
        {
          header: "Remark",
          accessorKey: "Remark", 
        },
      
      ],
 
      []
    );
 
    const loadSecurityList =  () => {
     axios.get("/Security?Parent="+ globalUser.Parent, {
      headers: {
        token: globalToken,
      },
    }).then(response=>{
      
          if (response.data.data){
           setSecurityList(response.data.data.Security);
           setRolesList(response.data.data.Roles);
           setFunctionalitiesList(response.data.data.Functionalities);
         
          }else{
            alert('log out and log in again')
          }
        })
        
      };

  
    const handleUploadFile = async (e) => {
      const file = e.target.files[0];
      const data = await file.arrayBuffer();
      const workbook = XLSX.read(data);
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      var jsonData = XLSX.utils.sheet_to_json(worksheet);
  
      var fdata = new FormData();
      fdata.append("JsonData", JSON.stringify(jsonData));
      await axios.post("/Security/Excel", fdata, {
        headers: { "Content-Type": "application/json" , 
            token: globalToken,
         },
      });
    };
    function handleDownloadFile() {
      const worksheet = XLSX.utils.json_to_sheet(SecurityList);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "supports");
      //let buf = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
      XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(workbook, "supportsdata.xlsx");
    }
    
  
  
  
    useEffect(() => {
      if(!globalUser){
        navigate('/');
        return;
      }
      loadSecurityList();
      setPageHeader('Security')
    }, [update]);
    return (
      <div>
      {rolesList && rolesList.length>0 && functionalitiesList && functionalitiesList.length>0 &&
      <Grid container>
          

          <Grid item className="hideInMobile" m={1}>
      
            <MaterialReactTable
            title="Abdulmejid table"
              columns={columns}
              data={SecurityList}
               initialState={{ 
                density: "compact",
                pagination:{
                  pageIndex:0,
                  pageSize:12,
                   }
                   , grouping: ['Role','Department'] 
              }}
              enableGlobalFilter
             enableGrouping
              //enableRowSelection //enable some features
              enableColumnOrdering
              enableColumnResizing
              //enableRowNumbers
                muiTopToolbarProps= {{
                sx: {backgroundColor: theme.palette.primary.main,color:theme.palette.primary.contrastText}
              }}
              muiBottomToolbarProps={{
                sx: {backgroundColor: theme.palette.primary.main,color:theme.palette.primary.contrastText}
              }}
              renderTopToolbarCustomActions={() => (
                <Box>
                  <Tooltip title={t("download")}>
                  <Button       size="small"
                    variant="standard" endIcon={<FileDownload style={{ width: "20px", height: "20px" }} />} type="file" onClick={handleDownloadFile}>
                    Download
                  </Button>
                 </Tooltip>|
                 <Tooltip title={t("refresh")}>
                    <Button       size="small"
                    variant="standard" endIcon={<RefreshSharp />} onClick={() => setUpdate(!update)}>
                      Refresh
                    </Button>
                  </Tooltip>
                </Box>
              )}
              enableRowActions
              renderRowActions={(table) => (
                <Box>
                  <Tooltip title={t("modify")}>                  
                  <IconButton > 
                   <SecurityModel
                  icon={<EditSharp style={{ width: "20px", height: "20px" }} />}
                  data={table.row.original}
                  Title="EDIT SECURITY"
                  Roles={rolesList}
                  SecurityList={SecurityList}
                  setParentUpdate={setUpdate}
                  ParentUpdate={update}
                />   
                  </IconButton>
                  </Tooltip>

                </Box>
              )}
            ></MaterialReactTable> 
        </Grid>
        <Grid item xs={12} marginTop={1} className="hideInFull">
        <Box sx={{  width: "100%",backgroundColor: theme.palette.primary.main,color: theme.palette.primary.contrastText}}>

                  <Tooltip title={t("download")}>
                  <Button       size="small"
                    variant="standard" endIcon={<FileDownload style={{ width: "20px", height: "20px" }} />} type="file" onClick={handleDownloadFile}>
                    Download
                  </Button>
                 </Tooltip>|
                 <Tooltip title={t("refresh")}>
                    <Button       size="small"
                    variant="standard" endIcon={<RefreshSharp />} onClick={() => setUpdate(!update)}>
                      Refresh
                    </Button>
                  </Tooltip>
                </Box>
        </Grid>
          {!(SecurityList === undefined) &&
          !(SecurityList === null) &&
            SecurityList.length > 0 &&
            SecurityList.map((item) => {
              return (
                <Grid item key={item.Id} xs={5} m={2} className="hideInFull">
                   <Card sx={{ borderRadius: 10, boxShadow: 3,width:"100%"}} >
                    <CardHeader
                      avatar={
                        <Avatar
                          src={
                            item.Picture != null && item.Picture.length > 0
                              ? item.Picture.split(",")[0]
                              : "/Images/Products/default.png"
                          }
                        />
                      }
                      className="CardHeader"
                      title={"Role: "+item.Role} //.substring(0, 20)}
                     
                    />

                    <CardContent>
                    <Typography variant="body2" align="left">
                    Functionality: {item.Functionality}
                      </Typography>
                    <Typography variant="body2" align="left">
                       Store: {item.StoreName}
                      </Typography>
                    <Typography variant="body2" align="left">
                       Read: {item.Read?'Allowed':'Forbidden'}
                      </Typography>
                      <Typography variant="body2" align="left">
                       Modify: {item.Modify?'Allowed':'Forbidden'}
                      </Typography>
                     
                      <Typography variant="body2" align="left">
                        Create: {item.Create?'Allowed':'Forbidden'}
                      </Typography>
                    </CardContent>
                    <CardActions
                    sx={{
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.primary.contrastText,
                      }}>
                      <Box>
                        <Tooltip title="Edit Payment">
                          <IconButton>
                            <SecurityModel
                              icon={
                                <EditSharp
                                  style={{ width: "20px", height: "20px" }}
                                />
                              }
                              data={item}
                              Title="EDIT STOCK"
                              Roles={rolesList}
                              SecurityList={SecurityList}
                              setParentUpdate={setUpdate}
                              ParentUpdate={update}
                            />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </CardActions>
                  </Card>
                </Grid>
              );
            })}
        </Grid>
          }
          </div>
    );
  }
  
  export default SecurityComp;
  