import { useState, useEffect, useContext } from "react";
import {
  Autocomplete,
  Button,
  Grid,
  IconButton,
  Table,
  TextField,
  Tooltip,
  Card,
  CardContent,
} from "@mui/material";
import {
  Close,
  Delete,
  Add,
  ContentPasteOffSharp,
  Troubleshoot,
  PropaneSharp,
  Preview,
  PreviewSharp,
  RequestQuoteSharp,
  ApprovalSharp,
  DeliveryDiningSharp,
  ReceiptSharp,
} from "@mui/icons-material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import InvoicePreview from "../Reports/InvoicePreview";

import { ShoppingCartContext } from "../Context/ShoppingCartContext";

import { format } from "date-fns";
import axios from "../../axiosInstances";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";

export default function ItemTransferIssueModel(props) {
  const theme=useTheme();
  var fileObj = [];
  var fileArray = [];
  const [open, setOpen] = useState(false);
  const { globalUser, allItems,globalToken,globalRoleSecurity } = useContext(ShoppingCartContext);
  const { t, i18n } = useTranslation();
  const [orderDate, setOrderDate] = useState(props.TransferOrder.IssuedDate);
  
  const [transferIssuedlines, setTransferIssuedlines] = useState(props.ItemTransferIssuedLines);
    const [transferlines, setTransferlines] = useState([])
 
  const [transferOrder, settransferOrder] = useState(props.TransferOrder);
 const [remark, setRemark] = useState(props.data ? props.data.Remark : "");

  const [productsList] = useState(allItems);

  const handleReceipt = () => {
   try {
      axios.get("/itemTransfers/changeToReceipt?Id=" + props.TransferOrder.Id + "&ReceivedBy=" + globalUser.Id + "&ReceivedDate=" + orderDate, {
        headers: {
          token: globalToken,
        },
      }); 
    } catch (err) {
    } finally {
      props.setParentUpdate(!props.ParentUpdate);
      setOpen(false);
    }
  };
  const handleSubmit = (status, lItems) => {

    
    if (
      lItems.filter(li=>li.Order===transferOrder.Id) === undefined ||
      lItems.filter(li=>li.Order===transferOrder.Id).filter((li) => li.Name.length > 0).length <= 0
    ) {
      alert("First select the items" );
      return;
    }
 

    lItems.filter(li=>li.Order===transferOrder.Id).map((li) => {
      if (isNaN(li.Quantity) || li.Quantity <= 0) {
        alert("First insert the proper quantity for product: " + li.Name);
         return;
      }
    });
    if (transferOrder.From === undefined || transferOrder.From === null || transferOrder.From===0) {
      alert("First select store from");
      return;
    }
    if (transferOrder.To === undefined || transferOrder.To === null || transferOrder.To===0) {
      alert("First select store to");
      return;
    }
    if (transferOrder.From==transferOrder.To) {
      alert("The source and destination stores must be differet");
      return;
    }
    if (
      window.confirm(
        "are you sure you want to save?"
      )
    ) {
    try {
        
            var fd = new FormData();
            fd.append("Id", 0);
            fd.append("Order", transferOrder.Id);
            fd.append("From", transferOrder.From);
            fd.append("To", transferOrder.To);
            fd.append("RequestedBy", transferOrder.RequestedBy?transferOrder.RequestedBy:globalUser.Id);
            fd.append("RequestedDate",transferOrder.RequestedDate? transferOrder.RequestedDate : format(new Date(), "yyyy-MM-dd"));
            fd.append("ApprovedBy", transferOrder.ApprovedBy?transferOrder.ApprovedBy:globalUser.Id);
            fd.append("ApprovedDate",transferOrder.ApprovedDate? transferOrder.ApprovedDate : format(new Date(), "yyyy-MM-dd"));
            fd.append("IssuedBy", transferOrder.IssuedBy?transferOrder.IssuedBy:globalUser.Id);
            fd.append("IssuedDate",transferOrder.IssuedDate? transferOrder.IssuedDate : format(new Date(), "yyyy-MM-dd"));
            fd.append("ReceivedBy", transferOrder.ReceivedBy?transferOrder.ReceivedBy:globalUser.Id);
            fd.append("ReceivedDate",transferOrder.ReceivedDate? transferOrder.ReceivedDate : format(new Date(), "yyyy-MM-dd"));
            fd.append("Status", status);
            fd.append("Parent", globalUser.Parent);            
            fd.append("Remark", transferOrder.Remark);
            fd.append("TransferLines",JSON.stringify(
              transferlines.map(tl=>{
                tl.Id=0;
                tl.Quantity=tl.ReceivingQuantity;
                return tl;
              })));
 
            axios
              .post("/itemtransfers", fd, {
                headers: { "Content-Type": "application/json" , 
                    token: globalToken,
                 },
              })
              .then((res) => {
                if (!(props.order && !(props.order === null))) {
                }
              });          
      
    } catch (err) {
    } finally {
     
      props.data && props.setParentUpdate(!props.ParentUpdate);
      setOpen(false);
    }
  }
  };
  
  const handleLineItemDelete = (id) => {
    try {
      axios.delete("/lineitems?Id=" + id, {
        headers: {
          token: globalToken,
        },
      }); //add id
    } catch (err) {
    } finally {
    }
  };
  useEffect(()=>{
    setTransferlines(props.ItemTransferLines.map(itl=>{
      var issuedQty=0;
      transferIssuedlines.filter(til=>(til.Item===itl.Item)).map(til=>{
        issuedQty=parseFloat(issuedQty)+parseFloat(til.Quantity)
      })
      itl.IssuedQuantity=issuedQty;
      itl.ReceivingQuantity=parseFloat(itl.Quantity)-issuedQty;
      return itl;
    }))
    },[])

  return (
    <div>
      <label
        onClick={() => {
          setOpen(true);
        }}
      >
        {props.icon}
      </label>

      <Dialog
      open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <DialogContent>
                <DialogContentText mt={1}>
                  <DialogTitle
                    style={{
                     backgroundColor: theme.palette.primary.main,
color:theme.palette.primary.contrastText,
                      height: "15px",
                    }}
                   
                  >
                     {"From: " + transferOrder.FromStoreName + " -->   To: " + transferOrder.ToStoreName}
                  </DialogTitle>
                  <div>
                    <Grid container>
                    <Grid
                        item
                        xs={12}
                        pl={'35%'}
                        sx={{
                          backgroundColor: theme.palette.primary.dark,
                         textDecoration:'underline',
                         fontWeight:'bold',
                        
                       }}
                      >
                         {props.TransferOrder.Status==='Issued'?
                        "Transfer Issued":"Transfer Receipt"}
                      </Grid>
                      <Grid item xs={6}>
                        <span>Doc. No.: {transferOrder.Id}</span><br></br>
                        <span>Status: {transferOrder.Status}</span>
                      </Grid>
                      <Grid item xs={6} mt={1}>
                        <TextField
                          size="small"
                          label= {props.TransferOrder.Status==='Issued'?"Receipt Date":"Issue Date"}
                          type="date"
                          value={orderDate}
                          onChange={(e) => {
                            setOrderDate(e.target.value);
                            props.TransferOrder.Status==='Issued'?
                            transferOrder.ReceivedDate=e.target.value:
                            transferOrder.IssuedDate=e.target.value
                          }}
                          sx={{ width: "70%", marginBottom: 2 }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      
                      <Grid item xs={6}>
                        Requested By: {transferOrder.IssuedToName ? transferOrder.RequestedByName? transferOrder.RequestedByName: globalUser.Name:globalUser.Name}<br></br>
                        Approved By: {transferOrder.ApprovedByName}<br></br>
                        </Grid>
                        <Grid item xs={6} mt={1}>
                        From: {transferOrder.FromStoreName}<br></br>
                        To: {transferOrder.ToStoreName}
                      </Grid>
                      <Grid item xs={6}>
                        Issued By: {transferOrder.IssuedByName}<br></br>
                       {/*  Received By: {transferOrder.ReceivedByName}<br></br> */}
                      </Grid>
                      
                     

                      <Grid item xs={12}>
                        <TextField
                          size="small"
                          variant="standard"
                          value={transferOrder.Remark}
                          label="Remark"
                          minRows={2}
                          sx={{ width: "95%" }}
                          onChange={(e) => {
                            transferOrder.Remark = e.target.value;
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <Grid
                          direction="row"
                          container
                        >
                          <hr></hr>
                          <Grid item xs={12} className="hideInMobile">
                            <Grid container>
                              <Grid
                                item
                                xs={1}
                                style={{
                                  paddingLeft: "5px",
                                 backgroundColor: theme.palette.primary.main,
color:theme.palette.primary.contrastText,
                                  color:theme.palette.primary.contrastText,
                                }}
                              >
                                No.
                              </Grid>
                              <Grid
                                item
                                xs={3}
                                style={{
                                  paddingLeft: "5px",
                                 backgroundColor: theme.palette.primary.main,
color:theme.palette.primary.contrastText,
                                  color:theme.palette.primary.contrastText,
                                }}
                              >
                                {" "}
                                Item Name
                              </Grid>
                              <Grid
                                item
                                xs={2}
                                style={{
                                  paddingLeft: "5px",
                                 backgroundColor: theme.palette.primary.main,
color:theme.palette.primary.contrastText,
                                  color:theme.palette.primary.contrastText,
                                }}
                              >
                                Order Quantity
                              </Grid>
                              <Grid
                                item
                                xs={2}
                                style={{
                                  paddingLeft: "5px",
                                 backgroundColor: theme.palette.primary.main,
color:theme.palette.primary.contrastText,
                                  color:theme.palette.primary.contrastText,
                                }}
                              >
                                Issued Quantity
                              </Grid>
                              <Grid
                                item
                                xs={2}
                                style={{
                                  paddingLeft: "5px",
                                 backgroundColor: theme.palette.primary.main,
color:theme.palette.primary.contrastText,
                                  color:theme.palette.primary.contrastText,
                                }}
                              >
                                Quantity
                              </Grid>

                              <Grid
                                item
                                xs={2}
                                style={{
                                  paddingLeft: "5px",
                                 backgroundColor: theme.palette.primary.main,
color:theme.palette.primary.contrastText,
                                  color:theme.palette.primary.contrastText,
                                  textAlign: "center",
                                }}
                              >
                                Remark
                              </Grid>
                           

                              {transferlines &&
                                transferlines !== null &&
                                transferlines.length > 0 &&
                                /*  transferlines.map(jl=>{
              var issuedQuantity=issuedQuantity + jl.Quantity;
            }) && */
                                transferlines
                                  .filter(
                                    (it) =>
                                      parseInt(it.Order) === parseInt(transferOrder.Id)
                                  )
                                  .map((item, index) => {
                                    /*         
                  var issuedQuantity=item.Quantity; */
                                    return (
                                      <>
                                        <Grid item xs={12}>
                                          <hr></hr>
                                        </Grid>
                                        <Grid item xs={1} key={index}>
                                          {index + 1}
                                        </Grid>
                                        <Grid item xs={3}>
                                        {item.Name}
                                        </Grid>
                                        <Grid item xs={2}>
                                          {item.Quantity}
                                          </Grid>
                                          <Grid item xs={2}>
                                        {item.IssuedQuantity} 
                                          </Grid>
                                        <Grid item xs={2}>
                                        {props.TransferOrder.Status==='Issued'?
                                        item.ReceivingQuantity:
                                          <TextField
                                            size="small"
                                            variant="standard"
                                            type="float"
                                            defaultValue={
                                              item.ReceivingQuantity /* (lineItems && lineItems.filter(li=>li.Item===item.Item).length>0?lineItems.filter(li=>li.Item===item.Item)[0].Quantity:0)- issuedQuantity */
                                            }
                                            sx={{ width: "70px" }}
                                            onChange={(e) => {
                                              if((parseFloat(item.Quantity)-parseFloat(item.IssuedQuantity)-parseFloat(e.target.value))<0){
                                                item.ReceivingQuantity = (parseFloat(item.Quantity)-parseFloat(item.IssuedQuantity));
                                                }else{
                                                item.ReceivingQuantity = e.target.value;
                                                }  
                                                                                       
                                              setTransferlines(
                                                transferlines.map((litem) => {
                                                  return litem;
                                                })
                                              );
                                            }}
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                          />
                                  }
                                        </Grid>

                                        <Grid item xs={2}>
                                        {props.TransferOrder.Status==='Issued'?
                                        item.Remark:
                                          <TextField
                                            size="small"
                                            variant="standard"
                                            value={item.Remark}
                                            sx={{ width: "95%" }}
                                            onChange={(e) => {
                                              item.Remark = e.target.value;
                                            }}
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                          />}
                                        </Grid>
                                      
                                      </>
                                    );
                                  })}
                              <Grid item xs={12}>
                                <hr></hr>
                              </Grid>
                              <Grid item xs={5}></Grid>
                            </Grid>
                          </Grid>
                          <Grid item className="hideInFull" m={1} xs={12}>
                            {transferlines &&
                              transferlines !== null &&
                              transferlines.length > 0 &&
                              transferlines
                                /* .filter(
                (it) =>
                  parseInt(it.IssuedToName) === parseInt(transferOrder.IssuedToName)
              ) */
                                .map((item, index) => {
                                  return (
                                    <>
                                      <Card sx={{ borderRadius: 10, boxShadow: 3,width:"100%"}} >
                                        <CardContent>
                                          <Grid container>
                                            <Grid item xs={4}>
                                              No.:
                                            </Grid>
                                            <Grid item xs={8} pl={0}>
                                              {index + 1}
                                            </Grid>
                                            <Grid item xs={4}>
                                              Product:
                                            </Grid>
                                            <Grid item xs={8} pl={0}>
                                              <Autocomplete
                                                label="Items"
                                                id="Items"
                                                size="small"
                                                variant="standard"
                                                value={item.Name}
                                                onSelect={(e) => {
                                                  if (
                                                    transferlines.filter(
                                                      (li) =>
                                                        li.Name ===
                                                        e.target.value
                                                    ).length > 0
                                                  ) {
                                                    item.Item = "";
                                                    item.Name = "";
                                                    item.Quantity = 0;
                                                    //item.IssuedTo = transferOrder.AssignTo;

                                                    setTransferlines(
                                                      transferlines.map(
                                                        (litem) => {
                                                          return litem;
                                                        }
                                                      )
                                                    );

                                                    return;
                                                  } else {
                                                    let selectedProduct =
                                                      productsList.filter(
                                                        (selItem) =>
                                                          selItem.Name ===
                                                          e.target.value
                                                      );
                                                    if (
                                                      selectedProduct &&
                                                      selectedProduct.length > 0
                                                    ) {
                                                      item.Quantity =
                                                        selectedProduct[0].DefaultOrderQty;

                                                      item.Item =
                                                        selectedProduct[0].Item;
                                                      item.Name =
                                                        selectedProduct[0].Name;
                                                      //item.IssuedTo = transferOrder.AssignTo;

                                                      setTransferlines(
                                                        transferlines.map(
                                                          (litem) => {
                                                            return litem;
                                                          }
                                                        )
                                                      );
                                                    } else {
                                                      item.Quantity = 0;

                                                      item.Item = "";
                                                      item.Name = "";
                                                      // item.IssuedTo = transferOrder.AssignTo;
                                                      setTransferlines(
                                                        transferlines.map(
                                                          (litem) => {
                                                            return litem;
                                                          }
                                                        )
                                                      );
                                                    }
                                                  }
                                                }}
                                                options={
                                                  !(
                                                    productsList === undefined
                                                  ) &&
                                                  !(productsList === null) &&
                                                  productsList.length > 0
                                                    ? productsList.map((it) => {
                                                        return it.Name;
                                                      })
                                                    : [""]
                                                }
                                                sx={{ width: "100%" }}
                                                renderInput={(params) => (
                                                  <TextField
                                                    {...params}
                                                    label={item.Name}

                                                    //error={!from ? true : false}
                                                  />
                                                )}
                                              />
                                            </Grid>
                                            <Grid item xs={4}>
                                              Quantity:
                                            </Grid>
                                            <Grid item xs={8} pl={0}>
                                              <TextField
                                                size="small"
                                                variant="standard"
                                                type="float"
                                                value={item.Quantity}
                                                maxRows={4}
                                                sx={{ width: "100%" }}
                                                onChange={(e) => {
                                                  item.Quantity =
                                                    e.target.value;
                                                  setTransferlines(
                                                    transferlines.map(
                                                      (litem) => {
                                                        return litem;
                                                      }
                                                    )
                                                  );
                                                }}
                                                InputLabelProps={{
                                                  shrink: true,
                                                }}
                                              />
                                            </Grid>

                                            <Grid item xs={4}>
                                              Remark :
                                            </Grid>
                                            <Grid item xs={8} pl={8}>
                                              {item.Remark}
                                            </Grid>
                                            <Grid item xs={12}>
                                              <hr></hr>
                                              Remove: {"   "}
                                              <Tooltip title="remove">
                                                <Button
                                                  endIcon={
                                                    <Delete width="10px" />
                                                  }
                                                  onClick={() => {
                                                    handleLineItemDelete(
                                                      transferlines.filter(
                                                        (litem) =>
                                                          litem.Item ===
                                                          item.Item
                                                      )[0].Id
                                                    );

                                                    if (
                                                      transferlines.filter(
                                                        (litem) =>
                                                          !(
                                                            litem.Item ===
                                                            item.Item
                                                          )
                                                      ).length <= 0
                                                    ) {
                                                      let newLineItem = {};
                                                      newLineItem.Item = "";
                                                      newLineItem.Name = "";
                                                      //newLineItem.IssuedTo = transferOrder.AssignTo;
                                                      //newLineItem.IssuedToName = transferOrder.IssuedToName;
                                                      newLineItem.Quantity = 0;

                                                      setTransferlines((xx) => [
                                                        ...xx,
                                                        newLineItem,
                                                      ]);
                                                    }
                                                  }}
                                                ></Button>
                                              </Tooltip>
                                            </Grid>
                                          </Grid>
                                        </CardContent>
                                      </Card>
                                      <hr></hr>
                                    </>
                                  );
                                })}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                </DialogContentText>
            
        </DialogContent>
        <DialogActions
          style={{
          backgroundColor: theme.palette.primary.main,
color:theme.palette.primary.contrastText,
color:theme.palette.primary.contrastText,  fontSize: "8px",
          }}
        >
          <hr></hr>
          <Button  endIcon={<PreviewSharp />}>
            <InvoicePreview
              icon={
                <div style={{ fontSize: "10px"}}>Preview</div>
              }
              data={[transferOrder]}
              LineItems={transferlines}
              Type="TransferOrder"
              DocumentType="Transfer Issue"
            />
          </Button>
          |
         {props.TransferOrder.Status==='Approved' && globalRoleSecurity.filter(gs=>((gs.Functionality===('Transfer Issue'))  && gs.Security>1)).length>0 &&
          <>
          <Button
            style={{ fontSize: "10px"}}
           endIcon={<DeliveryDiningSharp />} 
            autoFocus
            onClick={() =>
              handleSubmit(
                "Issued",
                transferlines.filter((itt) => itt.Name && itt.Name.length > 0)
              )
            }
          >
            Issue
          </Button>
          |
          </>
}
{props.TransferOrder.Status==='Issued' && globalRoleSecurity.filter(gs=>((gs.Functionality===('Transfer Receipt'))  && gs.Security>1)).length>0 &&
          <>
          <Button
            style={{ fontSize: "10px",}}
            endIcon={<ReceiptSharp />} 
            autoFocus
            onClick={() =>
              handleReceipt()
            }
          >
            Receive
          </Button>
         
          |
          </>
}
          <Button
            style={{ fontSize: "10px" }}
            /* endIcon={<Close />} */
            sx={{ alignSelf: "end" }}
            onClick={() => setOpen(false)}
          >
            close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
