import { Grid,TextField,Table,td, TableBody, Button,Box } from "@mui/material";
import { AddSharp } from "@mui/icons-material";
import * as React from "react";
import {format} from 'date-fns'
import axios from "../axiosInstances";
import { useState, useEffect, useContext,useMemo } from "react";
import { BarChart,Bar,CartesianGrid,XAxis,YAxis,Legend,Tooltip, LineChart,Line } from "recharts";
import FullCharts from "./FullCharts"
import { ShoppingCartContext } from "./Context/ShoppingCartContext";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from 'react-router-dom'
import { useTranslation } from "react-i18next";
function HomeComp() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const theme=useTheme();
  const {globalUser,globalToken,setPageHeader,globalSetting } =    useContext(ShoppingCartContext);
   var currentDate=new Date()
  const [startDate,setStartDate]=useState(format(currentDate.setDate(currentDate.getDate()-parseInt(globalSetting?.filter(gs=>gs.Description==="Home Page days Range").length ?globalSetting?.filter(gs=>gs.Description==="Home Page days Range")[0].Value:30
  )),"yyyy-MM-dd"))
  const [endDate,setEndDate]=useState(format(new Date(),"yyyy-MM-dd"))
  const [mainData, setMainData] = useState([]);
  const [mainDataType, setMainDataType] = useState('sales');
  const [cashFlowData, setCashFlowData] = useState([]);
  const [SalesQuantitySummary, setSalesQuantitySummary] = useState([]);

  const [PurchaseQuantitySummary, setPurchaseQuantitySummary] = useState([]);
 
  const [activeCustomerList, setActiveCustomerList] = useState([]);
  const [activeSupplierList, setActiveSupplierList] = useState([]);
  const [activeEmployeeList, setActiveEmployeeList] = useState([]);
  const [selectedPersonals, setSelectedPersonals] = useState({});
const [selectedItemsListDescription,setSelectedItemsListDescription]=useState('Out of Stock')
  const loadDashBoardData =  () => {
    axios.get("/lineitems/getDashboardData?Parent="+globalUser.Parent +"&StartDate="+ startDate +"&EndDate="+endDate, {
     headers: {
       token: globalToken,
     },
   })
    .then(response=>{
         if (response.data.data){
           setSalesQuantitySummary(response.data.data.SalesQuantitySummary);
           setMainData(response.data.data.SalesQuantitySummary);
           //setSalesPriceSummary(response.data.data.SalesPriceSummary);
           setPurchaseQuantitySummary(response.data.data.PurchaseQuantitySummary);
           //setPurchasePriceSummary(response.data.data.PurchasePriceSummary);
           setActiveCustomerList(response.data.data.Top5Customers);
           setActiveSupplierList(response.data.data.Top5Suppliers);
           setSelectedPersonals({Title:"top 5 customers",Data:response.data.data.Top5Customers})
           setActiveEmployeeList(response.data.data.Top5Users);
           setCashFlowData(response.data.data.CashFlow);
     
         }else{
           alert('log out and log in again')
         }
       })
       
     };
     useEffect(()=>{
     
            if(!globalUser){
        navigate('/');
        return;
      }
      setPageHeader("Dashboard");
      loadDashBoardData();
     },[])
  return (
    <div>
    
    
    <Grid
      container
     margin={1}

      width="98%"
     sx={{backgroundColor: theme.palette.primary.dark}}
    >
        <Grid item xs={12}  margin={2.3}>
        <Grid container style={{boxShadow: '10px 5px 5px gray',borderRadius:10,backgroundColor: theme.palette.primary.main,color:theme.palette.primary.contrastText}}>
          <Grid item xs={2} md={1} pl={4}>{t("filter")}: </Grid>
        <Grid item xs={3.5} md={2} p={0.2}>
                  <TextField
                    size="small"
                    type='date'
                    variant="standard"
                    label={t("start date")}
                    value={startDate}
                    onChange={(e) => {
                      setStartDate(e.target.value);
                    }}
                    sx={{ width: "95%",color:theme.palette.primary.contrastText}}
                   
                    InputLabelProps={{
                    shrink: true,
                    style:{color:theme.palette.primary.contrastText}
                    }}
                  />
                  </Grid>
                  <Grid item xs={3.5} md={2} p={0.2}>
                  <TextField
                    size="small"
                    type='date'
                    variant="standard"
                    label={t("end date")}
                    defaultValue={endDate}
                    onChange={(e) => {
                      setEndDate(e.target.value);
                    }}
                    sx={{ width: "95%",color:theme.palette.primary.contrastText}}
                    InputLabelProps={{
                      shrink: true,
                      style:{color:theme.palette.primary.contrastText}
                    }}
                    
                  />
                  </Grid>
                  <Grid item xs={2} m={0.2}>
                  <Button variant="contained" size="small" style={{marginTop:4,borderBlock:1}}
                  onClick={()=>{
                    loadDashBoardData();
                  }}> {t("refresh")}</Button>
                  </Grid>
        </Grid>
        
       </Grid>
      <Grid item margin={2.3} xs={12} md={2.6} style={{backgroundColor: "white",boxShadow: '10px 5px 5px gray',borderRadius:16}}>
     
        <BarChart width={300} height={100}  data={SalesQuantitySummary} onClick={()=>{
setMainData(SalesQuantitySummary)
setMainDataType('sales')
      }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="Name" />
          <YAxis />
        
          <Tooltip />
          <Bar dataKey="Quantity" fill="#8884d8" />
          <Bar dataKey="TotalPrice" fill="#82ca9d" />
        </BarChart>
        <div  style={{width:'100%',display:'flex',justifyContent:'center'}}>{t("sales quantity and value per item")}</div>

      
      </Grid>
   
      <Grid item margin={2.3} xs={12} md={2.6} style={{backgroundColor: "white",boxShadow: '10px 5px 5px gray',borderRadius:16}}>
      <BarChart width={300} height={100} data={PurchaseQuantitySummary} onClick={()=>{
setMainData(PurchaseQuantitySummary)
setMainDataType('purchase')
      }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="Item" />
          <YAxis />
          <Tooltip />
          <Bar dataKey="Quantity" fill="#8884d8" />
          <Bar dataKey="TotalPrice" fill="#82ca9d" />
        </BarChart>
        <div  style={{width:'100%',display:'flex',justifyContent:'center'}}>{t("purchase quantity and value per item")}</div>

      </Grid>
   
      <Grid item margin={2.3} xs={12} md={2.6} style={{backgroundColor: "white",boxShadow: '10px 5px 5px gray',borderRadius:16}}>
      <LineChart width={300} height={100} data={cashFlowData} onClick={()=>{
setMainDataType('cash')
      }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="PaidDate" />
          <YAxis />
          <Tooltip />
          
          <Line type="monotone" dataKey="Cash In" stroke="#8884d8" />
          <Line type="monotone" dataKey="Cash Out" stroke="#82ca9d" />
        </LineChart>
        <div  style={{width:'100%',display:'flex',justifyContent:'center'}}>{t("cash flow")}</div>
      </Grid>
      <Grid item margin={2.3} xs={12} md={2.6} style={{backgroundColor: "white",boxShadow: '10px 5px 5px gray',borderRadius:16}}>
      <BarChart width={300} height={100} data={PurchaseQuantitySummary} onClick={()=>{
setMainData(PurchaseQuantitySummary)
setMainDataType('cash')
      }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="Item" />
          <YAxis />
          <Tooltip />
          <Bar dataKey="Quantity" fill="#8884d8" />
          <Bar dataKey="TotalPrice" fill="#82ca9d" />
        </BarChart>
        <div  style={{width:'100%',display:'flex',justifyContent:'center'}}>{t("credit control")}</div>
      </Grid>      
      <Grid item margin={2.3} mt={1} xs={12} md={5.5} display={{xs:'none',md:'block'}} style={{backgroundColor: "white",boxShadow: '10px 5px 5px gray',borderRadius:16}}>
       {mainDataType==="cash"?
       <>
      <LineChart width={500} height={370} data={cashFlowData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="PaidDate" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="Cash In" stroke="#8884d8" />
          <Line type="monotone" dataKey="Cash Out" stroke="#82ca9d" />
        </LineChart>
        <div  style={{width:'100%',display:'flex',justifyContent:'center'}}>{t("cash flow")}</div>     

       </>
       :<>
      <BarChart width={500} height={370} data={mainData} onClick={
        <FullCharts MainData={mainData}/>
      }>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="Item" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="Quantity" fill="#8884d8" />
          <Bar dataKey="TotalPrice"  fill="#82ca9d" />
        </BarChart>
        <div  style={{width:'100%',display:'flex',justifyContent:'center'}}>{ t(mainDataType + " quantity and value per item")}</div>     
    </>
    }
        </Grid>
      <Grid item margin={2.3} mt={1} xs={12} md={5.8}>
        <Grid container spacing={2}>
          <Grid item xs={12} style={{boxShadow: '10px 5px 5px gray',borderRadius:16}}>
          <Box sx={{  width: "100%",backgroundColor: theme.palette.primary.main,color: theme.palette.primary.contrastText}}>
          <Button size="small"
                    variant="standard" sx={{borderRadius:'30px'}} endIcon={<AddSharp style={{ width: "20px", height: "20px" }} />} 
                    onClick={()=>setSelectedPersonals({Title:"top 5 customers",Data:activeCustomerList})}>
                  {t("top 5 customers")}
                  </Button>|
                  <Button size="small"
                    variant="standard" sx={{borderRadius:'30px'}} endIcon={<AddSharp style={{ width: "20px", height: "20px" }} />} 
                    onClick={()=>setSelectedPersonals({Title:"top 5 suppliers",Data:activeSupplierList})}>
                   {t("top 5 suppliers")}
                  </Button>|
                  <Button size="small"
                    variant="standard" sx={{borderRadius:'30px'}} endIcon={<AddSharp style={{ width: "20px", height: "20px" }} />} 
                    onClick={()=>setSelectedPersonals({Title:"top 5 employees",Data:activeEmployeeList})}>
                   {t("top 5 employees")}
                  </Button>|
          </Box>
            <Table style={{backgroundColor:'whitesmoke'}}>
              <tr style={{backgroundColor:'lightgray'}}>
            <td colSpan={5}>
       ({t(selectedPersonals.Title) +"      " + t("from") + ": " + startDate +" "+t("to")+": " + endDate})
    </td></tr>
            <tr>
<td>No.</td>
<td>Name</td>
<td>Quantity</td>
<td>Amount</td>
<td>Action</td>
</tr>
<TableBody>
  <tr>
    
  </tr>
              {selectedPersonals.Data && selectedPersonals.Data.map((item,index)=>{
                return<>
                <tr style={{borderBottom:'1',borderColor:'white'}}>
              <td>{index+1}</td>
<td>{item.Name}</td>
<td>{item.Quantity}</td>
<td>{item.TotalPrice}</td>
<td><button>Detail</button></td>
</tr></>
              })}
 
              </TableBody>
            </Table>
     
          </Grid>
        
          <Grid item xs={12}>
          <Box sx={{  width: "100%",backgroundColor: theme.palette.primary.main,color: theme.palette.primary.contrastText}}>
          <Button size="small"
                    variant="standard" sx={{borderRadius:'30px'}} endIcon={<AddSharp style={{ width: "20px", height: "20px" }} />} 
                    onClick={()=>setSelectedItemsListDescription("Out of stock")}>
                   {t("out of stock")}
                  </Button>|
                  <Button size="small"
                    variant="standard" sx={{borderRadius:'30px'}} endIcon={<AddSharp style={{ width: "20px", height: "20px" }} />} 
                    onClick={()=>setSelectedItemsListDescription("slow moving items")}>
                    {t("slow moving items")}
                  </Button>|<Button size="small"
                    variant="standard" sx={{borderRadius:'30px'}} endIcon={<AddSharp style={{ width: "20px", height: "20px" }} />} 
                    onClick={()=>setSelectedItemsListDescription("fast moving items")}>
                    {t("fast moving items")}
                  </Button>|<Button size="small"
                    variant="standard" sx={{borderRadius:'30px'}} endIcon={<AddSharp style={{ width: "20px", height: "20px" }} />} 
                    onClick={()=>setSelectedItemsListDescription("costy items")}>
                    {t("costy items")}
                  </Button>|
          </Box>
          
          <Table style={{backgroundColor:'whitesmoke',boxShadow: '10px 5px 5px gray',borderRadius:16}}>
            <tr style={{backgroundColor:'lightgray'}}>
            <td colSpan={5}>
      {t(selectedItemsListDescription)} ({t("from")+": " + startDate +" "+t("to")+": " + endDate})
    </td></tr>
            <tr>
<td>No.</td>
<td>Name</td>
<td>Quantity</td>
<td>Amount</td>
</tr>
<TableBody>
  <tr>
    
  </tr>
              {activeEmployeeList && activeEmployeeList.map((item,index)=>{
                return<>
                <tr style={{borderBottom:'1',borderColor:'white'}}>
              <td>{index+1}</td>
<td>{item.Name}</td>
<td>{item.Quantity}</td>
<td>{item.TotalPrice}</td>
</tr></>
              })}
 
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </Grid>
    </Grid>

    </div>
  );
}

export default HomeComp;
